/**
 * Entry point of the React application.
 *
 * This component wraps the entire app with essential providers:
 *
 * - `CustomThemeProvider`: Provides the custom Chakra UI theme to the entire app, including colors fetched asynchronously based on the client ID.
 *
 * The app is rendered into a DOM element with the ID 'root'.
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { CustomThemeProvider } from './theme/CustomThemeProvider';
import { App } from './App';
import { appInsights } from './appInsights';

appInsights.trackPageView({
  name: 'PageView',
  uri: window.location.href,
  properties: {
    language: navigator.language, // Adds the language/locale of the page
    referrer: document.referrer,
  },
});

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <CustomThemeProvider>
      <App />
    </CustomThemeProvider>
  </React.StrictMode>,
);
