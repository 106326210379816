import { LogLevel, Configuration } from '@azure/msal-browser';

type B2CPolicy = {
  names: {
    signUpSignIn: string;
  };
  authorities: {
    signUpSignIn: {
      authority: string;
    };
  };
  authorityDomain: string;
};

export const b2cPolicies: B2CPolicy = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://scopelycatalystb2c.b2clogin.com/scopelycatalystb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
    },
  },
  authorityDomain: 'scopelycatalystb2c.b2clogin.com',
};

export const msalConfig: Configuration = {
  auth: {
    clientId: 'a10360e1-3ff2-4e8b-b0e4-7a7a044cba7d',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: '/login',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // "sessionStorage" is more secure, "localStorage" enables SSO
    storeAuthStateInCookie: false, // Enable for compatibility with IE11/Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line
            console.error(message);
            break;
          case LogLevel.Info:
            // eslint-disable-next-line
            console.info(message);
            break;
          case LogLevel.Verbose:
            // eslint-disable-next-line
            console.debug(message);
            break;
          case LogLevel.Warning:
            // eslint-disable-next-line
            console.warn(message);
            break;
          default:
            // Handle unexpected log levels
            // eslint-disable-next-line
            console.warn(`Unknown log level: ${level}`);
            break;
        }
      },
    },
  },
};

export const loginRequest: { scopes: string[] } = {
  scopes: [
    'https://scopelycatalystb2c.onmicrosoft.com/a10360e1-3ff2-4e8b-b0e4-7a7a044cba7d/user_impersonation',
    'offline_access',
    'openid',
  ],
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
// export const silentRequest: { scopes: string[]; loginHint?: string } = {
//   scopes: ["openid", "profile"],
//   loginHint: "example@domain.net",
// };
