import React, { useState } from 'react';
import { Box, FormControl, Icon, ResponsiveValue, Text, useColorModeValue } from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';
import { Label } from '../../atoms/Label';
import { Description } from '../../atoms/Description';

interface CustomInputFileProps {
  question: string;
  placeholder?: string;
  required: boolean;
  description?: string;
  multiple?: boolean;
  marginTop?: string;
  marginStart?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  fontSize?: string;
  descriptionFontSize?: string;
  onChange?: (files: Record<string, File>) => void;
  isDisabled?: boolean;
}

export const CustomInputFile: React.FC<CustomInputFileProps> = ({
  question,
  placeholder,
  required,
  description,
  marginStart,
  paddingLeft,
  marginTop,
  fontSize,
  descriptionFontSize,
  multiple = true,
  onChange,
  isDisabled,
}) => {
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [dragging, setDragging] = useState(false);
  const dropzoneBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.300', 'gray.600');

  const handleFiles = (files: FileList | null) => {
    if (files) {
      const fileArray = Array.from(files);
      const fileMap = fileArray.reduce<Record<string, File>>((acc, file) => {
        return { ...acc, [file.name]: file };
      }, {});

      setFileNames(fileArray.map(file => file.name));
      if (onChange) onChange(fileMap);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(event.target.files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!isDisabled) setDragging(true);
  };

  const handleDragLeave = () => setDragging(false);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    if (!isDisabled) handleFiles(event.dataTransfer.files);
  };

  const getBorderColor = () => {
    if (dragging) return 'blue.400';
    return borderColor;
  };

  const getBgColor = () => {
    if (isDisabled) return 'gray.200';
    if (dragging) return 'blue.50';
    return dropzoneBg;
  };

  return (
    <Box marginStart={marginStart} paddingLeft={paddingLeft} marginTop={marginTop}>
      <FormControl marginStart={marginStart} paddingLeft={paddingLeft} marginTop={marginTop}>
        <Label label={question} htmlFor="file-input" required={required} fontSize={fontSize} />
        <Description description={description} fontSize={descriptionFontSize} />
        <Box
          border="1px solid"
          borderColor={getBorderColor()}
          bgColor={getBgColor()}
          p={6}
          rounded="md"
          textAlign="center"
          boxShadow="md"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          opacity={isDisabled ? 0.6 : 1}
          _hover={!isDisabled ? { borderColor: 'blue.400' } : undefined}
          onClick={() => !isDisabled && document.getElementById('file-input')?.click()}
        >
          {fileNames.length > 0 ? (
            <Text fontSize="sm" mb={2} color={isDisabled ? 'gray.500' : 'black'}>
              {fileNames.join(', ')}
            </Text>
          ) : (
            <>
              <Icon as={FiUpload} boxSize={6} color={isDisabled ? 'gray.500' : 'gray.600'} mb={2} />
              <Text fontSize="sm" color={isDisabled ? 'gray.500' : 'gray.600'}>
                {placeholder || 'Select file or drag it here (5 MB file upload limit)'}
              </Text>
            </>
          )}
        </Box>
        <input
          id="file-input"
          type="file"
          style={{ display: 'none' }}
          multiple={multiple}
          onChange={handleFileChange}
          disabled={isDisabled}
        />
      </FormControl>
    </Box>
  );
};
