import React, { useState } from 'react';
import { Box, Icon, Stack, HStack, Button } from '@chakra-ui/react';
import { FaEdit, FaCheck, FaRegQuestionCircle } from 'react-icons/fa';
import { IconPicker } from './iconPicker';
import { getIconFromString } from '../MenuCard';
import { CustomButton, CustomText } from '../../atoms';
import { SectionIcons } from '../../../stateManagement/SettingsContext';
import { ToggleAtom } from '../../atoms/CustomToggle';

interface SectionSettingsMoleculeProps {
  sectionIcons: SectionIcons[];
  onSectionIconChange: (sectionIcons: SectionIcons[]) => void;
  configs: {
    showSectionGrid: boolean;
  };
  onConfigChange: (key: string, value: any) => void;
}

export const SectionsSettingsMolecule: React.FC<SectionSettingsMoleculeProps> = ({
  sectionIcons,
  onSectionIconChange,
  configs,
  onConfigChange,
}) => {
  const [editingSectionIcon, setEditingSectionIcon] = useState<string | null>(null);
  const [currentEdit, setCurrentEdit] = useState<SectionIcons | null>(null);
  const [showSectionGrid, setShowSectionGrid] = useState(configs.showSectionGrid);

  const handleEditSectionIcons = (section: SectionIcons) => {
    setEditingSectionIcon(section.id);
    setCurrentEdit({ ...section });
  };

  const handleSaveEdit = () => {
    if (currentEdit && editingSectionIcon) {
      const updatedSections = sectionIcons.map(section =>
        section.id === editingSectionIcon ? currentEdit : section,
      );
      onSectionIconChange(updatedSections);
      setEditingSectionIcon(null);
      setCurrentEdit(null);
    }
  };

  const handleIconChange = (iconName: string) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, icon: iconName.split('.')[1] });
    }
  };

  const handleSubmit = () => {
    // eslint-disable-next-line
    console.log(sectionIcons);
  };

  return (
    <Box height="80vh" overflowY="auto" border="1px solid #e2e8f0" p={4} borderRadius="md">
      <HStack
        p={4}
        borderWidth="1px"
        borderRadius="md"
        mb={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <ToggleAtom
          justifyContent="space-between"
          label="Show Section Wise Articles"
          value={showSectionGrid}
          onChange={value => {
            setShowSectionGrid(value); // Update local state
            onConfigChange('showSectionGrid', value); // Notify parent of the change
          }}
        />
      </HStack>
      <Stack spacing={4}>
        <Box mt={6}>
          {sectionIcons.map(section => (
            <HStack
              key={section.id}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              mb={4}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              {editingSectionIcon === section.id ? (
                <Stack spacing={2} width="100%">
                  <HStack>
                    <IconPicker onSelect={iconName => handleIconChange(iconName)} />
                    {currentEdit?.icon && (
                      <Icon
                        as={getIconFromString(currentEdit.icon) || FaRegQuestionCircle}
                        boxSize={6}
                      />
                    )}
                  </HStack>
                </Stack>
              ) : (
                <Stack spacing={1} width="50%">
                  <HStack alignItems="center">
                    <Icon
                      aria-label="Section Icon"
                      as={getIconFromString(section.icon) || FaRegQuestionCircle}
                      mr={4}
                    />
                    <CustomText fontWeight="bold">{section.name}</CustomText>
                  </HStack>
                </Stack>
              )}

              <HStack>
                {editingSectionIcon === section.id ? (
                  <CustomButton
                    icon={<FaCheck />}
                    aria-label="Save Changes"
                    onClick={handleSaveEdit}
                    colorScheme="green"
                  />
                ) : (
                  <CustomButton
                    icon={<FaEdit />}
                    aria-label="Edit Section Icon"
                    onClick={() => handleEditSectionIcons(section)}
                    colorScheme="yellow"
                  />
                )}
              </HStack>
            </HStack>
          ))}
        </Box>
        <Button colorScheme="blue" onClick={handleSubmit}>
          Submit
        </Button>
      </Stack>
    </Box>
  );
};
