import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SimpleGrid } from '@chakra-ui/react';
import { SectionCard } from '../molecules/SectionCard';
import { CustomText } from '../atoms';
import { SectionIcons, useSettings } from '../../stateManagement/SettingsContext';
import { SectionData } from '../../interfaces';

interface SectionGridProps {
  sections?: SectionData[] | null;
  sectionIcons?: SectionIcons[];
  textColor?: string;
  sectionBgColor?: string;
  sectionIconsTranslations?: SectionIcons[];
  sectionsHeading: string;
  sectionHelperText: string;
  sectionCardTextColor: string;
}

export const SectionGrid: React.FC<SectionGridProps> = ({
  sections,
  sectionIcons,
  textColor,
  sectionBgColor = '#ffffff',
  sectionIconsTranslations,
  sectionsHeading,
  sectionHelperText,
  sectionCardTextColor,
}) => {
  const translatedSectionIcons = sectionIcons?.map(item => {
    const sectionIconsTranslation = sectionIconsTranslations?.find(t => t.id === item.id);
    return {
      ...item, // Keep all properties from `items`
      name: sectionIconsTranslation?.name ?? item.name, // Override `title` if translation exists
    };
  });
  const { language } = useSettings();
  const navigate = useNavigate();

  const onClickMore = (title: string) => {
    navigate(`/sections/${language}/section/${title}`);
  };

  return (
    <>
      <CustomText
        variant="h1"
        fontSize={{ base: '2xl', md: '3xl' }} // Adjust font size for mobile
        fontWeight="bold"
        color={textColor}
      >
        {sectionsHeading}
      </CustomText>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing="6" mt="4vh">
        {sections?.map(section => {
          // Find the corresponding icon from sectionIcons
          const matchingIcon = translatedSectionIcons?.find(icon => icon.id === section.section_id);
          const formattedSectionTitle = `${section.section_name.toLowerCase().replace(/\s+/g, '-')}`;

          return (
            <SectionCard
              key={section.section_id}
              textColor={sectionCardTextColor}
              bgColor={sectionBgColor}
              sectionName={section.section_name}
              faqs={section.faq_data}
              icon={matchingIcon?.icon || ''} // Pass the icon or an empty string as a fallback
              onClickMore={() => onClickMore(formattedSectionTitle)}
              sectionHelperText={sectionHelperText}
              helperTextColor={textColor}
            />
          );
        })}
      </SimpleGrid>
    </>
  );
};
