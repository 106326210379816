import React from 'react';
import { Box } from '@chakra-ui/react';
import { CustomText, CustomTextProps } from '../atoms/CustomText';
import { CustomButton } from '../atoms/CustomButton';

interface ContactBtnSectionProps {
  onButtonClick: () => void; // Define the type for the onButtonClick function
  variant: CustomTextProps['variant'];
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  buttonBgColor?: string;
  needHelpTextColor?: string;
  needHelpText?: string;
  mt?: string;
  contactUsButtonText: string;
}

export const ContactBtnSection: React.FC<ContactBtnSectionProps> = ({
  onButtonClick,
  variant = 'h1',
  color = 'primary',
  fontSize = '24px',
  fontWeight = 'bold',
  buttonBgColor = 'black',
  needHelpTextColor = 'black',
  needHelpText = 'Need More Help?',
  mt,
  contactUsButtonText,
}) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={mt}>
      <CustomText
        variant={variant}
        color={needHelpTextColor}
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        {needHelpText}
      </CustomText>
      <CustomButton
        onClick={onButtonClick}
        size="md"
        fontSize="lg"
        textColor={color}
        backgroundColor={buttonBgColor}
        borderRadius="0.4em"
        my="4vh"
        _hover={{ boxShadow: 'md', transform: 'scale(1.05)' }}
      >
        {contactUsButtonText}
      </CustomButton>
    </Box>
  );
};
