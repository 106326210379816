import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  HStack,
  Stack,
  Button,
  Image,
  Input,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  useToast,
  Center,
  VStack,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { ColorPickerAtom } from '../../atoms/ColorPickerAtom';
import { ToggleAtom } from '../../atoms/CustomToggle';
import { CustomText } from '../../atoms';

interface BannerSettingsMoleculeProps {
  configs: {
    bannerImageUrl: string;
    showBanner: boolean;
    showOverlay: boolean;
    showSearchBar: boolean;
    bannerBgColor: string;
    bannerTextColor: string;
    bannerTextSize?: string;
    isBannerFullScreen: boolean;
  };
  onConfigChange: (key: string, value: any) => void;
  convertImageToBase64: (file: File) => Promise<string | ArrayBuffer | null>;
}
const BLOB_CONTAINER_NAME = process.env.REACT_APP_BLOB_CONTAINER_NAME || '';

export const BannerSettingsMolecule: React.FC<BannerSettingsMoleculeProps> = ({
  configs,
  onConfigChange,
  convertImageToBase64,
}) => {
  const [showOverlay, setShowOverlay] = useState(configs.showOverlay);
  const [showSearchBar, setShowSearchBar] = useState(configs.showSearchBar);
  const [showBanner, setShowBanner] = useState(configs.showBanner);
  const [isBannerFullScreen, setIsBannerFullScreen] = useState(configs.isBannerFullScreen);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const toast = useToast();

  useEffect(() => {
    setShowOverlay(configs.showOverlay);
    setShowSearchBar(configs.showSearchBar);
    setShowBanner(configs.showBanner);
    setIsBannerFullScreen(configs.isBannerFullScreen);
  }, [configs]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resetFileInput = () => {
      setSelectedFile(null);
      setPreviewUrl(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };

    const file = event.target.files?.[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        setAlertMessage('Only image files are allowed.');
        resetFileInput();
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setAlertMessage('File size must be less than 5MB.');
        resetFileInput();
        return;
      }

      const renamedFile = new File([file], 'banner.png', { type: file.type });
      setSelectedFile(renamedFile);

      const reader = new FileReader();
      reader.onloadend = () => setPreviewUrl(reader.result as string);
      reader.readAsDataURL(renamedFile);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
    setIsUploading(true);
    try {
      const base64String = await convertImageToBase64(selectedFile);

      try {
        const data = JSON.stringify([
          {
            name: 'banner.png',
            type: 'image',
            path: `${BLOB_CONTAINER_NAME}/images`,
            data: base64String,
          },
        ]);

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://catalysthc.ms.helpshift.live/api/fe-config',
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        };

        const response = await axios.request(config);

        if (response.status >= 200 && response.status < 300) {
          toast({
            title: 'Upload successful!',
            description: 'Your banner image has been uploaded.',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error uploading file:', error);
        toast({
          title: 'Error',
          description: 'An error occurred while uploading.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (error) {
      // console.error('Error uploading file:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while uploading.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box position="relative" height="80vh" overflowY="auto">
      <Stack spacing={6} p={4} mt={4}>
        <VStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          align="start"
        >
          <CustomText>Upload Banner Image</CustomText>
          <HStack spacing={4}>
            <Input
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              onChange={handleFileChange}
              ref={fileInputRef}
              bg="white"
            />
            <Button
              colorScheme="blue"
              onClick={handleUpload}
              isDisabled={!selectedFile}
              isLoading={isUploading}
            >
              Upload
            </Button>
          </HStack>
          <Text fontSize="sm" color="gray.500" mt={2}>
            Supported file types: <b>png, jpg, jpeg</b>
          </Text>
          <Text fontSize="sm" color="gray.500">
            Note: This action will immediately upload the selected image without any further
            confirmation.
          </Text>
        </VStack>

        {previewUrl && (
          <Box mt={4} display="flex" justifyContent="center">
            <Image
              src={previewUrl}
              alt="Preview"
              boxSize="200px"
              borderRadius="md"
              objectFit="cover"
            />
          </Box>
        )}

        <Stack spacing={4}>
          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ToggleAtom
              justifyContent="space-between"
              label="Show Banner"
              value={showBanner}
              onChange={value => {
                setShowBanner(value);
                onConfigChange('showBanner', value);
              }}
            />
          </HStack>

          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ToggleAtom
              justifyContent="space-between"
              label="Show Banner in full screen"
              value={isBannerFullScreen}
              onChange={value => {
                setIsBannerFullScreen(value);
                onConfigChange('isBannerFullScreen', value);
              }}
            />
          </HStack>

          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ToggleAtom
              justifyContent="space-between"
              label="Show Overlay"
              value={showOverlay}
              onChange={value => {
                setShowOverlay(value);
                onConfigChange('showOverlay', value);
              }}
            />
          </HStack>

          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ToggleAtom
              justifyContent="space-between"
              label="Show SearchBar"
              value={showSearchBar}
              onChange={value => {
                setShowSearchBar(value);
                onConfigChange('showSearchBar', value);
              }}
            />
          </HStack>
          <HStack
            p={4}
            borderWidth="1px"
            borderRadius="md"
            mb={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ColorPickerAtom
              label="Banner Text Color"
              value={configs.bannerTextColor}
              onChange={color => onConfigChange('bannerTextColor', color)}
            />
          </HStack>
        </Stack>
      </Stack>

      {alertMessage && (
        <Center
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="1000"
        >
          <Alert status="error" variant="solid" borderRadius="md" boxShadow="lg">
            <AlertIcon />
            <Box>
              <AlertTitle>Error</AlertTitle>
              {alertMessage}
            </Box>
            <CloseButton
              onClick={() => setAlertMessage(null)}
              position="absolute"
              right="8px"
              top="8px"
            />
          </Alert>
        </Center>
      )}
    </Box>
  );
};
