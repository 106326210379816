import React, { useState } from 'react';
import { Box, Stack, HStack, Text } from '@chakra-ui/react';
import { FaEdit, FaTrash, FaCheck, FaPlus } from 'react-icons/fa';
import { CustomButton, CustomInput, CustomText } from '../../atoms';
import { CifData, FormField } from '../../../stateManagement/SettingsContext';
import { CustomDropdownWithSearchBar } from './CustomDropdownWithSearchBar';
import { ToggleAtom } from '../../atoms/CustomToggle';

interface CifSettingsOrganismProps {
  onFormCardChange: (newCards: FormField[]) => void;
  cifs: CifData;
  contactUsFormData: FormField[];
}

export const ContactUsSettingsMolecule: React.FC<CifSettingsOrganismProps> = ({
  onFormCardChange,
  cifs,
  contactUsFormData,
}) => {
  const [editingCardId, setEditingCardId] = useState<string | null>(null);
  const [currentEdit, setCurrentEdit] = useState<FormField | null>(null);
  const [selectedCif, setSelectedCif] = useState<string>('');
  const [selectedCifKey, setSelectedCifKey] = useState<string>('');
  const [isRequired, setIsRequired] = useState(currentEdit?.required);
  const [availableCifs, setAvailableCifs] = useState<CifData>({
    data: cifs.data.filter(
      cif => !contactUsFormData.some(field => field.linked_cif_key === cif.key),
    ),
    is_agent: cifs.is_agent,
  });

  const handleEditMenuCard = (card: FormField) => {
    setEditingCardId(card.id);
    setCurrentEdit({ ...card });
    setIsRequired(card.required);
    setSelectedCif(cifs.data.find(cif => cif.key === card.linked_cif_key)?.label || '');
    setSelectedCifKey(card.linked_cif_key || '');
  };

  const handleSaveEdit = () => {
    if (currentEdit && editingCardId) {
      const updatedCards: FormField[] = (contactUsFormData ?? []).map(card =>
        card.id === editingCardId ? currentEdit : card,
      );
      onFormCardChange(updatedCards);
      setEditingCardId(null);
      setCurrentEdit(null);
      setSelectedCif('');
      setSelectedCifKey('');
    }
  };

  const cifDropdownItems = availableCifs.data.map(item => ({
    label: item.label,
    value: item.key,
  }));

  const handleAddFormCard = () => {
    const newCard: FormField = {
      id: Date.now().toString(),
      question: '',
      description: '',
      placeholder: '',
      required: false,
      linked_cif_key: '',
      linked_cif_type: '',
      type: 'singleline',
      linked_cif_options: '',
      options: '',
    };
    onFormCardChange([...contactUsFormData.slice(0, 2), newCard, ...contactUsFormData.slice(2)]);
    setEditingCardId(newCard.id);
    setCurrentEdit(newCard);
    setSelectedCif('');
    setSelectedCifKey('');
  };
  const handleDeleteMenuCard = (id: string) => {
    const updatedCards: FormField[] = (contactUsFormData ?? []).filter(card => card.id !== id);
    onFormCardChange(updatedCards);
    setAvailableCifs(prev => ({
      ...prev,
      data: [
        // Restore the CIF from the deleted card if it had a linked CIF
        ...(selectedCifKey ? cifs.data.filter(cif => cif.key === selectedCifKey) : []),
        // Keep other available CIFs unchanged
        ...prev.data,
      ],
    }));
    setSelectedCif('');
    setSelectedCifKey('');
  };

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, question: event.target.value });
    }
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, description: event.target.value });
    }
  };
  const handlePlaceholderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, placeholder: event.target.value });
    }
  };
  const handleToggleChange = (value: boolean) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, required: value });
    }
  };

  const handleCifDropdownItemClick = (value: string) => {
    const selectedItem = cifs.data.find(item => item.key === value);

    setAvailableCifs(prev => ({
      ...prev,
      data: [
        // Add back the previously selected CIF (if it exists)
        ...(selectedCif ? cifs.data.filter(cif => cif.label === selectedCif) : []),
        // Remove the newly selected CIF (which is now `selectedItem`)
        ...prev.data.filter(cif => cif.key !== selectedItem?.key),
      ],
    }));

    setSelectedCif(selectedItem?.label || '');
    setSelectedCifKey(selectedItem?.key || '');

    // Extract options and convert them to a comma-separated string
    const linkedCifOptions = selectedItem?.data?.options
      ? selectedItem.data.options.map(option => option.label).join(', ')
      : '';

    if (currentEdit) {
      setCurrentEdit({
        ...currentEdit,
        linked_cif_key: selectedItem?.key,
        linked_cif_type: selectedItem?.type,
        linked_cif_options: linkedCifOptions,
        type: selectedItem?.type || 'singleline',
        options: linkedCifOptions,
      });
    }
  };

  return (
    <Box height="86.5vh" overflowY="auto" border="1px solid #e2e8f0" p={4} borderRadius="md">
      <CustomButton icon={<FaPlus />} onClick={handleAddFormCard} colorScheme="green">
        Add Form Field
      </CustomButton>
      <Stack spacing={4}>
        <Box mt={6}>
          {contactUsFormData.map(card => (
            <HStack
              key={card.id}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              mb={4}
              justifyContent="space-between"
              alignItems="flex-start"
              bg={editingCardId === card.id ? 'white' : 'gray.50'}
            >
              {editingCardId === card.id ? (
                <Stack spacing={2} width="100%" bg="gray.50">
                  {card.id !== '1' && card.id !== '2' && card.id !== '6' && (
                    <HStack>
                      <CustomDropdownWithSearchBar
                        value={selectedCif}
                        label={
                          selectedCif
                            ? cifs.data.find(item => item.key === selectedCifKey)?.label ||
                              'Select an option'
                            : 'Select Cif To Link'
                        }
                        items={cifDropdownItems}
                        onItemClick={handleCifDropdownItemClick}
                        variant="solid"
                      />
                      {!selectedCif && <Text color="red.500">required field *</Text>}
                    </HStack>
                  )}

                  <HStack>
                    <CustomInput
                      value={currentEdit?.question || ''}
                      onChange={handleQuestionChange}
                      placeholder="Question"
                      overflow="auto"
                    />
                  </HStack>
                  <HStack>
                    <CustomInput
                      value={currentEdit?.description || ''}
                      onChange={handleDescriptionChange}
                      placeholder="Description"
                      overflow="auto"
                    />
                  </HStack>
                  {card.id !== '1' && (
                    <HStack>
                      <CustomInput
                        value={currentEdit?.placeholder || ''}
                        onChange={handlePlaceholderChange}
                        placeholder="Placeholder"
                        overflow="auto"
                      />
                    </HStack>
                  )}

                  {card.id !== '1' && (
                    <HStack>
                      <ToggleAtom
                        value={isRequired || false}
                        justifyContent="space-between"
                        onChange={value => {
                          setIsRequired(value);
                          handleToggleChange(value);
                        }}
                        label="Is this field mandatory?"
                      />
                    </HStack>
                  )}
                </Stack>
              ) : (
                <Stack spacing={1} width="50%">
                  <HStack alignItems="center">
                    <CustomText fontWeight="bold">{card.question}</CustomText>
                  </HStack>
                  <HStack alignItems="center">
                    <CustomText>{card.description}</CustomText>
                  </HStack>
                  <HStack alignItems="center">
                    <CustomText>{card.placeholder}</CustomText>
                  </HStack>
                </Stack>
              )}

              <HStack>
                {editingCardId === card.id ? (
                  <>
                    <CustomButton
                      icon={<FaCheck />}
                      aria-label="Save Changes"
                      onClick={handleSaveEdit}
                      colorScheme="green"
                      isDisabled={!selectedCif && !['1', '2', '6'].includes(editingCardId)}
                    />
                    {/* Show Delete button only in edit mode */}
                    {card.id !== '1' && card.id !== '2' && card.id !== '6' && (
                      <CustomButton
                        icon={<FaTrash />}
                        aria-label="Delete Menu Card"
                        onClick={() => handleDeleteMenuCard(card.id)}
                        colorScheme="red"
                      />
                    )}
                  </>
                ) : (
                  <CustomButton
                    icon={<FaEdit />}
                    aria-label="Edit Menu Card"
                    onClick={() => handleEditMenuCard(card)}
                    colorScheme="yellow"
                  />
                )}
              </HStack>
            </HStack>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};
