import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ContactUs } from '../organisms/ContactUs';
import { FormField } from '../../stateManagement/SettingsContext';
import { Header } from '../organisms';
import { Footer } from '../organisms/Footer';
import { Settings } from '../../interfaces';

interface ContactUsPagePreviewProps {
  settings: Settings;
  supportedLanguages: { label: string; value: string }[];
  onClickMyTickets: () => void;
  onClickLandingPageButton: () => void;
  contactUsFormData: FormField[];
}
export const ContactUsPagePreview: React.FC<ContactUsPagePreviewProps> = ({
  settings,
  supportedLanguages,
  onClickMyTickets,
  onClickLandingPageButton,
  contactUsFormData,
}) => {
  // const { contactUsFormData } = useSettings();
  const { t }: { t: (key: string, options?: { returnObjects: boolean }) => any } = useTranslation();
  const translatedContactUsFormData = t('data.contactUsFormData', { returnObjects: true });
  const helperTexts = t('data.helperTexts', { returnObjects: true });
  const [currentLanguage, setCurrentLanguage] = useState('English');

  const changeTheme = () => {};
  const handleLanguageChange = () => {
    setCurrentLanguage('english');
  };
  const translatedData: FormField[] = (contactUsFormData ?? []).map(link => {
    const translation = translatedContactUsFormData.find(
      (i: {
        id: string;
        question?: string;
        description?: string;
        placeholder?: string;
        options?: string;
      }) => i.id === link.id,
    );
    return {
      ...link,
      question: translation?.question ?? link.question,
      description: translation?.description ?? link.description,
      placeholder: translation?.placeholder ?? link.placeholder,
      options: translation?.options ?? link.linked_cif_options,
    };
  });
  return (
    <Box backgroundColor={settings?.configs.secondaryBgColor}>
      {/* Header */}
      {settings?.configs?.showHeader && (
        <Header
          showMainHeader={settings?.configs.showMainHeader}
          mainHeaderBgColor={settings?.configs.mainHeaderBgColor}
          mainHeaderTextColor={settings?.configs.mainHeaderTextColor}
          showSubHeader={settings?.configs.showSubHeader}
          subHeaderBgColor={settings?.configs.primaryBgColor}
          subHeaderTextColor={settings?.configs.primaryTextColor}
          showHeaderLogo={settings?.configs.showHeaderLogo}
          logoUrl={settings?.configs.headerlogoUrl}
          gameTitle={settings?.data.gameTitle}
          platforms={settings?.data.platforms}
          supportedLanguages={supportedLanguages}
          currentLanguage={currentLanguage}
          onLanguageChange={handleLanguageChange}
          horizontalPadding={{ base: '1', md: '8vw', lg: '12vw' }} // Responsive padding
          changeTheme={changeTheme}
          onClickMyTickets={onClickMyTickets}
          myTicketsButtonText={
            helperTexts.find((item: Record<string, string>) => 'myTicketsButtonText' in item)
              ?.myTicketsButtonText
          }
          loginButtonText={
            helperTexts.find((item: Record<string, string>) => 'loginButtonText' in item)
              ?.loginButtonText
          }
          logoWidth={settings?.configs?.headerLogoSize}
          onClickLandingPageButton={onClickLandingPageButton}
        />
      )}
      <ContactUs
        FormFields={translatedData}
        createTicketButtonText={
          helperTexts.find((item: Record<string, string>) => 'createTicketButtonText' in item)
            ?.createTicketButtonText
        }
        liveChatButtonText={
          helperTexts.find((item: Record<string, string>) => 'liveChatButtonText' in item)
            ?.liveChatButtonText
        }
      />
      <Footer
        showFooterLogo={settings?.configs?.showFooterLogo}
        showFooterLinks={settings?.configs?.showFooterLinks}
        links={settings?.data?.footerLinks}
        imageSrc={settings?.configs?.footerLogoUrl}
        logoWidth={settings?.configs?.footerLogoSize}
        footerNote={settings?.data?.footerNote}
        footerBgColor={settings?.configs?.footerBackgroundColor}
        footerTextColor={settings?.configs?.footerTextColor}
      />
    </Box>
  );
};
