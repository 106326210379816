import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ArrowBackIcon, SearchIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { CustomHeading } from '../atoms/CustomHeading';
import { Settings } from '../../interfaces';
import { InputWithIcon } from '../molecules/InputWithIcon';
import { CustomTable } from '../molecules/CustomTable';
import { CustomButton } from '../atoms/CustomButton';

export interface PlayerHubProps {
  PlayerHubTableFields: PlayerHubTableField[];
  settings?: Settings;
  onTicketClick?: (issueId: number) => void;
  showHelperButton?: boolean;
  emptyDataHelperText: string;
}

export interface PlayerHubTableField {
  ticketNumber: number;
  ticketSummary: string;
  status: string;
  date: string;
}

export const PlayerHub: React.FC<PlayerHubProps> = ({
  PlayerHubTableFields,
  settings,
  onTicketClick,
  showHelperButton = 'true',
  emptyDataHelperText,
}) => {
  // const { settings } = useSettings();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState<string>('');

  // Handle change event for input
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value); // Update input value
  };

  // Filter data based on input value (case-insensitive match)
  const filteredTableData = PlayerHubTableFields.filter(field =>
    field.ticketSummary.toLowerCase().includes(inputValue.toLowerCase()),
  ).map(field => ({
    'Ticket #': field.ticketNumber,
    'Ticket Summary': field.ticketSummary,
    Status: field.status,
    'Last Updated': field.date,
  }));

  const handleBackClick = () => {
    // eslint-disable-next-line
    console.log('Clicked My Tickets');
    navigate('/contactUs');
  };

  return (
    <Box padding="2rem" maxWidth="1400px" margin="0 auto">
      <Box marginBottom="2rem">
        <CustomHeading
          question="MY TICKETS"
          fontSize="4xl"
          descriptionFontSize="md"
          fontWeight="400"
          marginTop="1rem"
          color={settings?.configs.secondaryTextColor}
        />
      </Box>
      <InputWithIcon
        searchBarTextColor="#000000"
        searchBarBgColor={settings?.configs.searchBarBgColor}
        placeholderColor="gray"
        placeholder="Search Fields"
        placeholderFontSize={{ base: '18px', md: '20px', lg: '22px' }}
        InputIcon={SearchIcon}
        height={{ base: '5vh', md: '5vh', lg: '7vh', sm: '15vh' }}
        iconSize="4vh"
        onChange={handleChange}
        value={inputValue}
        width="100%"
        px="0"
      />
      <CustomTable
        height="50vh"
        mt={{ base: '5vh' }}
        fields={['Ticket #', 'Ticket Summary', 'Status', 'Last Updated']}
        fieldsWithIcons={['Ticket #', 'Status', 'Last Updated']}
        fieldsAsLinks={['Ticket #']}
        centerAlignFields={['Status']}
        rowBorderColor="#dedede"
        data={filteredTableData}
        rowHoverBgColor={settings?.configs.secondaryBgColor}
        tableRowTextColor={settings?.configs.primaryTextColor}
        tableHeadingTextColor={settings?.configs.secondaryTextColor}
        onTicketClick={onTicketClick}
        emptyDataHelperText={emptyDataHelperText}
      />
      {showHelperButton && (
        <CustomButton
          onClick={handleBackClick}
          leftIcon={<ArrowBackIcon />}
          size="md"
          colorScheme="gray"
          variant="solid"
          fontSize="md"
          borderRadius="md"
          padding="30px 30px"
          fontWeight="bold"
          marginLeft="71rem"
        >
          Contact Us Form
        </CustomButton>
      )}
    </Box>
  );
};
