import {
  Grid,
  GridItem,
  Box,
  Heading,
  Text,
  List,
  ListItem,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Alert,
  AlertIcon,
  UnorderedList,
  Flex,
} from '@chakra-ui/react';

export const PrivacyPolicyHS: React.FC = () => {
  return (
    <Grid templateColumns={{ base: '1fr', md: '250px 1fr' }} gap={8} p={6} maxW="6xl" mx="auto">
      {/* Table of Contents */}
      <GridItem>
        <Box position="sticky" top="4" bg="white" p={4} borderRadius="md" boxShadow="md">
          <Heading size="md" mb={4}>
            Table of Contents
          </Heading>
          <List spacing={2}>
            <ListItem>
              <Link href="#section-1">1. How We Use Data</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-2">2. Data Sharing</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-3">3. Data Transfers</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-4">4. Security & Retention</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-5">5. Automated Decisions</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-6">6. Third Parties</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-7">7. Children s Data</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-8">8. Your Rights</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-9">9. Philippines Rights</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-10">10. India Rights</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-11">11. Singapore Rights</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-12">12. Canada Rights</Link>
              <ListItem pl={2}>
                <Link href="#section-12">12.1 Québec residents</Link>
              </ListItem>
              <ListItem pl={2}>
                <Link href="#section-12">12.2 Non Québec Residents</Link>
              </ListItem>
            </ListItem>

            <ListItem>
              <Link href="#section-13">13. California Notice</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-14">14. Other US States</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-15">15. Policy Changes</Link>
            </ListItem>
            <ListItem>
              <Link href="#section-16">16. Contact Info</Link>
            </ListItem>
          </List>
        </Box>
      </GridItem>

      {/* Main Content */}
      <GridItem>
        <Flex direction="column" maxWidth="800px" margin="auto" textAlign="left" gap={4} p={4}>
          <Heading as="h1" size="xl" fontWeight="bold" mb={4} color="gray.800">
            Privacy Notice
          </Heading>

          <Flex direction="column" gap={2}>
            <Flex alignItems="baseline">
              <Text fontSize="md" fontWeight="600" mr={2}>
                Effective date:
              </Text>
              <Text fontSize="md" color="gray.600">
                8 July 2023
              </Text>
            </Flex>

            <Flex alignItems="baseline">
              <Text fontSize="md" fontWeight="600" mr={2}>
                Last Updated:
              </Text>
              <Text fontSize="md" color="gray.600">
                19 August 2024
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {/* Section 1 */}
        <Box id="section-1" mb={12}>
          <Box>
            <Text mb={4}>
              Helpshift, Inc (“Helpshift”, “we”, “us” or “our”) is a subsidiary of Keywords Studios
              Group (“Keywords”, “we”, “us” or “our”). We know that you care about how your Personal
              Data is used and shared, and we take your privacy seriously. When we reference
              Personal Data, we mean any information which Keywords has or obtains or which an
              individual provides to us from which that individual can be directly or indirectly
              personally identified or as that term (or similar term) is defined under applicable
              law.
            </Text>

            <Text mb={4}>
              Accordingly, we have implemented this Privacy Notice to inform you of the information
              that we collect from you when you visit our website,{' '}
              <Link href="https://www.keywordsstudios.com" color="blue.500" isExternal>
                www.keywordsstudios.com
              </Link>
              , and its sub-domains, including individual websites of Keywords’ studios (the
              “Website”), or when you engage Keywords directly for its services, applications and/or
              their related features (the “Services”), what we do with it and how you can exercise
              your privacy rights. When you use our Services or our Website, your Personal Data will
              be handled as described in this Privacy Notice. Your use of our Services or Website,
              and any dispute over privacy is, where permitted under law, subject to our Terms of
              Use which is available on the Website, including their applicable terms governing
              limitations on damages and the resolution of disputes.
            </Text>

            <Text mb={4}>
              This Privacy Notice covers how we, at Keywords, treat and manage your Personal Data,
              why we use your Personal Data and how to contact us. We have identified below which of
              your Personal Data we collect on our own behalf. Keywords is a global company with
              customers and offices all around the world. As such, our approach to privacy
              compliance is a global one. No matter where you are located, whether in the United
              States, the European Union (EU), the United Kingdom (UK), Latin America, or the
              Asia-Pacific region, we remain committed to abiding by all applicable data privacy
              laws. As part of this global approach, we endeavour to adopt consistent approaches to
              our Personal Data processing to the extent possible however, we have identified
              certain regions with specific practices and we have supplemented this Privacy Notice
              with regional specific sections in certain instances and they can be easily accessed
              through the hyperlinks in the below Table of Contents.
            </Text>

            <Alert status="info" mt={6} mb={6} borderRadius="md">
              <AlertIcon />
              <Box>
                <Text>
                  Additionally if you are applying for a role within the Keywords organisation using
                  the application portal on this Website, please refer to the standalone Applicant
                  Privacy Notice.
                </Text>
                <Text mt={2}>
                  If you have a disability, you may access this Privacy Notice in an alternative
                  format by contacting our Privacy Team at{' '}
                  <Link href="mailto:privacy@helpshift.com" color="blue.500">
                    privacy@helpshift.com
                  </Link>
                  . Our Privacy Team can also be contacted if you have any questions on this Privacy
                  Notice in general.
                </Text>
              </Box>
            </Alert>
          </Box>
          <Heading size="xl" mb={6}>
            1. How We Use Your Personal Data
          </Heading>
          <Heading size="md" mb={4}>
            Categories of Personal Data We Collect
          </Heading>
          <Text>
            This chart details the categories of Personal Data that we collect in connection with
            the provision of our Services to our clients. Keywords acts as a data controller with
            respect to this data.
          </Text>

          <Box>
            {/* Personal Data Table */}
            <Table variant="striped" colorScheme="gray" my={6}>
              <Thead bg="gray.100">
                <Tr>
                  <Th border="1px" borderColor="gray.200">
                    Category of Personal Data
                  </Th>
                  <Th border="1px" borderColor="gray.200">
                    Personal Data We Collect
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Profile or Contact Data
                  </Td>
                  <Td border="1px" borderColor="gray.200">
                    First and last name, email address, phone number, mailing address, DOB.
                  </Td>
                </Tr>
                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Online Identifiers
                  </Td>
                  <Td border="1px" borderColor="gray.200">
                    Unique identifiers such as email IDs.
                  </Td>
                </Tr>
                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Identifiers
                  </Td>
                  <Td border="1px" borderColor="gray.200">
                    Customer number.
                  </Td>
                </Tr>
                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Online Presence
                  </Td>
                  <Td border="1px" borderColor="gray.200">
                    Information about your active status or presence on a Keywords dashboard /
                    database.
                  </Td>
                </Tr>
                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Social Network Data
                  </Td>
                  <Td border="1px" borderColor="gray.200">
                    Email, phone number, username on the social network, IP address, Device ID.
                  </Td>
                </Tr>
                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Other Identifying Information that You Voluntarily Choose to Provide
                  </Td>
                  <Td border="1px" borderColor="gray.200" whiteSpace="normal">
                    While using our Services, you may submit or upload certain content,
                    communications, data, attachments or files to our Services for hosting and
                    processing by us at your discretion.
                  </Td>
                </Tr>
              </Tbody>
            </Table>

            <Box mb={6}>
              <Alert status="info" variant="left-accent" mb={4}>
                <AlertIcon />
                <Box>
                  <Text>
                    If you are an individual whose Personal Data is being processed by Keywords in
                    connection with a service being provided to you by a third party, then we are
                    acting as a data processor or service provider on behalf of such company and you
                    should contact the company (our customer) that provided you access to the
                    Services to address your rights with respect to such data and they will respond
                    to you accordingly. Our privacy practices in such cases will be governed by the
                    contract that we have in place with the company.
                  </Text>
                  <Text mt={2}>
                    We will then endeavour to provide assistance to our customers to address
                    concerns you may have in accordance with the terms of our contract with such
                    customer. A common example of where we process Personal Data in such a manner is
                    in connection with our player support services where an individual may have
                    submitted a support request (which may include Personal Data such as a name,
                    email address, user ID, etc.) to a company that uses our customer support
                    technology or services however in such circumstances, the company should be
                    contacted and we can assist with such communications to the extent possible.
                  </Text>
                </Box>
              </Alert>

              <Box bg="gray.50" p={4} borderRadius="md">
                <Text fontWeight="semibold" mb={2}>
                  {`
                  The following chart details the categories of Personal Data that we collect
                  through your usage of our Website or other Keywords' websites including`}
                  <Link href="https://labs.keywordsstudios.com" color="blue.500" isExternal>
                    https://labs.keywordsstudios.com
                  </Link>
                  . Keywords acts as a data controller with respect to this data.
                </Text>
              </Box>
            </Box>

            <Table variant="striped" colorScheme="gray" my={6}>
              <Thead bg="gray.100">
                <Tr>
                  <Th border="1px" borderColor="gray.200" fontSize="md">
                    Category of Personal Data
                  </Th>
                  <Th border="1px" borderColor="gray.200" fontSize="md">
                    Personal Data We Collect
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Profile or Contact Data
                  </Td>
                  <Td border="1px" borderColor="gray.200">
                    First and last name, email address, phone number, mailing address
                  </Td>
                </Tr>

                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Online Identifiers
                  </Td>
                  <Td border="1px" borderColor="gray.200">
                    Unique identifiers such as email IDs
                  </Td>
                </Tr>

                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Device / IP data
                  </Td>
                  <Td border="1px" borderColor="gray.200">
                    IP address, device ID, type of device/operating system/browser used to access
                    the Website
                  </Td>
                </Tr>

                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Web Analytics
                  </Td>
                  <Td border="1px" borderColor="gray.200" whiteSpace="normal">
                    Browsing or search history, web page interactions (including with ads).
                    Referring webpage/source through which you accessed the Website.
                    Non-identifiable request IDs, statistics associated with the interaction between
                    device or browser and the Website pixel tags
                  </Td>
                </Tr>

                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Social Network Data
                  </Td>
                  <Td border="1px" borderColor="gray.200">
                    Email, phone number, username on the social network, IP address, Device ID
                  </Td>
                </Tr>

                <Tr>
                  <Td border="1px" borderColor="gray.200" fontWeight="medium">
                    Other Identifying Information that You Voluntarily Choose to Provide
                  </Td>
                  <Td border="1px" borderColor="gray.200" whiteSpace="normal">
                    <List spacing={2}>
                      <ListItem>Identifying information in communications when you:</ListItem>
                      <List spacing={1} pl={6} mt={1}>
                        <ListItem>Register or use an account</ListItem>
                        <ListItem>Participate with Labs at Keywords Studios</ListItem>
                        <ListItem>Request demo/trial/service information</ListItem>
                        <ListItem>Subscribe to marketing communications</ListItem>
                        <ListItem>Provide feedback or seek support</ListItem>
                        <ListItem>Post blog comments</ListItem>
                        <ListItem>Sign up for events/webinars</ListItem>
                      </List>
                      <Text mt={2}>
                        May include: Name, email, contact details, company name, job role, request
                        details
                      </Text>
                      <Text mt={2} color="gray.600">
                        We may also collect data offline through: event attendance, phone calls with
                        representatives
                      </Text>
                    </List>
                  </Td>
                </Tr>
              </Tbody>
            </Table>

            <Box>
              {/* Note Section */}
              <Alert status="info" variant="left-accent" mb={6} borderRadius="md">
                <AlertIcon />
                <Box>
                  <Text fontWeight="medium">
                    Note: Where you are engaging with Keywords through our studios Player Research
                    and Digital Media Management, more information on how your Personal Data is used
                    can be found on their specific privacy notices that can be accessed through the
                    following links:
                  </Text>
                  <List spacing={2} mt={2}>
                    <ListItem>
                      <Link
                        href="https://www.playerresearch.com/privacy-policy/"
                        isExternal
                        color="blue.500"
                      >
                        Player Research Privacy Policy
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="https://digitalmediamanagement.com/privacy-policy/"
                        isExternal
                        color="blue.500"
                      >
                        Digital Media Management Privacy Policy
                      </Link>
                    </ListItem>
                  </List>
                </Box>
              </Alert>

              {/* Categories of Sources */}
              <Heading size="md" mb={4}>
                Categories of Sources of Personal Data
              </Heading>

              <Text mb={4}>
                We collect Personal Data about you from the following categories of sources:
              </Text>

              {/* You Section */}
              <Box mb={6}>
                <Text fontWeight="semibold" mb={2}>
                  You
                </Text>
                <Text mb={2}>
                  When you provide such information directly to us. This may occur:
                </Text>
                <List spacing={2} pl={6}>
                  <ListItem>
                    When you use our interactive tools, this Website, other Websites and/or Services
                  </ListItem>
                  <ListItem>
                    When you voluntarily provide information in free-form text boxes through the
                    Services or through responses to surveys or questionnaires
                  </ListItem>
                  <ListItem>When you send us an email or otherwise contact us</ListItem>
                  <ListItem>
                    When you use the Website and/or Services and such information is collected
                    automatically, for example:
                    <List spacing={2} pl={6} mt={1} styleType="circle">
                      <ListItem>
                        Through Cookies (defined in our{' '}
                        <Link href="/cookie-notice" color="blue.500">
                          Cookie Notice
                        </Link>{' '}
                        which is available on the Website)
                      </ListItem>
                      <ListItem>
                        If you download one of our mobile applications or use a location-enabled
                        browser, we may receive information about your location and mobile device,
                        as applicable
                      </ListItem>
                      <ListItem>
                        If you download and install certain applications and software that we make
                        available, we may receive and collect information transmitted from your
                        computing device for the purpose of providing you the relevant Website
                        and/or Services
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Box>

              {/* Third Parties Section */}
              <Box mb={6}>
                <Text fontWeight="semibold" mb={2}>
                  Third Parties
                </Text>
                <List spacing={2} pl={6}>
                  <ListItem>
                    Social Networks:
                    <List spacing={1} pl={6} mt={1} styleType="circle">
                      <ListItem>
                        If you provide your social network account credentials to us or otherwise
                        sign-in to the Services through a third-party site or service, some content
                        and/or information in those accounts may be transmitted into your account
                        with us
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Box>

              {/* Legal Basis Section */}
              <Box bg="gray.50" p={4} borderRadius="md" mb={6}>
                <Text>
                  If you are from a region that requires a legal basis for processing personal data
                  (such as the EU or the UK), our legal basis for collecting and using the Personal
                  Data described above will depend on the Personal Data concerned and the specific
                  context in which we collect it but we will only process your Personal Data if we
                  have a lawful basis for doing so. In such circumstances, the table below details
                  how Keywords will use your Personal Data for our commercial or business purposes
                  (“Purposes”) and the context for which we use your Personal Data (“Legal Basis”).
                </Text>
              </Box>
            </Box>

            {/* Legal Basis Tables */}
            <Box>
              {/* Contract Performance Table */}
              <Table variant="striped" colorScheme="gray" mb={6}>
                <Thead bg="gray.100">
                  <Tr>
                    <Th colSpan={2} border="1px" borderColor="gray.200">
                      Where required for performance of a contract with you
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td border="1px" borderColor="gray.200" fontWeight="semibold" width="30%">
                      Legal Basis
                    </Td>
                    <Td border="1px" borderColor="gray.200" whiteSpace="normal">
                      We may obtain, collect and process your Personal Data where necessary to
                      perform a contract or to enter into a contract at your request with Keywords.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td border="1px" borderColor="gray.200" fontWeight="semibold">
                      Purpose
                    </Td>
                    <Td border="1px" borderColor="gray.200" whiteSpace="normal">
                      {`We process your Personal Data for our customers as a matter of "contractual
                      necessity", meaning that we need to process the data to provide you with the
                      Services. When we process data due to contractual necessity, failure to
                      provide such Personal Data will result in your inability to use some or all
                      portions of the Services that require such data.`}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>

              {/* Legitimate Interest Table */}
              <Table variant="striped" colorScheme="gray" mb={6}>
                <Thead bg="gray.100">
                  <Tr>
                    <Th colSpan={2} border="1px" borderColor="gray.200">
                      Where use is for a legitimate purpose of Keywords
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td border="1px" borderColor="gray.200" fontWeight="semibold" width="30%">
                      Legal Basis
                    </Td>
                    <Td border="1px" borderColor="gray.200">
                      We may obtain, collect and process your Personal Data where we have a
                      legitimate interest to do so as the data controller.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td border="1px" borderColor="gray.200" fontWeight="semibold">
                      Purpose
                    </Td>
                    <Td border="1px" borderColor="gray.200" whiteSpace="normal">
                      <List spacing={2}>
                        <ListItem>
                          Providing, Customizing and Improving the Website and/or Services
                        </ListItem>
                        <ListItem>
                          Providing you with the products, Services or information you request.
                        </ListItem>
                        <ListItem>Providing Services to our clients.</ListItem>
                        <ListItem>
                          Meeting or fulfilling the reason you provided the information to us.
                        </ListItem>
                        <ListItem>
                          Providing support and assistance for the Website and/or Services and
                          processing transactions and orders.
                        </ListItem>
                        <ListItem>
                          Setting up your online account and sending you administrative or account
                          related information.
                        </ListItem>
                        <ListItem>
                          Improving the Website and/or Services, including testing, research,
                          internal analytics and product development.
                        </ListItem>
                        <ListItem>
                          To manage your account, for customer relationship management, to provide
                          support and other related services.
                        </ListItem>
                        <ListItem>
                          Personalising the Website and/or Services, Website content and
                          communications based on your preferences.
                        </ListItem>
                        <ListItem>To obtain your feedback or user experience.</ListItem>
                        <ListItem>Doing fraud protection, security and debugging.</ListItem>
                        <ListItem>To identify trends and address Service issues.</ListItem>
                        <ListItem>
                          To maintain security and identify any server problems or other IT or
                          network issues.
                        </ListItem>
                        <ListItem>
                          For other legitimate business purposes such as audits, for marketing and
                          customer relationships, record keeping, business planning and management.
                        </ListItem>
                        <ListItem>
                          For business transactions such as a merger, restructuring or a sale.
                        </ListItem>
                        <ListItem>
                          {`Carrying out other business purposes stated when collecting your Personal
                          Data or as otherwise set forth in applicable data privacy laws, such as
                          the California Consumer Privacy Act (the "CCPA").`}
                        </ListItem>
                        <ListItem>
                          Marketing the Services in accordance with your preferences, marketing and
                          selling the Services.
                        </ListItem>
                        <ListItem>Corresponding with You</ListItem>
                        <ListItem>
                          Responding to correspondence that we receive from you, contacting you when
                          necessary or requested, and sending you information about Keywords or the
                          Services.
                        </ListItem>
                        <ListItem>
                          Sending emails and other communications according to your preferences or
                          that display content that we think will interest you.
                        </ListItem>
                      </List>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>

              {/* Legal Compliance Section */}
              <Table variant="striped" colorScheme="gray" mb={6}>
                <Thead bg="gray.100">
                  <Tr>
                    <Th colSpan={2} border="1px" borderColor="gray.200">
                      {`For compliance with Keywords' legal obligations, including:`}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td border="1px" borderColor="gray.200" fontWeight="semibold" width="30%">
                      Legal Basis
                    </Td>
                    <Td border="1px" borderColor="gray.200">
                      We may obtain, collect and process your Personal Data when it is necessary in
                      order to comply with legal obligations imposed under applicable EU Member
                      State, European Union law or UK law.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td border="1px" borderColor="gray.200" fontWeight="semibold">
                      Purpose
                    </Td>
                    <Td border="1px" borderColor="gray.200" whiteSpace="normal">
                      <List spacing={2} as="ol" styleType="decimal">
                        <ListItem>
                          Fulfilling our legal obligations under applicable law, regulation, court
                          order or other legal process, such as preventing, detecting and
                          investigating security incidents and potentially illegal or prohibited
                          activities.
                        </ListItem>
                        <ListItem>
                          Protecting the rights, property or safety of you, Keywords or another
                          party.
                        </ListItem>
                        <ListItem>Enforcing any agreements with you.</ListItem>
                        <ListItem>Resolving disputes.</ListItem>
                        <ListItem>
                          Where necessary to establish, exercise or defend Keywords legal rights or
                          for the purpose of legal proceedings
                        </ListItem>
                      </List>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>

              {/* Consent Section */}
              <Alert status="warning" variant="left-accent" borderRadius="md" mt={6}>
                <AlertIcon />
                <Text>
                  Where you have provided your consent to us using the Personal Data for a
                  particular purpose. If you give consent for Keywords to use your Personal Data for
                  a particular purpose, you have the right at any time to withdraw consent to the
                  future use of that Personal Data for some or all of those purposes by writing to
                  the address specified below. (See{' '}
                  <Link href="#contact" color="blue.500">
                    Contact Us
                  </Link>
                  ).
                </Text>
              </Alert>
            </Box>
          </Box>
        </Box>

        {/* Section 2 */}
        <Box id="section-2" mb={12}>
          <Heading size="xl" mb={6}>
            2. How We Share Your Personal Data
          </Heading>
          <Box>
            <Alert status="info" variant="left-accent" mb={4} borderRadius="md">
              <AlertIcon />
              <Box>
                <Text>
                  {`We disclose your Personal Data to the categories of service providers and other
                  parties listed in this section. Depending on state laws that may be applicable to
                  you, some of these disclosures may constitute a "sale" of your Personal Data. For
                  more information, please refer to the state-specific sections below.`}
                </Text>
              </Box>
            </Alert>

            <Box mb={6}>
              <Heading size="md" mb={3}>
                Service Providers
              </Heading>
              <Text>
                {`We will share Personal Data with our service providers or other entities acting as
                Keywords' agents or data processors for the purposes of providing services to
                Keywords and on the understanding that Personal Data will be processed in line with
                legal obligations imposed. These parties help us to provide the Website and/or
                Services or to perform business functions on our behalf. They include hosting,
                technology and communication providers.`}
              </Text>
            </Box>

            <Box mb={6}>
              <Heading size="md" mb={3}>
                Customers
              </Heading>
              <Text mb={2}>
                We will share Personal Data with our customers to enable them to carry out the
                obligations under the contract that we have with our customers and other commercial
                partners where agreed.
              </Text>
              <Alert status="info" variant="subtle" borderRadius="md">
                <AlertIcon />
                Note that we will only share your Personal Data with the specific customer that
                contracted with Keywords to make the Services available unless otherwise agreed.
              </Alert>
            </Box>

            <Box mb={6}>
              <Heading size="md" mb={3}>
                Legal Obligations
              </Heading>
              <Text>
                {`We may share any Personal Data where this is required by law or regulation, or court
                or administrative order having force of law, or where required by any of Keywords'
                regulators.`}
              </Text>
            </Box>

            <Box mb={6}>
              <Heading size="md" mb={3}>
                Business Transfers
              </Heading>
              <Text>
                All of your Personal Data that we collect may be transferred to a different entity,
                and our legal or other advisors, if we undergo, or evaluate, a merger, acquisition,
                bankruptcy or other transaction (or proposed transaction) in which that entity
                assumes control of our business or assets of our business (in whole or in part).
              </Text>
            </Box>

            <Box mb={6}>
              <Heading size="md" mb={3}>
                Data that is Not Personal Data
              </Heading>
              <Box bg="gray.50" p={4} borderRadius="md">
                <Text>
                  We may create aggregated, de-identified or anonymized data from the Personal Data
                  that we collect, including by removing information that makes the data personally
                  identifiable to a particular user. We may use such aggregated, de-identified or
                  anonymized data and share it with third parties for our lawful business purposes,
                  including to analyze, build and improve the Services and promote our business,
                  provided that we will not share such data in a manner that could identify you.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Section 3 */}
        <Box id="section-3" mb={12}>
          <Heading size="xl" mb={6}>
            3. International Data Transfers
          </Heading>
          <Text mb={4}>
            {`            We may create aggregated, de-identified or anonymized data from the Personal Data that
            we collect, including by removing information that makes the data personally
            identifiable to a particular user. We may use such aggregated, de-identified or
            anonymized data and share it with third parties for our lawful business purposes,
            including to analyze, build and improve the Services and promote our business, provided
            that we will not share such data in a manner that could identify you.`}
          </Text>
          <Text mb={4}>
            {`Many of the
            countries will be within the EEA or will be ones which the European Commission has
            approved and will have data privacy laws which are the same as or broadly equivalent to
            those in the European Union. However, some transfers may be to countries which do not
            have equivalent protections, and, in that case, Keywords will use reasonable efforts to
            implement contractual protections for the Personal Data. While this may not always be
            possible, any transfers will be done in accordance with applicable data privacy laws,
            including through the implementation of appropriate or suitable safeguards, including
            Standard Contractual Clauses approved by the European Commission, in accordance with
            such applicable data privacy laws. In particular, Keywords is a party to an Intra-Group
            Data Transfer Agreement which governs the transfer of Personal Data across all members
            of the Keywords Group in a manner consistent with the requirements of the applicable
            data privacy laws.`}
          </Text>
        </Box>

        {/* Section 4 - Data Security and Retention */}
        <Box id="section-4" mb={12}>
          <Heading size="xl" mb={6}>
            4. Data Security and Retention
          </Heading>
          <Text mb={4}>
            {`We seek to protect your Personal Data from unauthorized access, use and disclosure using
            appropriate physical, technical, organizational and administrative security measures
            based on the type of Personal Data and how we are processing that Personal Data.
            Although we work to protect the security of your data that we hold in our records,
            please be aware that no method of transmitting data over the internet or storing data is
            completely secure.`}
          </Text>
          <Text mb={4}>
            {`We retain Personal Data about you for as long as necessary to provide you with our
            Services. In some cases, we retain Personal Data for longer, if doing so is necessary to
            comply with our legal obligations, resolve disputes or collect fees owed, or is
            otherwise permitted or required by applicable law, rule or regulation. In general,
            Keywords (or its service providers on its behalf) will hold this information for a
            period of seven years after you cease to interact with us, unless we are obliged to hold
            it for a longer period under law or applicable regulations. We may further retain
            information in an anonymous or aggregated form where that information would not identify
            you personally.`}
          </Text>
        </Box>

        {/* Section 5 - Automated Decisions */}
        <Box id="section-5" mb={12}>
          <Heading size="xl" mb={6}>
            5. Automated Decisions
          </Heading>
          <Text mb={4}>
            {`Automated decisions are defined as decisions about individuals that are based solely on
            the automated processing of data and that produce legal effects that significantly
            affect the individuals involved. Keywords does not make automated decisions in relation
            to you or to your Personal Data. If automated decisions are made, the affected persons
            will be given an opportunity to express their views on the automated decision in
            question and to object to it.`}
          </Text>
        </Box>

        {/* Section 6 - Third Party Website/Services */}
        <Box id="section-6" mb={12}>
          <Heading size="xl" mb={6}>
            6. Third Party Website/Services
          </Heading>
          <Text mb={4}>
            {`The Website may permit you to link to other websites on the Internet, and other websites
            may contain links to the Website. These other websites are not under Keywords control,
            and such links do not constitute an endorsement by Keywords of those other websites or
            the services offered through them. The privacy and security practices of websites linked
            to or from the Service are not covered by this Privacy Notice, and Keywords is not
            responsible for the privacy or security practices or the content of such websites.`}
          </Text>
        </Box>

        {/* Section 7 - Personal Data of Children */}
        <Box id="section-7" mb={12}>
          <Heading size="xl" mb={6}>
            7. Personal Data of Children
          </Heading>
          <Text mb={4}>
            {`Automated decisions are defined as decisions about individuals that are based solely on
            the automated processing of data and that produce legal effects that significantly
            affect the individuals involved. Keywords does not make automated decisions in relation
            to you or to your Personal Data. If automated decisions are made, the affected persons
            will be given an opportunity to express their views on the automated decision in
            question and to object to it.`}
          </Text>
        </Box>

        {/* Section 8 - Your Rights in relation to Personal Data */}
        <Box id="section-8" mb={12}>
          <Heading size="xl" mb={6}>
            8. Your Rights in relation to Personal Data
          </Heading>
          <Box>
            <Text mb={4}>
              Depending on the laws of the state or country in which you are located or reside, you
              may have certain rights with respect to your Personal Data, including those set forth
              below. Residents of certain states or countries may have different or additional
              rights, which you can find below in the state-specific sections. For more information
              about these rights, or to submit a request, please email us at{' '}
              <Link href="mailto:privacy@helpshift.com" color="blue.500">
                privacy@helpshift.com
              </Link>
              .
            </Text>

            <List spacing={4}>
              <ListItem>
                <Text fontWeight="semibold">Access:</Text>
                <Text>
                  You may at any time request a copy of your Personal Data from Keywords. This right
                  can be exercised by writing to us at{' '}
                  <Link href="mailto:privacy@helpshift.com" color="blue.500">
                    privacy@helpshift.com
                  </Link>
                  .
                </Text>
              </ListItem>

              <ListItem>
                <Text fontWeight="semibold">Rectification, Erasure & Restriction:</Text>
                <Text>
                  {`If you believe that any Personal Data we are holding about you is incorrect or
                  incomplete, you have the right to correct any inaccuracies, and in certain
                  circumstances, to request erasure, or restriction on the use, of your Personal
                  Data, and to object to certain uses of your Personal Data, in each case subject to
                  the restrictions set out in applicable data privacy laws. Further information on
                  these rights, and the circumstances in which they may arise in connection with
                  Keywords' processing of Personal Data, can be obtained by writing to us at`}
                  <Link href="mailto:privacy@helpshift.com" color="blue.500">
                    privacy@helpshift.com
                  </Link>
                </Text>
              </ListItem>

              <ListItem>
                <Text fontWeight="semibold">Withdrawal of Consent:</Text>
                <Text>
                  If we are processing your Personal Data based on your consent (as indicated at the
                  time of collection of such data), you have the right to change your mind and
                  withdraw your consent to future processing of Personal Data by writing to us at{' '}
                  <Link href="mailto:privacy@helpshift.com" color="blue.500">
                    privacy@helpshift.com
                  </Link>
                  .
                </Text>
              </ListItem>

              <ListItem>
                <Text fontWeight="semibold">Portability:</Text>
                <Text>
                  You can ask for a copy of your Personal Data in a machine-readable format. You can
                  also request that we transmit the data to another data controller where
                  technically feasible.
                </Text>
              </ListItem>

              <ListItem>
                <Text fontWeight="semibold">Objection:</Text>
                <Text>
                  Where Keywords is relying on a legitimate interest, in order to use and disclose
                  Personal Data, you may to object to such use or disclosure of your Personal Data,
                  and we will cease to use and process the Personal Data for that purpose, unless we
                  can show there are compelling legitimate reasons for processing to continue or we
                  need to use the Personal Data for the purposes of legal claims.
                </Text>
              </ListItem>

              <ListItem>
                <Text fontWeight="semibold">Right to File Complaint:</Text>
                <Text>
                  {`You have the right to lodge a complaint about Keywords' practices with respect to
                  your Personal Data with the supervisory authority of your country or EU Member
                  State. A list of Supervisory Authorities is available here:`}
                  <Link
                    href="https://edpb.europa.eu/about-edpb/board/members_en"
                    color="blue.500"
                    isExternal
                  >
                    https://edpb.europa.eu/about-edpb/board/members_en
                  </Link>
                  .
                </Text>
              </ListItem>
            </List>

            <Alert status="warning" mt={6} borderRadius="md" variant="left-accent">
              <AlertIcon />
              <Box>
                <Text>
                  {`Please note that in some circumstances, we may not be able to fully comply with
                  your request, such as if it is frivolous or extremely impractical, if it
                  jeopardizes the rights of others, or if it is not required by law, but in those
                  circumstances, we will still respond to notify you of such a decision. In some
                  cases, we may also need you to provide us with additional information, which may
                  include Personal Data, if necessary to verify your identity and the nature of your
                  request.`}
                </Text>
              </Box>
            </Alert>
          </Box>
        </Box>

        {/* Section 9 - Philippines */}
        <Box id="section-9" mb={12}>
          <Heading size="xl" mb={6}>
            9. Philippines Residents Rights
          </Heading>
          <Box>
            <Alert status="info" variant="left-accent" mb={4} borderRadius="md">
              <AlertIcon />
              <Text>
                If you are a resident of the Philippines, this section forms part of the Privacy
                Notice and should be read together with the other sections of the Privacy Notice. If
                there is any conflict between this section and the remainder of the Privacy Notice,
                this section shall prevail.
              </Text>
            </Alert>

            <Heading size="sm" fontWeight="normal" mb={4}>
              As a resident of the Philippines, you are entitled to the following rights:
            </Heading>

            <List spacing={6} pl={6}>
              {/* Right to be Informed */}
              <ListItem>
                <Text fontWeight="semibold">Right to be informed:</Text>
                <Text>
                  You have the right to be informed whether Personal Data pertaining to you shall
                  be, is being, or has been processed, including the existence of automated
                  decision-making and profiling.
                </Text>
              </ListItem>

              {/* Access */}
              <ListItem>
                <Text fontWeight="semibold">Access:</Text>
                <Text>
                  You may at any time request a copy of your Personal Data from Keywords. This right
                  can be exercised by writing to us at{' '}
                  <Link href="mailto:privacy@helpshift.com" color="blue.500">
                    privacy@helpshift.com
                  </Link>
                  . You should be given access to information about, and obtain a copy of your
                  Personal Data that we are processing, including information on the following where
                  applicable:
                </Text>
                <List spacing={2} mt={4} styleType="number">
                  <ListItem>
                    Contents of Personal Data and categories of data that were processed;
                  </ListItem>
                  <ListItem>
                    Sources from which your Personal Data were obtained, if the data was not
                    collected from you;
                  </ListItem>
                  <ListItem>Purposes of processing;</ListItem>
                  <ListItem>Identities and addresses of recipients of Personal Data;</ListItem>
                  <ListItem>Manner by which your Personal Data was processed;</ListItem>
                  <ListItem>
                    Reasons for the disclosure and purposes for granting access to the recipients of
                    your Personal Data;
                  </ListItem>
                  <ListItem>
                    Information on automated processing, in case the data was used as the sole basis
                    for any decision that significantly affects or will significantly affect you as
                    a data subject;
                  </ListItem>
                  <ListItem>Date when your Personal Data was last accessed or modified;</ListItem>
                  <ListItem>
                    The designation, identity, and address of the data controller and/or data
                    processor other than the Keywords;
                  </ListItem>
                  <ListItem>
                    Period for which particular categories of information will be stored; and
                  </ListItem>
                  <ListItem>
                    The designation, name or identity, and address of the Keywords’ data protection
                    officer, if any.
                  </ListItem>
                </List>
              </ListItem>

              {/* Rectification & Restriction */}
              <ListItem>
                <Text fontWeight="semibold">Rectification & Restriction:</Text>
                <Text>
                  {`If you believe that any Personal Data we are holding about you is incorrect or
                  incomplete, you have the right to correct any inaccuracies in, to request
                  completion of incomplete data, and in certain circumstances, to request erasure,
                  or restriction on the use, of your Personal Data, and to object to certain uses of
                  your Personal Data. Further information on these rights, and the circumstances in
                  which they may arise in connection with Keywords' processing of Personal Data, can
                  be obtained by writing to us at`}
                  <Link href="mailto:privacy@helpshift.com" color="blue.500">
                    privacy@helpshift.com
                  </Link>
                  .
                </Text>
                <Text mt={2}>
                  If the Personal Data has been corrected, Keywords shall ensure the accessibility
                  of both the new and the retracted information and the simultaneous receipt of the
                  new and the retracted information by the intended recipients thereof, provided
                  that recipients or other entities who have previously received such processed
                  Personal Data shall be informed of its inaccuracy and its rectification, upon your
                  reasonable request.
                </Text>
              </ListItem>

              {/* Erasure or Blocking */}
              <ListItem>
                <Text fontWeight="semibold">Erasure or Blocking:</Text>
                <Text>
                  {`You have the right to request the suspension, withdrawal, or order the blocking,
                  removal, deletion, or destruction of your Personal Data from the Keywords' filing
                  system under certain circumstances, in particular when:`}
                </Text>
                <List spacing={2} pl={2} mt={2} styleType="number">
                  <ListItem>
                    the Personal Data is no longer necessary in relation to the purposes for which
                    it was originally collected or otherwise processed;
                  </ListItem>
                  <ListItem>
                    you object to the processing of your data and there are no overriding legitimate
                    interests that require continued processing;
                  </ListItem>
                  <ListItem>
                    you withdraw your consent, where provided, and there is no other legal ground
                    for the processing;
                  </ListItem>
                  <ListItem>
                    the Personal Data is incomplete, outdated, false, or has been unlawfully
                    processed;
                  </ListItem>
                  <ListItem>
                    the Personal Data has to be erased in order for Keywords to comply with a legal
                    obligation. You do not have the right to request the deletion of your Personal
                    Data when the processing of your Personal Data is necessary for compliance with
                    a legal obligation or for the establishment, exercise, or defense of legal
                    claims;
                  </ListItem>
                  <ListItem>
                    the Personal Data concerns private information that is prejudicial to you,
                    unless justified by freedom of speech, of expression, or of the press or
                    otherwise authorized;
                  </ListItem>
                  <ListItem>the processing is unlawful; or</ListItem>
                  <ListItem>
                    Keywords or the data processor on their behalf violated your rights as a data
                    subject.
                  </ListItem>
                </List>
              </ListItem>
              <Text mt={4} ml={-4}>
                Keywords may notify other entities who have previously received such processed
                Personal Data.
              </Text>

              {/* Withdrawal of Consent */}
              <ListItem>
                <Text fontWeight="semibold">Withdrawal of Consent:</Text>
                <Text>
                  If we are processing your Personal Data based on your consent (as indicated at the
                  time of collection of such data), you have the right has the right to change your
                  mind and withdraw consent to future processing of Personal Data by writing to us
                  at{' '}
                  <Link href="mailto:privacy@helpshift.com" color="blue.500">
                    privacy@helpshift.com
                  </Link>
                  .
                </Text>
                <Alert status="warning" mt={2} borderRadius="md" variant="left-accent">
                  <AlertIcon />
                  <Box>
                    <Text>
                      This will not affect the lawfulness of any processing carried out before you
                      withdraw your consent.
                    </Text>
                    <Text mt={1}>
                      If you withdraw your consent, we may not be able to provide certain products
                      or Services to you. We will advise you if this is the case at the time you
                      withdraw your consent.
                    </Text>
                  </Box>
                </Alert>
              </ListItem>

              {/* Portability */}
              <ListItem>
                <Text fontWeight="semibold">Portability:</Text>
                <Text>
                  You can ask for a copy of your Personal Data in a machine-readable format. You can
                  also request that we transmit the data to another data controller, where
                  technically feasible, in an electronic or structured format that is commonly used
                  and allows for your further use.
                </Text>
              </ListItem>

              {/* Objection */}
              <ListItem>
                <Text fontWeight="semibold">Objection:</Text>
                <Text>
                  You shall have the right to object to the processing of your Personal Data,
                  including processing for direct marketing, automated processing or profiling. You
                  shall also be notified and given an opportunity to withhold consent to the
                  processing in case of changes or any amendment to the information supplied or
                  declared to you. When you object or withhold consent, Keywords shall no longer
                  process the Personal Data, unless:
                </Text>
                <List spacing={2} pl={6} mt={2} as="ol" styleType="lower-alpha">
                  <ListItem>The Personal Data is needed pursuant to a subpoena</ListItem>
                  <ListItem>The collection and processing are for obvious purposes...</ListItem>
                  <ListItem>
                    The information is being collected and processed as a result of a legal
                    obligation
                  </ListItem>
                </List>
              </ListItem>

              {/* Right to File Complaint */}
              <ListItem>
                <Text fontWeight="semibold">Right to File Complaint:</Text>
                <Text>
                  {`You have the right to lodge a complaint about Keywords' practices with respect to
                  your Personal Data with the National Privacy Commission through its website:`}
                  <Link href="https://www.privacy.gov.ph" color="blue.500" isExternal>
                    https://www.privacy.gov.ph
                  </Link>
                  .
                </Text>
              </ListItem>

              {/* Right to damages */}
              <ListItem>
                <Text fontWeight="semibold">Right to damages:</Text>
                <Text>
                  You may be entitled to compensation for damages you sustain due to such
                  inaccurate, incomplete, outdated, false, unlawfully obtained or unauthorized use
                  of your Personal Data, taking into account any violation of your rights and
                  freedoms as a data subject.
                </Text>
              </ListItem>
            </List>
          </Box>
        </Box>

        {/* Section 10 - Indian Residents Rights */}
        <Box id="section-10" mb={12}>
          <Heading size="xl" mb={6}>
            10. Indian Residents Rights
          </Heading>
          <Box>
            {/* Indian Residents Section */}
            <Alert status="info" variant="left-accent" mb={4} borderRadius="md">
              <AlertIcon />
              <Box>
                <Text>
                  If you are an Indian resident, this section forms part of the Privacy Notice and
                  should be read together with the other sections of the Privacy Notice regardless
                  of how you access the Services or the Website (whether by computer, mobile device,
                  or otherwise) and regardless of whether you are a registered user or a guest. If
                  there is any conflict between this section and the remainder of the Privacy
                  Notice, this section shall prevail. If you have any questions about this section
                  or whether any of the following rights apply to you, please contact us at{' '}
                  <Link href="mailto:privacy@helpshift.com" color="blue.500">
                    privacy@helpshift.com
                  </Link>
                  .
                </Text>
              </Box>
            </Alert>

            {/* Usage Agreement */}
            <Box bg="gray.50" p={4} borderRadius="md" mb={6} borderWidth="1px">
              <Text>
                By using the Services or the Website, you agree to the Privacy Notice. If you do not
                agree to the Privacy Notice, you are not authorized to use the Services or the
                Website as appropriate and you must cease all such use immediately.
              </Text>
            </Box>

            {/* Children's Data */}
            <Heading size="md" mb={4}>
              Personal Data of Children
            </Heading>
            <Alert status="warning" variant="left-accent" mb={4}>
              <AlertIcon />
              <Box>
                <Text>
                  We do not knowingly collect or solicit Personal Data about or from individuals
                  under 18 years of age in India. If you are an individual under the age of 18,
                  please do not attempt to register for or otherwise use the Services or send us any
                  Personal Data, and you may use the Services or send us any Personal Data only
                  after your parent/guardian have consented to your use of the Services, on your
                  behalf.
                </Text>
                <Text mt={2}>
                  If we learn we have collected Personal Data from an individual under 18 years of
                  age, without consent of your parent/guardian, we will delete that information as
                  quickly as possible. If you believe that an individual under 18 years of age may
                  have provided Personal Data to us, please contact us at{' '}
                  <Link href="mailto:privacy@helpshift.com" color="blue.500">
                    privacy@helpshift.com
                  </Link>
                  .
                </Text>
              </Box>
            </Alert>

            {/* Data Security */}
            <Heading size="md" mb={4}>
              Data Security
            </Heading>
            <Text mb={4}>
              Apart from the above mentioned Data Security practices under the{' '}
              <Link href="#data-security" color="blue.500">
                Data Security and Retention
              </Link>{' '}
              section above, Keywords take all commercially reasonable steps to ensure your
              information is protected in alignment with all applicable laws and regulations,
              including by implementing various information security policies applicable to its
              personnel.
            </Text>

            {/* Consent Section */}
            <Box bg="blue.50" p={4} borderRadius="md" borderWidth="1px" borderColor="blue.100">
              <Heading size="md" mb={3}>
                Consent to Collection of Personal Data
              </Heading>
              <Text>
                We will obtain your consent to the collection, use and/or disclosure of your
                Personal Data. Where there are changes to the Purposes for processing, we will
                obtain your consent to such changes.
              </Text>
            </Box>
          </Box>
        </Box>

        {/* Section 11 - Singapore Residents Rights */}
        <Box id="section-11" mb={12}>
          <Heading size="xl" mb={6}>
            11. Singapore Residents Rights
          </Heading>
          <Box>
            {/* Singapore Notice */}
            <Alert status="info" variant="left-accent" mb={4} borderRadius="md">
              <AlertIcon />
              <Text>
                If you are based in Singapore, this section forms part of the Privacy Notice and
                should be read together with the other sections of the Privacy Notice. If there is
                any conflict between this section and the remainder of the Privacy Notice, this
                section shall prevail.
              </Text>
            </Alert>

            {/* Consent Section */}
            <Box mb={6}>
              <Heading size="md" mb={2}>
                Consent to Collection of Personal Data
              </Heading>
              <Text>
                We will obtain your consent to the collection, use and/or disclosure of your
                Personal Data. Where there are changes to the Purposes for processing, we will
                obtain your consent to such changes.
              </Text>
            </Box>

            {/* Children's Data */}
            <Box mb={6}>
              <Heading size="md" mb={2}>
                Personal Data of Children
              </Heading>
              <Alert status="warning" variant="left-accent" borderRadius="md">
                <AlertIcon />
                <Box>
                  <Text>
                    We do not knowingly collect or solicit Personal Data about children under 21
                    years of age in the Philippines. If you are a child under the age of 21, please
                    do not attempt to register for or otherwise use the Services or the Website or
                    send us any Personal Data. If we learn we have collected Personal Data from a
                    child under 21 years of age, we will delete that information as quickly as
                    possible. If you believe that a child under 21 years of age may have provided
                    Personal Data to us, please contact us at{' '}
                    <Link href="mailto:privacy@helpshift.com" color="blue.500">
                      privacy@helpshift.com
                    </Link>
                    .
                  </Text>
                </Box>
              </Alert>
            </Box>

            {/* Your Rights */}
            <Box mb={6}>
              <Heading size="md" mb={4}>
                Your Rights in relation to Personal Data
              </Heading>
              <List spacing={4}>
                <ListItem>
                  <Text fontWeight="semibold">Access:</Text>
                  <Text>
                    You have a right of access to the Personal Data we process about you. You may at
                    any time request a copy of your Personal Data from Keywords. You also have a
                    right to information on how we have used or disclosed your Personal Data in the
                    12 months prior to your request. This right can be exercised by writing to us at{' '}
                    <Link href="mailto:privacy@helpshift.com" color="blue.500">
                      privacy@helpshift.com
                    </Link>
                    .
                  </Text>
                </ListItem>

                <ListItem>
                  <Text fontWeight="semibold">Rectification:</Text>
                  <Text>
                    If you believe that any Personal Data we are holding about you is incorrect or
                    incomplete, you have the right to correct any inaccuracies in your Personal Data
                    free of charge. Further information on this right can be obtained by writing to
                    us at{' '}
                    <Link href="mailto:privacy@helpshift.com" color="blue.500">
                      privacy@helpshift.com
                    </Link>
                    .
                  </Text>
                </ListItem>

                <ListItem>
                  <Text fontWeight="semibold">Withdrawal of Consent:</Text>
                  <Text>
                    If we are processing your Personal Data based on your consent (as indicated at
                    the time of collection of such data), you have the right has the right to change
                    your mind and withdraw consent to future processing of Personal Data by writing
                    to us at{' '}
                    <Link href="mailto:privacy@helpshift.com" color="blue.500">
                      privacy@helpshift.com
                    </Link>
                    .
                  </Text>
                  <Alert status="info" mt={2} borderRadius="md" variant="subtle">
                    <AlertIcon />
                    However, depending on the nature of the consent that is withdrawn, you may no
                    longer be able to visit the Website or use the Services. Any withdrawal of your
                    consent will not affect the processing of Personal Data which occurred before
                    you withdrew your consent.
                  </Alert>
                </ListItem>

                <ListItem>
                  <Text fontWeight="semibold">Right to File Complaint:</Text>
                  <Text>
                    {`You have the right to lodge a complaint about Keywords' practices with respect
                    to your Personal Data with the competent data protection authority through its
                    website:`}
                    <Link href="https://www.pdpc.gov.sg" color="blue.500" isExternal>
                      https://www.pdpc.gov.sg
                    </Link>
                    .
                  </Text>
                </ListItem>
              </List>

              <Text mt={4}>
                To exercise the above rights with regard to the processing activities for which we
                are the data controller, please contact us by e-mail or regular mail (see below for
                our contact details). Your request should be accompanied by your full contact
                details and a means of verifying your identity (e.g. a copy of the front of your ID
                card or driving licence). We may request further information as necessary in order
                to verify your identity or for security purposes. Where permitted to do so under
                applicable law, we may charge a reasonable fee for processing your request. We may
                also reject your requests where permitted to do so by law.
              </Text>
            </Box>

            {/* Data Protection Officer */}
            <Box bg="blue.50" p={4} borderRadius="md" borderWidth="1px" borderColor="blue.100">
              <Heading size="md" mb={2}>
                Data Protection Officer
              </Heading>
              <Text>
                Our Data Privacy Officer, as appointed in Singapore, can be contracted by writing to
                us at{' '}
                <Link href="mailto:privacy@helpshift.com" color="blue.500">
                  privacy@helpshift.com
                </Link>
                .
              </Text>
            </Box>
          </Box>
        </Box>

        {/* Section 12 - Rights under Canadian Privacy Law */}
        <Box id="section-12" mb={12}>
          <Heading size="xl" mb={6}>
            12. Rights under Canadian Privacy Law
          </Heading>
          <Text>
            If you are based in Canada, this section forms part of the Privacy Notice and should be
            read together with the other sections of the Privacy Notice. Depending on your province
            of residency in Canada, your rights under Canadian privacy legislation may differ. If
            there is any conflict between this section and the remainder of the Privacy Notice, the
            relevant part of this section shall prevail.
          </Text>
        </Box>

        {/* Section 12.1 - Québec residents */}
        <Box id="section-12.1" mb={12}>
          <Heading size="xl" mb={6}>
            12.1 Québec residents
          </Heading>
          <Box mb={12}>
            <Text mb={6}>
              Québec privacy law regulates the collection and use of Personal Data using technology
              which allows a person to be tracked or profiled. This includes the use of geolocation
              technology.
            </Text>

            <Alert status="info" mb={6}>
              <AlertIcon />
              “Profiling” is defined as “the collection and use of personal information to assess
              certain characteristics of a natural person, in particular for the purpose of
              analysing that person’s work performance, economic situation, health, personal
              preferences, interests or behaviour”.
            </Alert>

            <Text mb={6}>
              We do monitor web page interactions and track your location when using the Website, if
              and when you choose to activate such technologies by accepting cookies on the website.
              For further information, please consult our
              <Link href="/cookies-policy" color="blue.500">
                Cookies Policy
              </Link>{' '}
              available on our website.
            </Text>

            <Box mb={12}>
              <Heading size="md" mb={6}>
                Consent to Collection of Personal Data
              </Heading>
              <Text mb={6}>
                Generally speaking, we will obtain your consent prior to any collection, use or
                disclosure of your Personal Data, except where such collection, use or disclosure is
                authorized or required by applicable law. You are generally not obliged to provide
                your Personal Data, however, please note that, in certain cases, we may be unable to
                provide certain Services to you or enable access to the Website if you refuse to
                provide certain Personal Data.
              </Text>
              <Text mb={6}>
                You have the right to change your mind and withdraw consent to future processing of
                Personal Data by writing to us at{' '}
                <Link href="mailto:privacy@helpshift.com" color="blue.500">
                  privacy@helpshift.com
                </Link>
                . Please note that such withdrawal may impact our continued ability to provide
                certain Services or access to the Website to you. Such withdrawal does not affect
                the lawfulness of any processing which took place prior to such withdrawal, nor does
                it create an obligation for us to delete Personal Data which we are otherwise
                allowed or required to retain under applicable law.
              </Text>
            </Box>

            <Box mb={12}>
              <Heading size="md" mb={6}>
                Further Processing
              </Heading>
              <Text mb={6}>
                We will only use your Personal Data for the purposes for which we collect it (as
                outlined above), unless we reasonably consider that we need to use it for another
                reason and that reason is compatible with the original purpose, it is clearly to the
                benefit of the person concerned or is otherwise authorized or required by applicable
                law. If we need to use your Personal Data for any other reasonable purposes in
                connection with our engagement with you, the purpose for any further processing will
                be made known to you in advance. We may ask you to provide additional consent to
                such processing, if and to the extent required by applicable law.
              </Text>
              <Text mb={6}>
                If you provide your social network account credentials to us or otherwise sign-in to
                the Services through a third-party site or service, some content and/or information
                in those accounts may be transmitted into your account with us. This will be
                specifically addressed in the relevant app privacy notice or software-specific
                privacy notice to which you are required to give consent before installing same.
              </Text>
            </Box>

            <Box mb={12}>
              <Heading size="md" mb={6}>
                Sharing Your Personal Data with Other Entities
              </Heading>
              <Text mb={6}>
                We will share Personal Data with our service providers or any other entities acting
                as Keywords’ agents or data processors for the purposes of providing services to
                Keywords and on the understanding that Personal Data will only be processed in line
                with our instructions and in compliance with all applicable legal obligations.
                Generally, we may share Personal Data where this is authorized or required by law
                (including by a court or administrative body having order-making or relevant
                regulatory power).
              </Text>
              <Text mb={6}>
                All of your Personal Data that we collect may be transferred to another entity if we
                undergo a merger, acquisition, bankruptcy or other transaction (or proposed
                transaction) in which that other entity assumes control of our business (in whole or
                in part), to the extent necessary to give effect to such transaction. In such
                instances we will take steps to ensure that the third party has agreed in writing to
                protect your Personal Data, and that you are notified in writing of the transfer
                where possible.
              </Text>
            </Box>

            <Box mb={12}>
              <Heading size="md" mb={6}>
                Data Transfer
              </Heading>
              <Text mb={6}>
                Generally, transfers of Personal Data outside Québec will not occur unless and until
                a data privacy assessment has been undertaken by us and appropriate safeguards have
                been put in place to protect the Personal Data.
              </Text>
            </Box>

            <Box mb={12}>
              <Heading size="md" mb={6}>
                Data Retention
              </Heading>
              <Text mb={6}>
                We retain Personal Data about you for as long as necessary to provide you with our
                Services. In some cases, we retain Personal Data for longer, if doing so is
                necessary to comply with our legal obligations, resolve disputes or collect fees
                owed, or is otherwise authorized or required by applicable law, rule or regulation.
                In general, Keywords (or its service providers on its behalf) will hold this
                information for a period of{' '}
                <Text as="span" fontWeight="bold">
                  seven years
                </Text>{' '}
                after you cease to interact with us, unless it is obliged to hold it for a longer
                period under applicable law or regulatory requirements. To the extent permitted by
                applicable law, we may further retain information in an anonymous or aggregated form
                where that information would not identify you personally, whether directly or
                indirectly.
              </Text>
            </Box>

            <Box mb={12}>
              <Heading size="md" mb={6}>
                Your Rights
              </Heading>
              <Text mb={6}>
                You have certain rights with respect to your Personal Data, including those set
                forth below.
              </Text>

              <UnorderedList mb={6} spacing={2} pl={4}>
                <ListItem>
                  You have the right to request a copy of your Personal Data from Keywords.
                </ListItem>
                <ListItem>
                  If you believe that any Personal Data we are holding about you is incorrect or
                  incomplete, you have the right to request correction of any inaccuracies and, in
                  certain circumstances, to request erasure of the Personal Data.
                </ListItem>
                <ListItem>
                  You may request, in certain circumstances, that we cease disseminating your
                  Personal Data or to de-index any hyperlink that allows access to that Personal
                  Data by technological means, if such dissemination contravenes applicable law or a
                  court.
                </ListItem>
              </UnorderedList>

              <Text mb={6}>
                Any such access/correction/erasure requests can be made to Keywords’ Québec Data
                Protection Officer at{' '}
                <Link href="mailto:privacy@helpshift.com" color="blue.500">
                  privacy@helpshift.com
                </Link>
                .
              </Text>
            </Box>
          </Box>
        </Box>

        {/* Section 12.2 - Canadian Residents Outside the Province of Québec */}
        <Box id="section-12.2" mb={12}>
          <Heading size="xl" mb={6}>
            12.2 Canadian Residents Outside the Province of Québec:
          </Heading>
          <Box id="privacy-section" mb={12}>
            {/* Consent to Collection */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Consent to Collection of Personal Data
              </Heading>
              <Text mb={6}>
                Generally speaking, we will obtain your consent prior to any collection, use or
                disclosure of your Personal Data, except where such collection, use or disclosure is
                authorized or required by applicable law.
              </Text>
            </Box>

            {/* Further Processing */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Further Processing
              </Heading>
              <Text mb={6}>
                We will only use your Personal Data for the purposes for which we collect it (as
                outlined above), unless we reasonably consider that we need to use it for another
                reason and that reason is compatible with the original purpose, clearly to the
                benefit of the person concerned, or is otherwise authorized or required by
                applicable law. If we need to use your Personal Data for any other reasonable
                purposes in connection with our engagement with you, the purpose and legal basis (if
                applicable) for any further processing will be made known to you in advance (except
                in situations where we are authorized by law not to do so). We may ask you to
                provide additional consent to such processing, if and to the extent required by
                applicable law.
              </Text>
              <Text mb={6}>
                If you provide your social network account credentials to us or otherwise sign-in to
                the Services through a third-party site or service, some content and/or information
                in those accounts may be transmitted into your account with us. This will be
                specifically addressed in the relevant app privacy notice or software-specific
                privacy notice to which you are required to give consent before installing same.
              </Text>
            </Box>

            {/* Sharing Data */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Sharing Your Personal Data with Other Entities
              </Heading>
              <Text mb={6}>
                We will share Personal Data with our service providers or any other entities acting
                as Keywords’ agents or data processors for the purposes of providing services to
                Keywords and on the understanding that Personal Data will only be processed in line
                with our instructions and in compliance with all applicable legal obligations.
                Generally, we may share Personal Data where this sharing is authorized or required
                by law (including by a court or administrative body having order-making or relevant
                regulatory power).
              </Text>
              <Text mb={6}>
                All of your Personal Data that we collect may be transferred to another entity if we
                undergo a merger, acquisition, bankruptcy or other transaction (or proposed
                transaction) in which that third party assumes control of our business (in whole or
                in part), to the extent necessary to give effect to such transaction. In such
                instances we will take steps to ensure that the other entity has agreed in writing
                to protect your Personal Data, and that you are notified in writing of the transfer
                where possible (if the transaction completes).
              </Text>
            </Box>

            {/* Data Transfer */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Data Transfer
              </Heading>
              <Text mb={6}>
                Generally, transfers of Personal Data outside Canada (e.g. for processing by a
                service provider) will not occur unless and until a due diligence review has been
                undertaken by us and appropriate safeguards have been put in place to protect the
                Personal Data.
              </Text>
            </Box>

            {/* Data Retention */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Data Retention
              </Heading>
              <Text mb={6}>
                We retain Personal Data about you for as long as necessary to provide you with our
                Services. In some cases, we retain Personal Data for longer, if doing so is
                necessary to comply with our legal obligations, protect our legal position, resolve
                disputes or collect fees owed, or is otherwise authorized or required by applicable
                law, rule or regulation. In general, Keywords (or its service providers on its
                behalf), will retain this Personal Data for a period of{' '}
                <Text as="span" fontWeight="bold">
                  seven years
                </Text>{' '}
                after you cease to interact with us, unless it is obliged to hold it for a longer
                period under applicable law or regulatory requirements. To the extent permitted by
                applicable law, we may further retain information in an anonymous or aggregated form
                where that information would not identify you personally.
              </Text>
            </Box>

            {/* Your Rights */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Your Rights
              </Heading>
              <Text mb={6}>
                You have certain rights with respect to your Personal Data, including those set
                forth below.
              </Text>

              <UnorderedList mb={6} spacing={2}>
                <ListItem>
                  You have the right to request a copy of your Personal Data from Keywords.
                </ListItem>
                <ListItem>
                  If you believe that any Personal Data we are holding about you is incorrect or
                  incomplete, you have the right to request correction of any inaccuracies and, in
                  certain circumstances, to request erasure of the Personal Data
                </ListItem>
              </UnorderedList>

              <Text mb={6}>
                <Text as="span" fontWeight="bold">
                  Note:
                </Text>{' '}
                Any such access/correction/erasure requests can be made to Keywords’ Canadian Data
                Protection Officer at{' '}
                <Link href="mailto:privacy@helpshift.com" color="blue.500">
                  privacy@helpshift.com
                </Link>
                .
              </Text>
            </Box>
          </Box>
        </Box>

        {/* Section 13 - California */}
        <Box id="section-13" mb={12}>
          <Heading size="xl" mb={6}>
            13. California Privacy Notice
          </Heading>
          <Box id="ca-privacy-section" mb={12}>
            <Heading size="md" mb={6}>
              California Privacy Notice
            </Heading>

            <Text mb={6}>
              If you are based in California this section forms part of the Privacy Notice and
              should be read together with the other sections of the Privacy Notice. If there is any
              conflict between this section and the remainder of the Privacy Notice, this section
              shall prevail.
            </Text>

            <Text mb={6}>
              This California specific part (“CA Notice”) supplements the information contained in
              our Privacy Notice above and applies to visitors and users of the Website, along with
              those obtaining Services from Keywords, and others who are residents of certain states
              with generally applicable data privacy laws (referred to here as “consumers” or
              “you”). Consumers in these states have certain privacy rights as specified under state
              law, and this CA Notice ensures we cover state-specific requirements. Any terms
              defined in the applicable state privacy laws that we use here have the same meaning
              when used in this CA Notice. Personal Data, as used in this CA Notice, also includes
              “personal information,” as that term is defined under applicable state privacy laws.
            </Text>

            {/* Information We Collect */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Information We Collect
              </Heading>
              <Text mb={6}>
                As more fully described above under the How We Use Your Personal Data section above,
                we collect (and have collected within the last twelve (12) months) the following
                categories of Personal Data:
              </Text>
              <UnorderedList mb={6} spacing={2}>
                <ListItem>
                  Identifiers, including name, alias, email, phone number, address, unique personal
                  identifier, online identifier (including social network usernames), IP address, or
                  other similar identifiers.
                </ListItem>
                <ListItem>
                  Customer records, including contact information, customer numbers, professional
                  information, and customer business card numbers.
                </ListItem>
                <ListItem>
                  Internet or other electronic network activity, including browsing history,
                  clickstream data, search history, and information regarding interactions with our
                  Services, interactions with our advertisements or emails, and other usage data
                  related to your use of any of our Services.
                </ListItem>
                <ListItem>
                  Geolocation data, including general location information about a particular
                  individual or device.
                </ListItem>
                <ListItem>
                  Audio, electronic, visual, or similar information, including information collected
                  via call recordings if you call our customer service department or if you
                  otherwise call us on a recorded line.
                </ListItem>
                <ListItem>
                  Professional Information, including job title, company name, business email,
                  business phone number, and other similar professional and employment-related
                  information.
                </ListItem>
                <ListItem>
                  Inferences, including inferences drawn from any of the information described in
                  this section about a consumer (e.g., reflecting the consumer’s preferences,
                  characteristics, and behaviours).
                </ListItem>
              </UnorderedList>
              <Text mb={6}>For purposes of this CA Notice, Personal Data may not include:</Text>
              <UnorderedList mb={6} spacing={2}>
                <ListItem>
                  Publicly available information lawfully made available from government records, or
                  information that we have a reasonable basis to believe was made publicly available
                  by you and not restricted to a specific audience.
                </ListItem>
                <ListItem>
                  Information otherwise excluded from the scope of various state privacy laws.
                </ListItem>
              </UnorderedList>
            </Box>

            {/* Disclosure Section */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Disclosure of Personal Data to Third Parties and Other Recipients
              </Heading>
              <Text mb={6}>
                The categories of Personal Data we may have disclosed for a business purpose in the
                preceding 12 months include:
              </Text>
              <UnorderedList mb={6} spacing={2}>
                <ListItem>Identifiers;</ListItem>
                <ListItem>Customer records;</ListItem>
                <ListItem>Internet or other network activity information;</ListItem>
                <ListItem>Geolocation data;</ListItem>
                <ListItem>Audio, electronic, visual, or similar information;</ListItem>
                <ListItem>Professional information; and</ListItem>
                <ListItem>Inferences; and</ListItem>
                <ListItem>Protected classifications.</ListItem>
              </UnorderedList>
              <Text mb={6}>
                The categories of third parties and other recipients to whom we may have disclosed
                Personal Data for a business purpose may include:
              </Text>
              <UnorderedList mb={6} spacing={2}>
                <ListItem>Affiliates and subsidiaries;</ListItem>
                <ListItem>Vendors and service providers;</ListItem>
                <ListItem>Data analytics and marketing providers;</ListItem>
                <ListItem>Internet service providers; and</ListItem>
                <ListItem>Operating systems and platforms.</ListItem>
              </UnorderedList>
            </Box>

            {/* Sources Section */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Sources of Personal Data
              </Heading>
              <Text mb={6}>
                We generally collect Personal Data from the following categories of sources:
              </Text>
              <UnorderedList mb={6} spacing={2}>
                <ListItem>Directly or indirectly from you;</ListItem>
                <ListItem>Affiliates and subsidiaries;</ListItem>
                <ListItem>Business customers and clients;</ListItem>
                <ListItem>Internet service providers; and</ListItem>
                <ListItem>Vendors and service providers.</ListItem>
              </UnorderedList>
            </Box>

            {/* Purposes Section */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Purposes of Collecting and Disclosing Personal Data
              </Heading>
              <Text mb={6}>
                As more fully described in the How We Use Personal Data section above, in general,
                we collect and otherwise process Personal Data for the following business or
                commercial purposes:
              </Text>
              <UnorderedList mb={6} spacing={2}>
                <ListItem>Providing, supporting, and improving our Services;</ListItem>
                <ListItem>Conducting analytics;</ListItem>
                <ListItem>Product development;</ListItem>
                <ListItem>Opening and managing user accounts;</ListItem>
                <ListItem>Communicating with you;</ListItem>
                <ListItem>Marketing and promotions;</ListItem>
                <ListItem>Research and surveys;</ListItem>
                <ListItem>Planning and managing events;</ListItem>
                <ListItem>
                  Security, fraud detection, and protection of our and others rights;
                </ListItem>
                <ListItem>Compliance and legal process;</ListItem>
                <ListItem>Auditing, reporting, and other internal operations; and</ListItem>
                <ListItem>
                  General business and operational support, including engaging in business sales or
                  acquisitions.
                </ListItem>
              </UnorderedList>
            </Box>

            {/* Sensitive Data Section */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Sensitive Personal Data
              </Heading>
              <Text mb={6}>
                We do not collect, use, or disclose sensitive personal data beyond the purposes
                authorized by applicable state privacy laws.
              </Text>
            </Box>

            {/* Sales and Sharing Section */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Sales and Sharing of Personal Data
              </Heading>
              <Text mb={6}>
                The CCPA defines “sale” as disclosing or making available Personal Data to a
                third-party in exchange for monetary or other valuable consideration, and “sharing”
                as disclosing or making available Personal Data to a third-party for purposes of
                cross-contextual behavioural advertising.
              </Text>
              <Text mb={6}>
                While we do not disclose Personal Data to third parties in exchange for monetary
                compensation, we may “sell” or “share” the following categories of Personal Data:
                identifiers and Internet or other electronic network activity information. We may
                disclose these categories to third-party advertising networks, analytics providers
                and social networks for purposes of marketing and advertising. We do not sell or
                share Personal Data about individuals we know are under age sixteen (16).
              </Text>
            </Box>

            {/* Rights Section */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Your Privacy Rights and Choices
              </Heading>
              <Text mb={6}>
                California residents may have the following rights regarding their Personal Data,
                subject to certain exceptions and qualifications:
              </Text>
              <UnorderedList mb={6} spacing={2}>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    Access and Data Portability:
                  </Text>{' '}
                  You may have the right to request details about the Personal Data we have
                  collected about you, such as whether we have collected Personal Data about you,
                  the categories of sources, the purposes for which we have collected the Personal
                  Data, the categories of recipients and the specific pieces of Personal Data we
                  have collected. You may also have the right to receive a copy of your Personal
                  Data in a readily usable format.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    Correction:
                  </Text>{' '}
                  You may have the right to request we correct incorrect or inaccurate Personal
                  Data, subject to restrictions regarding the determination of accuracy of the
                  existing Personal Data.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    Deletion:
                  </Text>{' '}
                  You may have the right to request deletion of your data unless an exception
                  applies. Examples of exceptions include when we need to keep data to continue
                  providing the Services, meet legal obligations, detect fraud and investigate
                  violations of our Terms of Use or to address security issues.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    Opt-Out of Sale, Sharing, or Targeted Advertising:
                  </Text>{' '}
                  You have the right to opt-out of “sales” and “sharing” of your Personal Data and
                  to opt-out of the use of your Personal Data for “targeted advertising” as those
                  terms are defined under the CCPA. You have the right and ability to opt-out of
                  “sales” and “sharing” of your Personal Data using an opt-out preference signal. If
                  our Website detects that your browser or device is transmitting an opt-out
                  preference signal, such as the “global privacy control”—or GPC— signal, we will
                  opt that browser or device out of the use of cookies or other tools on our site
                  that result in a “sale” or “sharing” of your Personal Data. If you come to our
                  site from a different device or from a different browser on the same device, or if
                  you clear your cookies, you will need to opt-out again, or use an opt-out
                  preference signal, for that browser and/or device as well.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    Limit Use and Disclosure:
                  </Text>{' '}
                  We do not engage in uses of sensitive Personal Data that would trigger a right to
                  limit use and disclosure of sensitive personal information under applicable state
                  privacy law.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    Non-Discrimination:
                  </Text>{' '}
                  We will not discriminate against you in terms of price or service level for
                  exercising any of the rights described in this section.
                </ListItem>
              </UnorderedList>
            </Box>

            {/* Exercising Rights Section */}
            <Box mb={12}>
              <Heading size="md" mb={6}>
                Exercising Your Privacy Rights
              </Heading>
              <Text mb={6}>
                To exercise your privacy rights as a California resident, please send us a request
                by emailing{' '}
                <Link href="mailto:privacy@helpshift.com" color="blue.500">
                  privacy@helpshift.com
                </Link>
                . You can also contact us at{' '}
                <Link href="tel:+18187298508" color="blue.500">
                  +1 818 729-8508
                </Link>
                .
              </Text>
              <Text mb={6}>
                You may designate someone as an authorized agent to submit requests and act on your
                behalf. For security purposes, authorized agents will be required to provide proof
                of their authorization in their first communication with us, and we may also require
                that the relevant consumer directly verify their identity and the authority of the
                authorized agent.
              </Text>
              <Text mb={6}>
                Unless otherwise permitted by the CCPA, you may only make a request for access or
                data portability twice within a 12-month period. The request must:
              </Text>
              <UnorderedList mb={6} spacing={2}>
                <ListItem>
                  Provide sufficient information that allows us to reasonably verify you are the
                  person about whom we collected Personal Data or an authorized representative.
                </ListItem>
                <ListItem>
                  Describe your request with sufficient detail that allows us to properly
                  understand, evaluate, and respond to it.
                </ListItem>
              </UnorderedList>
              <Text mb={6}>
                We cannot respond to your request or provide you with Personal Data if we cannot
                verify your identity or authority to make the request and confirm the Personal Data
                relates to you. Therefore, we will need your first and last name, email address,
                and, if applicable, customer number. In some cases, we may request additional
                information to verify your identity, or where necessary to process your request. If
                we are unable to verify your identity after a good faith attempt, we may deny the
                request and, if so, will explain the basis for the denial.
              </Text>
            </Box>
          </Box>
        </Box>

        {/* Section 14 - Other U.S. State Privacy Rights */}
        <Box id="section-14" mb={12}>
          <Heading size="xl" mb={6}>
            14. California Privacy Notice
          </Heading>
          <Box id="state-privacy-section" mb={12}>
            <Text mb={6}>
              If you are based in Virginia, Connecticut, Colorado or Utah, this section forms part
              of the Privacy Notice and should be read together with the other sections of the
              Privacy Notice. If there is any conflict between this section and the remainder of the
              Privacy Notice, this section shall prevail.
            </Text>

            <Text mb={6}>
              Residents of Virginia (as of January 1, 2023), Connecticut and Colorado (as of July 1,
              2023), and Utah (as of December 31, 2023) who are users of the Website or recipients
              of Services and who are not acting in a commercial or employment context in relation
              to Keywords or its business customers may be entitled to exercise the following
              rights:
            </Text>

            <UnorderedList mb={6} spacing={2}>
              <ListItem>
                <Text as="span" fontWeight="bold">
                  Access and Data Portability:
                </Text>{' '}
                You may have the right to request details about the Personal Data we have collected
                about you. You may also have the right to receive a copy of your Personal Data in a
                readily usable format.
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="bold">
                  Correction:
                </Text>{' '}
                You may have the right to request we correct incorrect or inaccurate Personal Data,
                subject to restrictions regarding the determination of accuracy of the existing
                Personal Data.
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="bold">
                  Deletion.
                </Text>{' '}
                You may have the right to request deletion of your data unless an exception applies.
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="bold">
                  Opt-Out of Sale, Sharing, or Targeted Advertising.
                </Text>
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="bold">
                  Opt-Out of Profiling with Legal or Significant Effects:
                </Text>{' '}
                You may have the right to opt out of profiling in furtherance of decisions that
                produce legal or similarly significant effects. However, we do not engage in such
                activities, so there is no need to exercise this right.
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="bold">
                  Right to Appeal (VA, CT, and CO only):
                </Text>{' '}
                If we deny or reject your privacy rights request, you may have a right to appeal our
                decision if permitted by your state’s privacy law. To appeal our denial or
                rejection, please contact us at{' '}
                <Link href="mailto:privacy@helpshift.com" color="blue.500">
                  privacy@helpshift.com
                </Link>{' '}
                and include in the subject line “Request to Appeal Privacy Right Request Decision.”
                <Text mt={2}>
                  Please provide us sufficient information to locate your privacy right request
                  record, such as any unique number you were provided. Our privacy office will
                  review your appeal request, our original determination, and will inform you of our
                  decision on your appeal.
                </Text>
              </ListItem>
            </UnorderedList>
          </Box>
        </Box>

        {/* Section 15 - Changes to this Privacy Notice */}
        <Box id="section-15" mb={12}>
          <Heading size="xl" mb={6}>
            15. Changes to this Privacy Notice
          </Heading>
          <Text>
            Overall, we’re constantly trying to improve our Services which includes optimising our
            Website, so we may need to change this Privacy Notice from time to time to reflect
            changes in technology, law, business operations or any other reason we determine is
            necessary or appropriate, but we will alert you to any material changes by placing a
            notice on the Website, by sending you an email and/or by some other means.
          </Text>
        </Box>

        {/* Section 16 - Contact */}
        <Box id="section-16">
          <Heading size="xl" mb={6}>
            16. Contact Information
          </Heading>
          <Box id="contact-section" mb={12}>
            <Text mb={6}>
              If you have any questions or queries about this Privacy Notice, the ways in which we
              collect and use your Personal Data or your choices and rights regarding such
              collection and use, please do not hesitate to contact us at:
            </Text>

            <UnorderedList spacing={2} mb={6}>
              <ListItem>
                <Text as="span" fontWeight="medium">
                  Phone:
                </Text>{' '}
                <Link href="tel:+35319022730" color="blue.500">
                  +353 1 902 2730
                </Link>
                ;
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="medium">
                  Email:
                </Text>{' '}
                <Link href="mailto:privacy@keywordsstudios.com" color="blue.500">
                  privacy@keywordsstudios.com
                </Link>
                ; <Text as="span">or</Text>{' '}
                <Link href="mailto:privacy@helpshift.com" color="blue.500">
                  privacy@helpshift.com
                </Link>
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="medium">
                  Address:
                </Text>{' '}
                South County Business Park, Leopardstown, Dublin 18, D18 T9P8, Ireland.
              </ListItem>
            </UnorderedList>
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
};
