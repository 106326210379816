import React from 'react';
import { Box, Table, Thead, Tbody, Tr, ResponsiveValue, Td } from '@chakra-ui/react';
import { CustomTableHeading } from '../atoms/CustomTableHeading';
import { CustomTableRow } from '../atoms/CustomTableRow';

export interface CustomTableProps {
  fields: string[]; // The field names to be displayed as headings
  data: object[];
  fieldsWithIcons?: string[];
  fieldsAsLinks?: string[];
  centerAlignFields?: string[];
  mt?: ResponsiveValue<string>;
  height?: ResponsiveValue<string>;
  rowBorderColor?: string;
  rowHoverBgColor?: string;
  tableRowTextColor?: string;
  tableHeadingTextColor?: string;
  onTicketClick?: (issueId: number) => void;
  emptyDataHelperText: string;
}
const handleTicketClick = (
  item: Record<string, any>,
  onTicketClick?: (ticketNumber: any) => void,
) => {
  if (!onTicketClick) return;

  const ticketNumber = Object.entries(item).find(([key]) => key.toLowerCase() === 'ticket #')?.[1];

  if (ticketNumber) {
    onTicketClick(ticketNumber);
  }
};
export const CustomTable: React.FC<CustomTableProps> = ({
  fields,
  data,
  fieldsWithIcons,
  centerAlignFields,
  fieldsAsLinks,
  mt,
  height,
  rowBorderColor = 'gray',
  rowHoverBgColor = 'gray.200',
  tableRowTextColor = 'black',
  tableHeadingTextColor = 'black',
  onTicketClick,
  emptyDataHelperText,
}) => {
  return (
    <Box overflowX="auto" maxHeight={height} mt={mt} overflowY="auto">
      <Table width="100%" fontSize={{ base: 'sm', md: 'md', lg: 'md' }}>
        {/* Table Headings */}
        <Thead>
          <Tr position="sticky" top={0} zIndex={999}>
            {fields.map(field => (
              <CustomTableHeading
                key={field}
                field={field}
                fieldsWithIcons={fieldsWithIcons}
                centerAlignFields={centerAlignFields}
                tableHeadingTextColor={tableHeadingTextColor}
              />
            ))}
          </Tr>
        </Thead>
        {/* Table Rows */}
        <Tbody>
          {data.length > 0 ? (
            data.map(item => (
              <CustomTableRow
                key={JSON.stringify(item)}
                fields={fields}
                item={item}
                fieldsAsLinks={fieldsAsLinks}
                rowBorderColor={rowBorderColor}
                rowHoverBgColor={rowHoverBgColor}
                tableRowTextColor={tableRowTextColor}
                onTicketClick={() => handleTicketClick(item, onTicketClick)}
              />
            ))
          ) : (
            <Tr>
              <Td colSpan={fields.length} textAlign="center" padding="1rem">
                {emptyDataHelperText || 'Loading...'}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
