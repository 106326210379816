import { Text, Heading, ResponsiveValue } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

// Define the types for the CustomText component's props
export interface CustomTextProps {
  variant?: 'body' | 'h1' | 'h2' | 'h3'; // Specifies the variant of the text (body or heading)
  children: ReactNode; // Children content for the component
  fontSize?: ResponsiveValue<string>; // Custom font size for flexibility
  fontWeight?: ResponsiveValue<string>; // Custom font weight for flexibility
  color?: string; // Text color
  textAlign?: 'left' | 'right' | 'center' | 'justify'; // Text alignment
  marginStart?: ResponsiveValue<string>; // Left margin (directional)
  width?: ResponsiveValue<string>; // Width of the text container
  overflow?: string; // Overflow behavior (e.g., 'hidden', 'ellipsis')
  padding?: string; // Padding for the text container
  marginBottom?: string; // Bottom margin
  lineHeight?: ResponsiveValue<string>; // Line height for better spacing
  letterSpacing?: ResponsiveValue<string>; // Letter spacing for styling
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line'; // Control text wrapping
  mb?: ResponsiveValue<string>;
  mt?: ResponsiveValue<string>;
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky'; // Position property for the text
  left?: string;
  bottom?: string;
  p?: ResponsiveValue<string>;
  px?: ResponsiveValue<string>;
  py?: ResponsiveValue<string>;
  onClick?: () => void;
}

const variantStyles = {
  body: { fontSize: 'md', fontWeight: 'normal' },
  h1: { fontSize: '2xl', fontWeight: 'bold' },
  h2: { fontSize: 'xl', fontWeight: 'semibold' },
  h3: { fontSize: 'lg', fontWeight: 'semibold' },
};

// Main CustomText component
export const CustomText: React.FC<CustomTextProps> = ({
  variant = 'body',
  children,
  fontSize,
  fontWeight,
  color,
  textAlign = 'left',
  marginStart,
  padding,
  width,
  overflow,
  marginBottom,
  lineHeight,
  letterSpacing,
  whiteSpace,
  mb,
  mt,
  position,
  left,
  bottom,
  p,
  px,
  py,
  onClick,
}) => {
  // Retrieve the default styles based on the variant
  const { fontSize: defaultFontSize, fontWeight: defaultFontWeight } = variantStyles[variant];

  // Handle rendering of text depending on the variant
  if (variant === 'h1') {
    return (
      <Heading
        as="h1"
        fontSize={fontSize || defaultFontSize}
        fontWeight={fontWeight || defaultFontWeight}
        color={color}
        textAlign={textAlign}
        marginStart={marginStart}
        padding={padding}
        marginBottom={marginBottom}
        width={width}
        overflow={overflow}
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
        onClick={onClick}
      >
        {children}
      </Heading>
    );
  }

  if (variant === 'h2') {
    return (
      <Heading
        as="h2"
        fontSize={fontSize || defaultFontSize}
        fontWeight={fontWeight || defaultFontWeight}
        color={color}
        textAlign={textAlign}
        marginStart={marginStart}
        padding={padding}
        marginBottom={marginBottom}
        width={width}
        overflow={overflow}
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
        onClick={onClick}
      >
        {children}
      </Heading>
    );
  }

  if (variant === 'h3') {
    return (
      <Heading
        as="h3"
        fontSize={fontSize || defaultFontSize}
        fontWeight={fontWeight || defaultFontWeight}
        color={color}
        textAlign={textAlign}
        marginStart={marginStart}
        padding={padding}
        marginBottom={marginBottom}
        width={width}
        overflow={overflow}
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
        onClick={onClick}
      >
        {children}
      </Heading>
    );
  }

  // Default case for 'body' text
  return (
    <Text
      fontSize={fontSize || defaultFontSize}
      fontWeight={fontWeight || defaultFontWeight}
      color={color}
      textAlign={textAlign}
      marginStart={marginStart}
      padding={padding}
      marginBottom={marginBottom}
      width={width}
      overflow={overflow}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      whiteSpace={whiteSpace}
      mb={mb}
      mt={mt}
      position={position}
      left={left}
      bottom={bottom}
      p={p}
      px={px}
      py={py}
      onClick={onClick}
    >
      {children}
    </Text>
  );
};
