// ArticleFeedback.tsx
import { HStack } from '@chakra-ui/react';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { CustomButton, CustomText } from '../../atoms';
import { appInsights } from '../../../appInsights';

const onClickArticleFeedback = (
  id: string,
  publishId: string,
  title: string,
  feedback: boolean,
) => {
  // eslint-disable-next-line
  console.log('articlefeedback', feedback, title, id, publishId);
  appInsights.trackEvent({
    name: 'landingPage',
    properties: {
      buttonName: 'relatedArticleTileFeedback',
      userName: 'anonymous',
      articleId: id,
      articlePublishId: publishId,
      articleTitle: title,
      feedback,
    },
  });
};

interface ArticleFeedbackProps {
  id: string;
  publishId: string;
  title: string;
  feedbackText: string;
}

export const ArticleFeedback: React.FC<ArticleFeedbackProps> = ({
  id,
  publishId,
  title,
  feedbackText,
}) => {
  return (
    <HStack spacing={6} borderTop="1px solid gray" pt="4" mt="10">
      <CustomText variant="h3" fontWeight="bold">
        {feedbackText}
      </CustomText>
      <CustomButton
        onClick={() => {
          // console.log('Thumbs up clicked');
          onClickArticleFeedback(id, publishId, title, true);
        }}
        colorScheme="green"
        icon={<FaThumbsUp />}
        variant="outline"
      />
      <CustomButton
        onClick={() => {
          // console.log('Thumbs down clicked');
          onClickArticleFeedback(id, publishId, title, false);
        }}
        colorScheme="red"
        icon={<FaThumbsDown />}
        variant="outline"
      />
    </HStack>
  );
};
