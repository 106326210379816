import { Box, HStack, VStack } from '@chakra-ui/react';
import React from 'react';
import parse from 'html-react-parser';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { CustomButton, CustomText } from '../atoms';
import { CustomHeading } from '../atoms/CustomHeading';
import { Settings } from '../../interfaces';

type ChatMessage = {
  sender: string;
  timestamp: string;
  message: string;
  origin: string;
};

export interface TicketData {
  ticketId: string;
  status: 'Open' | 'Resolved' | 'In Progress';
  messages: ChatMessage[];
}

interface TicketViewProps {
  showHelperButton?: boolean;
  ticketData: TicketData;
  settings: Settings | null;
}

export const TicketView: React.FC<TicketViewProps> = ({
  ticketData,
  settings,
  showHelperButton = true,
}) => {
  const navigate = useNavigate();
  const label = `Ticket #${ticketData.ticketId}`;
  const description = `Status - ${ticketData.status}`;
  const handleBackClick = () => {
    navigate('/playerhub');
  };

  return (
    <Box padding="2rem" maxWidth="1400px" margin="0 auto">
      <HStack spacing={6} align="center">
        {showHelperButton && (
          <CustomButton
            onClick={handleBackClick}
            leftIcon={<ArrowBackIcon />}
            size="md"
            colorScheme="gray"
            variant="solid"
            fontSize="md"
            borderRadius="md"
            padding="8px 16px"
            fontWeight="bold"
            marginLeft="-7rem"
          >
            Back
          </CustomButton>
        )}
        <CustomHeading
          question={label}
          description={description}
          fontSize="4xl"
          descriptionFontSize="md"
          fontWeight="400"
          marginTop="1rem"
          color={settings?.configs.secondaryTextColor}
          descriptionColor={settings?.configs.primaryTextColor}
        />
      </HStack>
      {/* Messages Section */}
      <VStack align="start" spacing={6} mt={4} w="full">
        {ticketData.messages.map(msg => {
          const isBot = msg.origin === 'helpshift';
          return (
            <Box
              w="80%"
              p={4}
              bg={
                isBot ? settings?.configs.helpshiftChatBgColor : settings?.configs.userChatBgColor
              }
              borderRadius="md"
              shadow="sm"
              alignSelf={isBot ? 'flex-end' : 'flex-start'}
              textAlign={isBot ? 'right' : 'left'}
              marginLeft={isBot ? 'auto' : '0'}
              marginRight={isBot ? '0' : 'auto'}
            >
              <HStack justifyContent="space-between" w="full" mb={2}>
                <CustomText
                  variant="h3"
                  fontWeight="bold"
                  color={
                    isBot
                      ? settings?.configs.helpshiftChatTextColor
                      : settings?.configs.userChatTextColor
                  }
                  marginBottom="2"
                >
                  {msg.sender}
                </CustomText>
                <CustomText
                  variant="body"
                  fontSize="sm"
                  color={
                    isBot
                      ? settings?.configs.helpshiftChatTextColor
                      : settings?.configs.userChatTextColor
                  }
                  marginBottom="2"
                >
                  {new Date(msg.timestamp).toLocaleString()}
                </CustomText>
              </HStack>
              {/* <Box borderBottom="1px solid blue" mb={3}  /> */}
              <Box
                borderBottom={`1px solid ${
                  isBot
                    ? settings?.configs.helpshiftChatTextColor
                    : settings?.configs.userChatTextColor
                }`}
                mb={3}
              />

              {/* Message Content */}
              <CustomText
                variant="body"
                fontSize="sm"
                color={
                  isBot
                    ? settings?.configs.helpshiftChatTextColor
                    : settings?.configs.userChatTextColor
                }
                mt="0"
              >
                {parse(msg.message)}
              </CustomText>
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
};
