import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import crypto from 'crypto-js';
import FormData from 'form-data';
import { Box, Container, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { CustomButton } from '../atoms';

export const ForgotPassword: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const userEmail = document.getElementById('email') as HTMLInputElement | null;
    const email = userEmail ? userEmail.value : '';

    function encrypt(text: string, key: string, iv: string) {
      const keyHex = crypto.enc.Hex.parse(key);
      const ivHex = crypto.enc.Hex.parse(iv);
      const encrypted = crypto.AES.encrypt(crypto.enc.Utf8.parse(text), keyHex, {
        iv: ivHex,
        mode: crypto.mode.CBC,
        padding: crypto.pad.Pkcs7,
      });
      return encrypted.ciphertext.toString(crypto.enc.Hex);
    }

    const getUrl =
      'https://ps-scopely-catalyst-func-auth-00-staging.azurewebsites.net/api/getSessionId?';
    const getConfig = {
      method: 'get',
      maxBodyLength: Infinity,
      url: getUrl,
      headers: {},
    };

    try {
      const { data: getResult } = await axios.request(getConfig);
      const { sessionId, iv } = getResult;
      const formdata = new FormData();
      formdata.append('username', email);
      formdata.append('action', 'forgotPassword');

      const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY || '';
      const authString = process.env.REACT_APP_AUTHENTICATION_STRING || '';

      const encryptedAuthToken = encrypt(authString, encryptionKey, iv);

      const postUrl =
        'https://ps-scopely-catalyst-func-auth-00-staging.azurewebsites.net/api/authentication?';
      const postConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: postUrl,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `${encryptedAuthToken}_${sessionId}`,
        },
        data: formdata,
      };

      const { data: result } = await axios.request(postConfig);

      if (result.emailSent) {
        setErrorMessage('');
        setSuccessMessage('A password reset link has been sent to your email!');
        setTimeout(() => navigate('/admin/login'), 5000);
      } else {
        setErrorMessage('Please enter a registered email address.');
      }
    } catch (err) {
      setErrorMessage('An error occurred, please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="md" py={8}>
      <Box
        boxShadow="xl"
        borderRadius="md"
        p={6}
        bg="white"
        w="full"
        mt="5rem"
        border="1px"
        borderColor="gray.100"
      >
        <VStack spacing={4}>
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">
            Forgot Password
          </Text>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <FormControl isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                type="email"
                id="email"
                required
                placeholder="Enter your Registered Email Id"
                isDisabled={isLoading}
                onFocus={() => setErrorMessage('')}
              />
            </FormControl>
            <CustomButton
              type="submit"
              colorScheme="blue"
              w="full"
              mt={4}
              isLoading={isLoading}
              loadingText="Generating Link..."
            >
              Reset Password
            </CustomButton>
            {errorMessage && (
              <Text color="red.500" mt={2}>
                {errorMessage}
              </Text>
            )}
          </form>
          <Link
            to="/login"
            style={{
              textDecoration: 'none',
              fontSize: 'large',
              marginTop: '10px',
              cursor: isLoading ? 'not-allowed' : 'pointer',
              color: isLoading ? '#d3d3d3' : '#007bff',
            }}
          >
            Back to Login
          </Link>
          {successMessage && (
            <Text color="green.500" mt={2}>
              {successMessage}
            </Text>
          )}
        </VStack>
      </Box>
    </Container>
  );
};
