import React from 'react';
import { Box, Text, Link, Stack, Divider, useColorModeValue } from '@chakra-ui/react';
import { CustomImage } from '../atoms';
import { appInsights } from '../../appInsights';

interface FooterProps {
  footerBgColor: string;
  footerTextColor: string;
  fontSize?: string;
  footerNote?: string;
  links?: FooterLink[];
  translations?: TranslationLink[]; // New prop for translations
  imageSrc?: string;
  logoWidth?: { base: string; lg: string };
  logoPadding?: string;
  showFooterLogo: boolean;
  showFooterLinks: boolean;
  gameTitle?: string;
}

export interface FooterLink {
  text: string;
  href: string;
  id: string | number;
  enabled: boolean;
}

export interface TranslationLink {
  text: string;
  id: string | number;
}

export const Footer: React.FC<FooterProps> = ({
  footerBgColor = 'gray.900',
  footerTextColor = 'gray.500',
  fontSize = 'sm',
  footerNote = '© 2024 Netflix',
  imageSrc,
  links = [],
  translations = [], // Default empty array
  logoWidth = { base: '50%', lg: '5%' },
  logoPadding = '4',
  showFooterLogo = true,
  showFooterLinks = true,
  gameTitle,
}) => {
  // Map links with translations based on id
  const translatedLinks = links.map(link => {
    const translation = translations.find(t => t.id === link.id);
    return {
      ...link,
      text: translation ? translation.text : link.text, // Use translated text if available
    };
  });

  return (
    <Box bg={footerBgColor} color={footerTextColor} py="6" textAlign="center">
      {/* Logo Section */}
      {showFooterLogo && imageSrc && (
        <CustomImage src={imageSrc} width={logoWidth} margin="auto" padding={logoPadding} />
      )}

      {/* Footer Note */}
      <Text color={footerTextColor} fontSize={fontSize} mt="2">
        {gameTitle}
      </Text>
      <Text mt="1" color={footerTextColor} fontSize={fontSize}>
        {footerNote}
      </Text>

      {/* Divider */}
      <Divider my="4" borderColor={useColorModeValue('gray.600', 'gray.300')} />

      {/* Links Section */}
      {showFooterLinks && translatedLinks.length > 0 && (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing="4"
          justify="center"
          align="center"
          wrap="wrap"
        >
          {translatedLinks.map(link =>
            link.enabled ? (
              <Link
                key={link.id}
                href={link.href}
                color={footerTextColor}
                fontSize={fontSize}
                _hover={{ color: useColorModeValue('gray.200', 'gray.600') }}
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'footer',
                    properties: {
                      buttonName: 'footerLinkButton',
                      linkId: link.id,
                      linkText: link.text,
                      linkHref: link.href,
                      userName: 'anonymous',
                    },
                  });
                }}
              >
                {link.text}
              </Link>
            ) : null,
          )}
        </Stack>
      )}
    </Box>
  );
};
