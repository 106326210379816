import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import crypto from 'crypto-js';
import { Box, Container, FormControl, FormLabel, Input, Text, VStack } from '@chakra-ui/react';
import { Loader } from '../organisms/Loader';
import { CustomButton } from '../atoms';

export const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loaderError, setLoaderError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const URL =
    'https://ps-scopely-catalyst-func-auth-00-staging.azurewebsites.net/api/passwordReset?';
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');

  useEffect(() => {
    const authenticate = async () => {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL}action=linkValidation&token=${token}`,
        headers: {},
      };
      try {
        const response = await axios.request(config);
        const result = await response.data;
        if (result.isLinkValid) {
          setIsLoading(false);
        } else {
          setLoaderError(
            'Your password reset link has expired. Please generate a new one to reset your password.',
          );
        }
      } catch (authenticateError) {
        // eslint-disable-next-line
        console.error('Authentication failed', authenticateError);
        setLoaderError(
          'Your password reset link has expired. Please generate a new one to reset your password.',
        );
      }
    };
    authenticate();
  }, [navigate, token]);

  const passwordCriteria = (pass: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(pass);
    const hasLowerCase = /[a-z]/.test(pass);
    const hasNumber = /\d/.test(pass);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);

    return pass.length >= minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  };

  const handlePasswordChange = (e: {
    target: { value: any; setCustomValidity: (arg0: string) => void };
  }) => {
    const { value } = e.target;
    setPassword(value);

    if (!passwordCriteria(value)) {
      e.target.setCustomValidity(
        'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.',
      );
    } else {
      e.target.setCustomValidity('');
    }
  };

  function encrypt(text: string, key: string, iv: string) {
    const keyHex = crypto.enc.Hex.parse(key);
    const ivHex = crypto.enc.Hex.parse(iv);
    const encrypted = crypto.AES.encrypt(crypto.enc.Utf8.parse(text), keyHex, {
      iv: ivHex,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString(crypto.enc.Hex);
  }

  const handleResetPassword = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setIsLoading(true);
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
    } else {
      setError('');
      const keyPass = process.env.REACT_APP_ENCRYPTION_KEY || '';
      const ivPass = process.env.REACT_APP_AUTHENTICATION_STRING || '';

      const encryptedPassword = encrypt(password, keyPass, ivPass);

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL}token=${token}&password=${encryptedPassword}&action=resetPassword`,
        headers: {},
      };
      try {
        const response = await axios.request(config);
        const result = await response.data;
        if (result.passwordReset) {
          setSuccessMessage('Password has been reset successfully!');
          setTimeout(() => {
            navigate('/admin/login');
          }, 4000);
        } else {
          setError(result.message);
        }
      } catch (passwordResetError) {
        // eslint-disable-next-line
        console.error('Authentication failed', passwordResetError);
        setError('Authentication failed');
      }
    }
    setIsLoading(false);
  };
  return (
    <div>
      {loaderError && <div className="error-message-rp">{loaderError}</div>}

      {!loaderError && isLoading && <Loader />}

      {!loaderError && !isLoading && (
        <Container maxW="md" py={8}>
          <Box
            boxShadow="xl"
            border="1px"
            borderColor="gray.100"
            borderRadius="md"
            p={6}
            bg="white"
            w="full"
            mt="5rem"
          >
            <VStack spacing={4}>
              <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                Reset Password
              </Text>

              <form onSubmit={handleResetPassword} style={{ width: '100%' }}>
                <FormControl isRequired>
                  <FormLabel htmlFor="password">Enter Password</FormLabel>
                  <Input
                    type="password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    isDisabled={isLoading}
                    onFocus={() => setError('')}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="confirmPassword">Re-enter Password</FormLabel>
                  <Input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    isDisabled={isLoading}
                    onFocus={() => setError('')}
                  />
                </FormControl>
                <CustomButton
                  type="submit"
                  colorScheme="blue"
                  w="full"
                  mt={4}
                  isLoading={isLoading}
                  loadingText="Resetting Password..."
                >
                  Reset Password
                </CustomButton>

                {error && (
                  <Text color="red.500" mt={2}>
                    {error}
                  </Text>
                )}
              </form>
              {successMessage && (
                <div
                  className={`success-message-box ${successMessage ? 'show' : ''}`}
                  style={{ color: 'green' }}
                >
                  {successMessage}
                </div>
              )}
            </VStack>
          </Box>
        </Container>
      )}
    </div>
  );
};
