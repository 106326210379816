import { useEffect, useState, useCallback, useRef } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus, AuthenticationResult } from '@azure/msal-browser';
import { loginRequest } from './authConfig';

interface UserClaims {
  [key: string]: any;
}

export const useAuth = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const user = accounts.length > 0 ? accounts[0] : null;
  const [userClaims, setUserClaims] = useState<UserClaims | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const fetchAccessTokenRef = useRef(false); // Prevents multiple unnecessary calls

  // Function to acquire access token silently
  const fetchAccessToken = useCallback(async (): Promise<string | null> => {
    if (!isAuthenticated || !user) return null;

    try {
      const response: AuthenticationResult = await instance.acquireTokenSilent({
        ...loginRequest,
        account: user,
      });
      setAccessToken(response.accessToken);
      return response.accessToken;
    } catch (error) {
      // eslint-disable-next-line
      console.error('Silent token acquisition failed:', error);
      if (inProgress === InteractionStatus.None) {
        try {
          await instance.loginRedirect(loginRequest);
        } catch (loginError) {
          // eslint-disable-next-line
          console.error('Login redirect failed:', loginError);
        }
      }
      return null;
    }
  }, [isAuthenticated, user, instance, inProgress]);

  useEffect(() => {
    if (!fetchAccessTokenRef.current) {
      fetchAccessTokenRef.current = true;
      fetchAccessToken();
    }
  }, [fetchAccessToken]);

  // Automatically refresh token periodically
  useEffect(() => {
    if (!isAuthenticated) {
      return undefined; // ✅ Explicitly return undefined to satisfy ESLint
    }
    const refreshInterval = setInterval(
      () => {
        (async () => {
          await fetchAccessToken();
        })();
      },
      45 * 60 * 1000,
    );
    return () => clearInterval(refreshInterval);
  }, [isAuthenticated, fetchAccessToken]);

  const handleLogin = async () => {
    if (inProgress !== InteractionStatus.None) return;
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      // eslint-disable-next-line
      console.error('Login failed:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: '/login',
      });
      sessionStorage.clear();
      setUserClaims(null);
      setAccessToken(null);
    } catch (error) {
      // eslint-disable-next-line
      console.error('Logout failed:', error);
    }
  };

  return {
    isAuthenticated,
    user,
    userClaims,
    accessToken,
    setAccessToken,
    handleLogin,
    handleLogout,
  };
};
