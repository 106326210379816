import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import tinycolor from 'tinycolor2';
import { ArticleCard } from '../molecules/ArticleCard';
import { CustomText } from '../atoms/CustomText';

// Define the type for an article
export interface FaqArticle {
  category: string;
  title: string;
  id: string;
}

// Define props interface
export interface PopularArticlesProps {
  faqArticles: FaqArticle[]; // Array of articles
  bgColor?: string; // Optional background color
  faqArticlesTextColor: string;
  faqArticlesBgColor: string;
  secondaryTextColor: string;
  onClickPopularArticle: (id: string | number) => void;
  faqArticleTranslations: FaqArticle[];
  faqArticlesHeading: string;
}

export const PopularArticles: React.FC<PopularArticlesProps> = ({
  faqArticles,
  bgColor,
  faqArticlesTextColor,
  faqArticlesBgColor,
  secondaryTextColor,
  onClickPopularArticle,
  faqArticleTranslations,
  faqArticlesHeading,
}) => {
  const translatedFaqItems = faqArticles.map(item => {
    const itemTranslation = faqArticleTranslations.find(t => t.id === item.id);
    return {
      ...item, // Keep all properties from `items`
      title: itemTranslation?.title ?? item.title, // Override `title` if translation
      category: itemTranslation?.category ?? item.category,
    };
  });
  // Ensure the grid always displays 6 cards
  const displayArticles = translatedFaqItems ? [...translatedFaqItems].slice(0, 6) : null;
  const categoryTextColor = tinycolor(faqArticlesTextColor).lighten(30).toString();

  return (
    <Box py={{ base: '4', md: '10vh' }} bg={bgColor}>
      {/* Heading for Popular Articles */}
      <CustomText
        variant="h1"
        fontSize={{ base: '2xl', md: '3xl' }} // Adjust font size for mobile
        fontWeight="bold"
        mb={{ base: '4', md: '6' }} // Adjust margin bottom for mobile
        color={secondaryTextColor}
      >
        {faqArticlesHeading}
      </CustomText>

      {/* Grid Layout for Article Cards - Responsive Columns */}
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3 }} // 1 column for mobile, 2 for tablets, 3 for desktops
        spacing={{ base: '1', md: '0' }} // Adjust spacing for mobile
        mt="4vh"
      >
        {displayArticles?.map(article => (
          <ArticleCard
            key={article.id} // Unique key for each article
            category={article.category}
            title={article.title}
            categoryTextColor={categoryTextColor}
            faqArticlesBgColor={faqArticlesBgColor}
            faqArticlesTextColor={faqArticlesTextColor}
            id={article.id}
            onClickPopularArticle={onClickPopularArticle}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
