import { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useAuth } from '../stateManagement/auth/useAuth';
import { Loader } from '../components/organisms/Loader';
import { loginRequest } from '../stateManagement/auth/authConfig';

export const isValidToken = (token: string | null): boolean => {
  if (!token) return false; // Token is null or empty

  try {
    const [header, payload, signature] = token.split('.');
    if (!header || !payload || !signature) return false; // Invalid JWT structure

    const decodedPayload = JSON.parse(atob(payload)); // Decode Base64 payload
    const expiryTime = decodedPayload.exp;

    if (!expiryTime) return false; // Token has no expiration claim

    const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
    return expiryTime > currentTime; // Check if token is expired
  } catch (error) {
    // eslint-disable-next-line
    console.error('Error validating token:', error);
    return false; // Invalid token format or error in decoding
  }
};

export const ProtectedRoute = () => {
  const { isAuthenticated, handleLogin, accessToken, setAccessToken } = useAuth();
  const { inProgress, instance } = useMsal();
  const [loading, setLoading] = useState(true);
  const isFetchingToken = useRef(false); // Prevents duplicate token requests
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      if (isFetchingToken.current) return; // Prevent re-entrance

      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        // eslint-disable-next-line
        console.log('User is not authenticated. Initiating login...');
        handleLogin();
        return;
      }

      // eslint-disable-next-line
      console.log('valid Token:', isValidToken(accessToken));
      if (!isValidToken(accessToken)) {
        try {
          const accounts = instance.getAllAccounts();
          if (accounts.length > 0) {
            const tokenResponse = await instance.acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
              scopes: [
                'https://scopelycatalystb2c.onmicrosoft.com/a10360e1-3ff2-4e8b-b0e4-7a7a044cba7d/user_impersonation',
                'offline_access',
                'openid',
              ],
              forceRefresh: true,
            });
            setAccessToken(tokenResponse.accessToken);
            // eslint-disable-next-line
            console.log('access Token', tokenResponse.accessToken);
            // eslint-disable-next-line
            console.log('Token response:', tokenResponse);
          } else {
            // eslint-disable-next-line
            console.warn('No accounts found, forcing login...');
            handleLogin();
          }
        } catch (error) {
          // eslint-disable-next-line
          console.error('Error acquiring token, redirecting to login:', error);
          navigate(`/login?error=${error}`);
        }
      }
      setLoading(false);
    };

    fetchToken();
  }, [isAuthenticated]);

  if (
    loading ||
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect
  ) {
    return <Loader />; // Show loader while processing authentication
  }

  if (!isAuthenticated) {
    return <Loader />; // Show loader while redirecting
  }

  return <Outlet />;
};
