import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { FaComment, FaPlusCircle } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CustomInputEmail } from '../molecules/ContactUsForm/CustomInputEmail';
import { CustomInputTextbox } from '../molecules/ContactUsForm/CustomInputTextbox';
import { CustomInputTel } from '../molecules/ContactUsForm/CustomInputTel';
import { CustomInputDate } from '../molecules/ContactUsForm/CustomInputDate';
import { CustomInputPassword } from '../molecules/ContactUsForm/CustomInputPassword';
import { CustomInputNumber } from '../molecules/ContactUsForm/CustomInputNumber';
import { CustomInputFile } from '../molecules/ContactUsForm/CustomInputFile';
import { CustomInputRadio } from '../molecules/ContactUsForm/CustomInputRadio';
import { CustomCheckbox } from '../atoms/CustomCheckbox';
import { CustomHeading } from '../atoms/CustomHeading';
import { CustomButton } from '../atoms';
import { FormField } from '../../stateManagement/SettingsContext';
import { hmac, initializeHelpshiftChat } from '../../utils';
import { appInsights } from '../../appInsights';
import { useAuth } from '../../stateManagement/auth/useAuth';
import { Loader } from './Loader';
import { CustomTextArea } from '../atoms/ContactUsForm/CustomTextArea';

interface ContactUsProps {
  FormFields: FormField[] | null; // Array of form fields
  createTicketButtonText: string;
  liveChatButtonText: string;
}

const PLATFORM_ID = process.env.REACT_APP_WEB_PLATFORM_ID || '';
const DOMAIN = process.env.REACT_APP_DOMAIN || '';
const LANGUAGE = process.env.REACT_APP_LANGUAGE || '';
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || '';

export const ContactUs: React.FC<ContactUsProps> = ({
  FormFields,
  createTicketButtonText,
  liveChatButtonText,
}) => {
  const [formData, setFormData] = useState<Record<string, any>>({});
  const { user } = useAuth();
  const userId: string = user?.idTokenClaims?.epic_id as string;
  const userEmail = user?.idTokenClaims?.emails?.[0] || '';
  const userName = user?.idTokenClaims?.name || '';
  const [submitted, isSubmitted] = useState(false);
  const [disableUserName, setDisableUserName] = useState(false);
  const [loading, isLoading] = useState<Boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setFormData(prevData => ({
        ...prevData,
        [FormFields?.find(item => item.id === '2')?.question || '']:
          user?.idTokenClaims?.name || '',
        [FormFields?.find(item => item.id === '3')?.question || '']:
          user?.idTokenClaims?.emails?.[0] || '',
      }));
      setDisableUserName(true);
    }
  }, [user, FormFields]);

  const fetchData = async () => {
    isLoading(true);
    const uid = user?.idTokenClaims?.epic_id;
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://catalysthc.ms.helpshift.live/api/issues?end-user-ids=${uid}&platform-types=webchat`,
        headers: {},
      };

      const response = await axios.request(config);
      // Extracting the data array
      const issues = response.data?.data || [];

      const checkStateData = () => {
        return issues.filter((issue: { state_data: { state: any } }) => {
          const state = issue.state_data?.state;
          return state !== 'resolved' && state !== 'rejected';
        }).length;
      };

      // Example usage:
      const activeIssues = checkStateData();
      if (activeIssues !== 0) {
        isSubmitted(true);
        initializeHelpshiftChat(
          PLATFORM_ID,
          DOMAIN,
          LANGUAGE,
          userName,
          userEmail,
          userId,
          navigate,
          'openWidget,showWidget',
          hmac(userId, userEmail, SECRET_KEY),
        );
      } else {
        initializeHelpshiftChat(
          PLATFORM_ID,
          DOMAIN,
          LANGUAGE,
          userName,
          userEmail,
          userId,
          navigate,
          'hideWidget',
          hmac(userId, userEmail, SECRET_KEY),
        );
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('Error fetching data:', error);
    }
    isLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (name: string, value: string) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFileChange = (files: Record<string, File>) => {
    setFormData(prevData => ({
      ...prevData,
      ...files, // Merge the new files into the existing form data
    }));
  };
  const handleNumberChange = (question: string, newValue: number) => {
    setFormData(prevData => ({
      ...prevData,
      [question]: newValue,
    }));
  };
  const handleLiveChatClick = () => {
    initializeHelpshiftChat(
      PLATFORM_ID,
      DOMAIN,
      LANGUAGE,
      userName,
      userEmail,
      userId,
      navigate,
      'showWidget,openWidget',
      hmac(userId, userEmail, SECRET_KEY),
    );
  };

  const headingFields = FormFields?.filter(field => field.type === 'heading') || [];
  const otherFields = FormFields?.filter(field => field.type !== 'heading') || [];

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    isSubmitted(true);
    const userFirstMessage = formData[FormFields?.find(item => item.id === '6')?.question || ''];
    const cifDemo: Record<string, { type: string; value: any }> = {}; // Initialize an empty object
    otherFields.forEach(field => {
      const key = field.linked_cif_key;
      const type = field.linked_cif_type || '';
      const question = FormFields?.find(item => item.id === field.id)?.question || ''; // Get question from FormFields
      let value = formData[question] || ''; // Get value from formData

      if (type === 'dropdown') {
        const optionsArray = field.options?.split(',').map(option => option.trim()) || [];

        const selectedIndex = optionsArray.indexOf(value);

        if (selectedIndex !== -1) {
          const linkedOptionsArray =
            field.linked_cif_options?.split(',').map(option => option.trim()) || [];
          const linkedValue = linkedOptionsArray[selectedIndex] || '';

          value = linkedValue;
        }
      }
      if (key) {
        cifDemo[key] = { type, value };
      }
    });

    const authToken = hmac(userId, userEmail, SECRET_KEY);

    initializeHelpshiftChat(
      PLATFORM_ID,
      DOMAIN,
      LANGUAGE,
      formData[FormFields?.find(item => item.id === '2')?.question || ''],
      userEmail,
      userId,
      navigate,
      'showWidget,openWidget',
      authToken,
      userFirstMessage,
      cifDemo,
    );

    appInsights.trackEvent({
      name: 'contactUsPage',
      properties: {
        buttonName: 'SubmitButton',
        userName: 'anonymous',
      },
    });
    // eslint-disable-next-line
    console.log('done', 'SubmitButton');
  };

  const renderInputField = (field: FormField, index: number) => {
    const { type, question, description, placeholder, required, multiple, options, height, id } =
      field;
    const shouldDisable =
      question ===
        Object.keys(formData).find(
          key => key === FormFields?.find(item => item.id === '2')?.question,
        ) && disableUserName;
    switch (type) {
      case 'heading':
        return (
          <Box key={index} marginBottom="2rem">
            <CustomHeading
              question={question}
              description={description}
              fontSize="4xl"
              descriptionFontSize="md"
              fontWeight="400"
              marginTop="2rem"
            />
          </Box>
        );
      case 'singleline':
        return (
          <CustomInputTextbox
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            marginTop="2rem"
            fontSize="md"
            descriptionFontSize="sm"
            height={height || '50px'}
            onChange={e => handleInputChange(question, e.target.value)}
            value={formData[question] || ''}
            isDisabled={submitted || shouldDisable}
            id={id}
            // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
            // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
          />
          // </div>
        );
      case 'multiline':
        return (
          <CustomTextArea
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            marginTop="2rem"
            fontSize="md"
            descriptionFontSize="sm"
            height={height || '200px'}
            onChange={e => handleInputChange(question, e.target.value)}
            value={formData[question] || ''}
            isDisabled={submitted || shouldDisable}
          />
        );
      case 'password':
        return (
          // <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
          <CustomInputPassword
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            marginTop="2rem"
            // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
            // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
            fontSize="md"
            descriptionFontSize="sm"
            onChange={e => handleInputChange(question, e.target.value)}
            value={formData[question] || ''}
            isDisabled={submitted}
          />
          // </div>
        );
      case 'email':
        return (
          // <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
          <CustomInputEmail
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            marginTop="2rem"
            // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
            // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
            fontSize="md"
            descriptionFontSize="sm"
            onChange={e => handleInputChange(question, e.target.value)}
            value={formData[question] || ''}
            isDisabled={submitted}
          />
          // </div>
        );
      case 'date':
        return (
          <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
            <CustomInputDate
              question={question}
              placeholder={placeholder}
              required={required}
              description={description}
              marginTop="2rem"
              // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
              // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
              fontSize="md"
              descriptionFontSize="sm"
              onChange={e => handleInputChange(question, e.target.value)}
              value={formData[question] || ''}
              isDisabled={submitted}
            />
          </div>
        );
      case 'tel':
        return (
          <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
            <CustomInputTel
              question={question}
              placeholder={placeholder}
              required={required}
              description={description}
              marginTop="2rem"
              // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
              // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
              fontSize="md"
              descriptionFontSize="sm"
              onChange={e => handleInputChange(question, e.target.value)}
              value={formData[question] || ''}
              isDisabled={submitted}
            />
          </div>
        );
      case 'number':
        return (
          <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
            <CustomInputNumber
              question={question}
              placeholder={placeholder}
              required={required}
              description={description}
              marginTop="2rem"
              // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
              // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
              fontSize="md"
              descriptionFontSize="sm"
              isDisabled={submitted}
              onChange={(e: number) => handleNumberChange(question, e)}
              value={formData[question] || ''}
            />
          </div>
        );
      case 'checkbox':
        return (
          <div style={{ flex: '1 1 45%', margin: '0.5rem' }}>
            <CustomCheckbox
              question={question}
              isRequired={required}
              marginTop="2rem"
              // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
              // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
              onChange={e => handleInputChange(question, e.target.value)}
              value={formData[question] || ''}
              isDisabled={submitted}
            />
          </div>
        );
      case 'file':
        return (
          // <div style={{ flex: '1 1 45%', margin: '0.5rem' }}>
          <CustomInputFile
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            multiple={multiple}
            marginTop="2rem"
            // paddingLeft={{ base: '1rem', md: '2rem', lg: '4rem' }}
            // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
            fontSize="md"
            descriptionFontSize="sm"
            onChange={handleFileChange}
            isDisabled={submitted}
          />
          // </div>
        );
      case 'dropdown':
        return (
          // <div style={{ flex: '1 1 45%', margin: '0.5rem' }}>
          <CustomInputRadio
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            options={options}
            marginTop="2rem"
            // paddingLeft={{ base: '1rem', md: '2rem', lg: '4rem' }}
            // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
            fontSize="md"
            descriptionFontSize="sm"
            onChange={e => handleInputChange(question, e.target.value)}
            value={formData[question] || ''}
            isDisabled={submitted}
          />
          // </div>
        );
      default:
        return null;
    }
  };

  const firstColumnFields = otherFields; // Use all fields in one column

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <Box padding="2rem" maxWidth="1200px" margin="0 auto">
          <form onSubmit={handleSubmit}>
            {/* Render headings outside the flex arrangement */}
            {headingFields.map((field, index) => renderInputField(field, index))}

            {/* Single Column Layout */}
            <Box display="flex" flexDirection="column" gap="1rem">
              {firstColumnFields.map((field, index) => (
                <Box marginBottom="1rem">{renderInputField(field, index)}</Box>
              ))}
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" marginTop="2rem">
              <CustomButton
                type="submit"
                variant="solid"
                size="lg"
                colorScheme="blackAlpha"
                textColor="white"
                backgroundColor="black"
                padding="12px 24px"
                icon={<FaPlusCircle />}
                style={{ marginBottom: '16px', width: '450px' }}
                isDisabled={submitted}
              >
                {createTicketButtonText}
              </CustomButton>
              <CustomButton
                onClick={handleLiveChatClick}
                variant="solid"
                size="lg"
                colorScheme="blackAlpha"
                textColor="white"
                backgroundColor="black"
                padding="12px 24px"
                icon={<FaComment />}
                style={{ marginBottom: '16px', width: '450px' }}
                isDisabled={!submitted}
              >
                {liveChatButtonText}
              </CustomButton>
            </Box>
          </form>
        </Box>
      )}
    </Box>
  );
};
