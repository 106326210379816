import { Box, Heading, Text, Button, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" bg="gray.100">
      <VStack spacing={6} textAlign="center" bg="white" p={8} borderRadius="lg" boxShadow="lg">
        <Heading fontSize="6xl" color="red.500">
          404
        </Heading>
        <Text fontSize="xl" color="gray.600">
          {`Oops! The page you're looking for doesn't exist.`}
        </Text>
        <Button as={Link} to="/" colorScheme="blue" size="lg">
          Go Back Home
        </Button>
      </VStack>
    </Box>
  );
};
