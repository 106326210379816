import { Box, HStack, Stack } from '@chakra-ui/react';
import { ColorPickerAtom } from '../../atoms/ColorPickerAtom';

interface ColorSettingsMoleculeProps {
  configs: any;
  onConfigChange: (key: string, value: string) => void;
}

export const ColorSettingsMolecule: React.FC<ColorSettingsMoleculeProps> = ({
  configs,
  onConfigChange,
}) => {
  // Toggle collapse and expand

  return (
    <Box height="80vh" overflowY="auto">
      {/* Section Header */}

      {/* Conditional rendering of menu cards based on expand/collapse state */}

      <Stack spacing={4}>
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ColorPickerAtom
            label="Primary Background Color"
            value={configs.primaryBgColor}
            onChange={color => onConfigChange('primaryBgColor', color)}
          />
        </HStack>

        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ColorPickerAtom
            label="Secondary Background Color"
            value={configs.secondaryBgColor}
            onChange={color => onConfigChange('secondaryBgColor', color)}
          />
        </HStack>
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ColorPickerAtom
            label="Primary Text Color"
            value={configs.primaryTextColor}
            onChange={color => onConfigChange('primaryTextColor', color)}
          />
        </HStack>
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ColorPickerAtom
            label="Secondary Text Color"
            value={configs.secondaryTextColor}
            onChange={color => onConfigChange('secondaryTextColor', color)}
          />
        </HStack>
      </Stack>
    </Box>
  );
};
