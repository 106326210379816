import React, { useState } from 'react';
import { Box, Stack, HStack } from '@chakra-ui/react';
import { FaEdit, FaTrash, FaCheck, FaPlus } from 'react-icons/fa';
import { CustomButton, CustomInput, CustomText } from '../../atoms';
import { NewsArticle } from '../../organisms/NewsSection';
import { ToggleAtom } from '../../atoms/CustomToggle';

interface NewsSectionSettingsProps {
  newsCards: NewsArticle[];
  onNewsArticleChange: (newCards: NewsArticle[]) => void;
  configs: {
    showNewsSection: boolean;
  };
  onConfigChange: (key: string, value: any) => void;
}

export const NewsSectionSettings: React.FC<NewsSectionSettingsProps> = ({
  newsCards,
  onNewsArticleChange,
  configs,
  onConfigChange,
}) => {
  const [editingCardId, setEditingCardId] = useState<string | null>(null);
  const [currentEdit, setCurrentEdit] = useState<NewsArticle | null>(null);
  const [showNewsSection, setShowNewsSection] = useState(configs.showNewsSection);

  // Function to handle editing of a news card
  const handleEditNewsArticle = (card: NewsArticle) => {
    setEditingCardId(card.id);
    setCurrentEdit({ ...card });
  };

  // Function to handle saving the edits
  const handleSaveEdit = () => {
    if (currentEdit && editingCardId) {
      const updatedCards = newsCards.map(card => (card.id === editingCardId ? currentEdit : card));
      onNewsArticleChange(updatedCards);
      setEditingCardId(null);
      setCurrentEdit(null);
    }
  };

  // Function to handle deleting a news card
  const handleDeleteNewsCard = (id: string) => {
    const updatedCards = newsCards.filter(card => card.id !== id);
    onNewsArticleChange(updatedCards);
  };

  // Function to update the title in the input field
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, title: event.target.value });
    }
  };

  // Function to update the image URL in the input field
  const handleImageUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, imageUrl: event.target.value });
    }
  };

  // Function to update the date in the input field
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, date: event.target.value });
    }
  };

  // Function to update the destination link in the input field
  const handleDestinationLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, destinationLink: event.target.value });
    }
  };

  // Function to add a new news card at the top of the list
  const handleAddNewsCard = () => {
    const newCard: NewsArticle = {
      id: Date.now().toString(),
      title: '',
      imageUrl: '',
      date: '',
      destinationLink: '',
    };
    onNewsArticleChange([newCard, ...newsCards]);
    setEditingCardId(newCard.id);
    setCurrentEdit(newCard);
  };

  return (
    <Box height="80vh" overflowY="auto" border="1px solid #e2e8f0" p={4} borderRadius="md">
      <HStack
        p={4}
        borderWidth="1px"
        borderRadius="md"
        mb={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <ToggleAtom
          justifyContent="space-between"
          label="Show News Articles"
          value={showNewsSection}
          onChange={value => {
            setShowNewsSection(value); // Update local state
            onConfigChange('showNewsSection', value); // Notify parent of the change
          }}
        />
      </HStack>
      <CustomButton
        icon={<FaPlus />}
        // aria-label=""
        onClick={handleAddNewsCard}
        colorScheme="green"
      >
        Add News Card
      </CustomButton>

      <Box maxWidth="100%" overflow="hidden">
        <Stack spacing={4}>
          <Box mt={6}>
            {newsCards.map(card => (
              <Box
                key={card.id}
                p={4}
                borderWidth="1px"
                borderRadius="md"
                mb={4}
                display="flex"
                flexDirection="column"
                gap={4} // Adds spacing between elements
              >
                {/* Edit Mode */}
                {editingCardId === card.id ? (
                  <Stack spacing={3} width="100%">
                    <CustomInput
                      value={currentEdit?.title || ''}
                      onChange={handleTitleChange}
                      placeholder="Title"
                      width="100%"
                    />
                    <CustomInput
                      value={currentEdit?.imageUrl || ''}
                      onChange={handleImageUrlChange}
                      placeholder="Image URL"
                      width="100%"
                    />
                    <CustomInput
                      value={currentEdit?.date || ''}
                      onChange={handleDateChange}
                      placeholder="Date"
                      type="date"
                      width="100%"
                    />
                    <CustomInput
                      value={currentEdit?.destinationLink || ''}
                      onChange={handleDestinationLinkChange}
                      placeholder="Destination Link"
                      width="100%"
                    />
                  </Stack>
                ) : (
                  // Display Mode: Stack all text elements with wrapping enabled
                  <Stack spacing={3} width="100%">
                    <CustomText fontWeight="bold" whiteSpace="normal">
                      {card.title}
                    </CustomText>
                    <CustomText whiteSpace="normal">{card.imageUrl}</CustomText>
                    <CustomText whiteSpace="normal">{card.date}</CustomText>
                    <CustomText whiteSpace="normal">{card.destinationLink}</CustomText>
                  </Stack>
                )}

                {/* Move Buttons to a New Line */}
                <HStack justifyContent="flex-start" flexWrap="wrap" gap={2}>
                  {editingCardId === card.id ? (
                    <CustomButton
                      icon={<FaCheck />}
                      aria-label="Save Changes"
                      onClick={handleSaveEdit}
                      colorScheme="green"
                    />
                  ) : (
                    <CustomButton
                      icon={<FaEdit />}
                      aria-label="Edit News Card"
                      onClick={() => handleEditNewsArticle(card)}
                      colorScheme="yellow"
                    />
                  )}
                  <CustomButton
                    icon={<FaTrash />}
                    aria-label="Delete News Card"
                    onClick={() => handleDeleteNewsCard(card.id)}
                    colorScheme="red"
                  />
                </HStack>
              </Box>
            ))}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
