import React, { useMemo } from 'react';
import { Box, List, ListItem } from '@chakra-ui/react';
import elasticlunr from 'elasticlunr';
import { useSettings } from '../../../stateManagement/SettingsContext';

interface Article {
  section_name: string;
  id: string;
  title: string;
  body: string;
  stags: string[];
  publish_id: string;
}

interface RelatedArticlesProps {
  faqId?: string; // FAQ ID (optional)
  faqTitle?: string; // FAQ title for related article search
  onClickRelatedArticle: (id: string) => void;
  bgColor?: string;
  textColor?: string;
}

export const RelatedArticles: React.FC<RelatedArticlesProps> = ({
  faqId,
  faqTitle,
  onClickRelatedArticle,
  bgColor,
  textColor,
}) => {
  const { faqs, language, appId } = useSettings();

  const extractTextFromHTML = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  };

  // Filter and normalize articles for indexing
  const normalizedArticles: Article[] = useMemo(() => {
    if (!faqs || !language || !appId) return [];
    return faqs
      .filter(
        (article: any) =>
          article.translations[language] &&
          article.app_id === appId &&
          article['published?'] === true,
      )
      .map((article: any) => ({
        id: article.id,
        title: article.translations[language]?.title || '',
        body: extractTextFromHTML(article.translations[language]?.body || ''),
        stags: article.translations[language]?.stags || [],
        publish_id: article.publish_id,
        section_name: article.section_translations?.[language]?.title || '',
      }));
  }, [faqs, language, appId]);

  const index = useMemo(() => {
    const idx = new elasticlunr.Index<Article>();
    idx.addField('title');
    idx.addField('body');
    idx.addField('stags');
    idx.setRef('id');

    normalizedArticles.forEach(article => {
      idx.addDoc(article);
    });

    return idx;
  }, [normalizedArticles]);

  const getRelatedArticles = (): Article[] => {
    if (!faqTitle || !index) return [];

    const results = index.search(faqTitle, {
      fields: {
        title: { boost: 3 },
        body: { boost: 2 },
        stags: { boost: 1 },
      },
      expand: true,
    });

    const relatedArticles = results
      .map(result => normalizedArticles.find(article => article.id === result.ref)!)
      .filter(article => article && article.publish_id !== faqId);

    // Fallback to include more articles if less than 5 are found
    if (relatedArticles.length < 5) {
      const additionalArticles = normalizedArticles
        .filter(article => !relatedArticles.includes(article) && article.id !== faqId)
        .slice(0, 5 - relatedArticles.length);
      return [...relatedArticles, ...additionalArticles];
    }

    return relatedArticles.slice(0, 5);
  };

  const relatedArticles = getRelatedArticles();

  return (
    <Box backgroundColor={bgColor} textColor={textColor} p={2}>
      {relatedArticles.length > 0 ? (
        <List styleType="none">
          {relatedArticles.map(article => (
            <ListItem key={article.publish_id}>
              <Box
                as="button"
                onClick={() => onClickRelatedArticle(article.publish_id)}
                textAlign="left"
                display="block"
                width="100%"
                padding="8px 12px"
                _hover={{ backgroundColor: 'gray.100' }}
                borderBottom="1px solid #f1f2f4"
              >
                {/* Display the original article title */}
                <strong>
                  {faqs?.find(faq => faq.id === article.id)?.translations[language]?.title}
                </strong>
                {/* Show section name */}
                <Box fontSize="sm" color="gray.500">
                  {article.section_name}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      ) : (
        <Box textAlign="center" color="gray.500" fontSize="sm" p={2}>
          No Related Articles Found
        </Box>
      )}
    </Box>
  );
};
