// src/molecules/SubHeader.tsx
import { Box, Flex, ResponsiveValue, useBreakpointValue } from '@chakra-ui/react';
import { FaGlobe } from 'react-icons/fa';
import { IoIosChatboxes } from 'react-icons/io';
import { CustomButton, CustomIcon } from '../atoms';
import { CustomDropdown } from '../atoms/CustomDropdown';

interface SubHeaderProps {
  platforms: string[]; // Array of platform names
  currentLanguage: string; // Currently selected language
  supportedLanguages?: { label: string; value: string }[];
  onLanguageChange: (item: string) => void;
  horizontalPadding?: ResponsiveValue<string>; // Optional horizontal padding type
  textColor: string;
  subHeaderBgColor: string;
  subHeaderTextColor: string;
  onClickMyTickets: () => void;
  myTicketsButtonText: string;
}

export const SubHeader: React.FC<SubHeaderProps> = ({
  // platforms,
  currentLanguage,
  supportedLanguages,
  onLanguageChange,
  horizontalPadding,
  subHeaderTextColor,
  subHeaderBgColor,
  onClickMyTickets,
  myTicketsButtonText,
}) => {
  // Determine whether to show labels based on screen size
  const showLabels = useBreakpointValue({ base: false, lg: true });
  return (
    <Box bg={subHeaderBgColor} px={horizontalPadding}>
      <Flex
        alignItems="center"
        justify="space-between"
        flexDirection={{ base: 'row', sm: 'row' }} // Flex direction changes at the small screen breakpoint
        wrap="wrap" // Ensure it wraps when necessary
        gap="1"
      >
        {/* All Platforms Dropdown */}
        {/* <CustomDropdown
          textColor={subHeaderTextColor}
          label={showLabels ? 'All Platforms' : 'Platforms  '}
          items={platforms}
          leftIcon={<FaLayerGroup />}
          onItemClick={platform => {
            // eslint-disable-next-line no-console
            console.log('Selected Platform:', platform);
          }}
          dropdownBgColor={subHeaderBgColor}
          value={platforms[0]}
        /> */}

        {/* Flex for Language Dropdown and My Tickets Button */}
        <Flex>
          {/* Language Dropdown */}
          <CustomDropdown
            label={currentLanguage}
            value={currentLanguage}
            items={supportedLanguages?.map(lang => lang.label) ?? []}
            leftIcon={<FaGlobe />}
            onItemClick={onLanguageChange}
            textColor={subHeaderTextColor}
            dropdownBgColor={subHeaderBgColor}
          />
        </Flex>
        <Flex>
          {/* My Tickets Button */}
          <CustomButton
            variant="outline"
            colorScheme="black"
            backgroundColor="transparent"
            color={subHeaderTextColor}
            borderColor={subHeaderTextColor}
            borderRadius="8px"
            mt={{ base: 1, sm: 0, lg: '1' }}
            width={{ base: 'fit-content', sm: 'auto' }} // Full width for smaller screens, auto for larger
            onClick={onClickMyTickets}
            size="sm"
          >
            {showLabels ? myTicketsButtonText : <CustomIcon icon={IoIosChatboxes} />}
          </CustomButton>
        </Flex>
      </Flex>
    </Box>
  );
};
