import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as crypto from 'crypto-js';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CustomButton } from '../atoms/CustomButton';

export const AdminLoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionId = async () => {
      const GET_URL =
        'https://ps-scopely-catalyst-func-auth-00-staging.azurewebsites.net/api/getSessionId?';
      const getConfig = {
        method: 'get',
        maxBodyLength: Infinity,
        url: GET_URL,
        headers: {},
      };

      try {
        await axios.request(getConfig);
      } catch (error) {
        setErrorMessage('Your session has expired, kindly reload the page.');
      }
    };
    fetchSessionId();
  }, []);

  const handleLogin = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setIsLoading(true);

    const usernameInput = document.getElementById('username') as HTMLInputElement | null;
    const passwordInput = document.getElementById('password') as HTMLInputElement | null;

    const inputUsername = usernameInput ? usernameInput.value : '';
    const inputPassword = passwordInput ? passwordInput.value : '';

    const ivPass = process.env.REACT_APP_AUTHENTICATION_STRING || '';
    const keyPass = process.env.REACT_APP_ENCRYPTION_KEY || '';

    function encrypt(text: string, key: string, iv: string) {
      const keyHex = crypto.enc.Hex.parse(key);
      const ivHex = crypto.enc.Hex.parse(iv);
      const encrypted = crypto.AES.encrypt(crypto.enc.Utf8.parse(text), keyHex, {
        iv: ivHex,
        mode: crypto.mode.CBC,
        padding: crypto.pad.Pkcs7,
      });
      return encrypted.ciphertext.toString(crypto.enc.Hex);
    }

    const encryptedPassword = encrypt(inputPassword, keyPass, ivPass);
    const getConfig = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://ps-scopely-catalyst-func-auth-00-staging.azurewebsites.net/api/getSessionId?',
      headers: {},
    };

    try {
      const { data } = await axios.request(getConfig);
      const { sessionId, iv } = data;

      const formData = new FormData();
      formData.append('username', inputUsername);
      formData.append('password', encryptedPassword);
      formData.append('action', 'login');

      const authString = process.env.REACT_APP_AUTHENTICATION_STRING || '';
      const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY || '';

      const encryptedAuthToken = encrypt(authString, encryptionKey, iv);

      const loginConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://ps-scopely-catalyst-func-auth-00-staging.azurewebsites.net/api/authentication?',
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `${encryptedAuthToken}_${sessionId}`,
        },
        data: formData,
      };

      try {
        const loginResponse = await axios.request(loginConfig);
        const loginResult = loginResponse.data;

        if (loginResult.authenticated) {
          if (loginResult.role === 'admin') {
            const adminIv = JSON.stringify(crypto.lib.WordArray.random(16));
            const adminTimestamp = JSON.stringify(Date.now());
            const adminToken = encrypt(adminTimestamp, encryptionKey, adminIv);

            navigate('/admin', {
              state: {
                data: {
                  adminIv,
                  adminTimestamp,
                  adminToken,
                  adminUsername: inputUsername,
                },
              },
            });
          } else {
            setErrorMessage('Invalid credentials');
          }
        } else {
          setErrorMessage('Invalid username/password');
        }
      } catch {
        setErrorMessage('Invalid username/password');
      }
    } catch {
      setErrorMessage('Your session has expired, kindly reload the page.');
    }
    setIsLoading(false);
  };

  return (
    <Container maxW="md" py={8}>
      <Box
        boxShadow="xl"
        border="1px"
        borderColor="gray.100"
        borderRadius="md"
        p={6}
        bg="white"
        w="full"
        mt="5rem"
      >
        <VStack spacing={4}>
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">
            LOGIN
          </Text>
          <form onSubmit={handleLogin} style={{ width: '100%' }}>
            <FormControl isRequired>
              <FormLabel htmlFor="username">Username</FormLabel>
              <Input
                id="username"
                value={username}
                onChange={e => setUsername(e.target.value)}
                placeholder="Enter your username"
                isDisabled={isLoading}
                onFocus={() => setErrorMessage('')}
              />
            </FormControl>
            <FormControl isRequired mt={4}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup>
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  isDisabled={isLoading}
                  onFocus={() => setErrorMessage('')}
                />
                <InputRightElement>
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: 'pointer' }}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            {errorMessage && (
              <Text color="red.500" mt={2}>
                {errorMessage}
              </Text>
            )}
            <CustomButton
              type="submit"
              colorScheme="blue"
              w="full"
              mt={4}
              isLoading={isLoading}
              loadingText="Logging in..."
            >
              Login
            </CustomButton>
          </form>
          <Link
            to="/forgotpassword"
            style={{
              textDecoration: 'none',
              color: '#007bff',
              marginTop: '10px',
              fontSize: 'large',
            }}
          >
            <Text fontSize="sm" color="blue.500">
              Forgot Password?
            </Text>
          </Link>
        </VStack>
      </Box>
    </Container>
  );
};
