import React from 'react';
import { FormControl, InputGroup, ResponsiveValue } from '@chakra-ui/react';
import { Label } from '../../atoms/Label';
import { Description } from '../../atoms/Description';
import { CustomInputNum } from '../../atoms/ContactUsForm/CustomInputNum';

interface CustomInputNumberProps {
  question: string;
  placeholder?: string;
  required: boolean;
  description?: string;
  value?: number;
  step?: number;
  marginStart?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  marginTop?: string;
  fontSize?: string;
  descriptionFontSize?: string;
  isDisabled?: boolean;
  onChange?: (value: number) => void;
}

export const CustomInputNumber: React.FC<CustomInputNumberProps> = ({
  question,
  placeholder,
  required,
  description,
  value,
  step = 1,
  marginStart,
  paddingLeft,
  marginTop,
  fontSize,
  descriptionFontSize,
  isDisabled,
  onChange,
}) => {
  const inputId = 'number-field'; // Unique id for the input field

  return (
    <div>
      <FormControl marginStart={marginStart} paddingLeft={paddingLeft} marginTop={marginTop}>
        <Label label={question} htmlFor={inputId} required={required} fontSize={fontSize} />
        <Description description={description} fontSize={descriptionFontSize} />
        <InputGroup size="md">
          <CustomInputNum
            id={inputId} // Set the id for the input field
            isRequired={required}
            placeholder={placeholder}
            onChange={onChange}
            size="md"
            value={value}
            step={step}
            isDisabled={isDisabled}
            variant="outline"
            bgColor="#FFFFFF"
            height="40px"
            paddingLeft="10px"
            fontSize="md"
            marginTop={marginTop}
          />
        </InputGroup>
      </FormControl>
    </div>
  );
};
