import React from 'react';
import { Box, Image, VStack, Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { isAnyArrayBuffer } from 'util/types';
import { useAuth } from '../../stateManagement/auth/useAuth';

const CDN_URL = process.env.REACT_APP_CDN_URL;

export const LoginPage: React.FC = () => {
  const { handleLogin, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the error parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get('error');

  if (isAuthenticated) {
    navigate(`/`);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bg="gray.900"
      color="white"
    >
      {/* Error Message Display */}
      {error && (
        <Box
          bg="gray.700"
          border="1px solid red"
          maxW="300px"
          color="white"
          p={3}
          mb={4}
          borderRadius="md"
          textAlign="center"
          fontWeight="bold"
          textColor="red"
        >
          Error: {error}
        </Box>
      )}
      <Text fontSize="2xl" mb={6} fontWeight="bold">
        Login with your account
      </Text>
      <VStack spacing={4} w="100%" maxW="300px">
        {/* Epic Games Login */}
        <Box
          p={2}
          onClick={() => handleLogin()}
          display="flex"
          alignItems="center"
          w="100%"
          bg="gray.700"
          borderRadius="md"
          cursor="pointer"
          _hover={{ bg: 'gray.600' }}
          border="1px solid white"
        >
          <Box w={20} h={20}>
            <Image
              src={`${CDN_URL}/images/epiclogo.png`}
              // src="https://cdnhc-e4d9ereqdpc6b9cd.a02.azurefd.net/helpcenter/images/epiclogo.png"
              alt="Epic Games"
              borderRadius="md"
              objectFit="fill"
              w="100%" // Ensures the image takes the full width of the parent box
              h="100%" // Ensures the image takes the full height of the parent box
              mr={4}
            />
          </Box>
          <Text p={2} align="center" fontWeight="medium">
            Login With Epic Games
          </Text>
        </Box>
        {/* Steam Login */}
        <Box
          p={2}
          //   onClick={() => handleLogin()}
          display="flex"
          alignItems="center"
          w="100%"
          bg="gray.700"
          borderRadius="md"
          cursor="pointer"
          _hover={{ bg: 'gray.600' }}
          border="1px solid white"
        >
          <Box w={20} h={20}>
            <Image
              src={`${CDN_URL}/images/steamlogo.png`}
              // src="https://cdnhc-e4d9ereqdpc6b9cd.a02.azurefd.net/helpcenter/images/steamlogo.png"
              alt="Steam"
              borderRadius="md"
              objectFit="fill"
              w="100%" // Ensures the image takes the full width of the parent box
              h="100%" // Ensures the image takes the full height of the parent box
            />
          </Box>
          <Text p={2} align="center" fontWeight="medium">
            Login With Steam Account
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};
