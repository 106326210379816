import React, { useState } from 'react';
import { Box, Icon, Stack, HStack } from '@chakra-ui/react';
import { FaEdit, FaTrash, FaCheck, FaPlus, FaRegQuestionCircle } from 'react-icons/fa';
import { IconPicker } from './iconPicker';
import { getIconFromString } from '../MenuCard';
import { CustomButton, CustomInput, CustomText } from '../../atoms';
import { ToggleAtom } from '../../atoms/CustomToggle';

export interface MenuCard {
  menuCardLink?: string;
  icon: string;
  title: string;
  id: string;
}

interface MenuCardSettingsOrganismProps {
  menuCards: MenuCard[];
  onMenuCardChange: (newCards: MenuCard[]) => void;
  configs: {
    showMenuCards: boolean;
  };
  onConfigChange: (key: string, value: any) => void;
}

export const MenuCardSettingsOrganism: React.FC<MenuCardSettingsOrganismProps> = ({
  menuCards,
  onMenuCardChange,
  configs,
  onConfigChange,
}) => {
  const [editingCardId, setEditingCardId] = useState<string | null>(null);
  const [currentEdit, setCurrentEdit] = useState<MenuCard | null>(null);
  const [showMenuCards, setShowMenuCards] = useState(configs.showMenuCards);

  const handleEditMenuCard = (card: MenuCard) => {
    setEditingCardId(card.id);
    setCurrentEdit({ ...card });
  };

  const handleSaveEdit = () => {
    if (currentEdit && editingCardId) {
      const updatedCards = menuCards.map(card => (card.id === editingCardId ? currentEdit : card));
      onMenuCardChange(updatedCards);
      setEditingCardId(null);
      setCurrentEdit(null);
    }
  };

  const handleDeleteMenuCard = (id: string) => {
    const updatedCards = menuCards.filter(card => card.id !== id);
    onMenuCardChange(updatedCards);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, title: event.target.value });
    }
  };

  const handleIconChange = (iconName: string) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, icon: iconName.split('.')[1] });
    }
  };

  const handleMenuCardLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, menuCardLink: event.target.value });
    }
  };

  const handleAddMenuCard = () => {
    const newCard: MenuCard = {
      id: Date.now().toString(),
      icon: '',
      title: '',
      menuCardLink: '',
    };
    onMenuCardChange([newCard, ...menuCards]);
    setEditingCardId(newCard.id);
    setCurrentEdit(newCard);
  };

  return (
    <Box height="80vh" overflowY="auto" border="1px solid #e2e8f0" p={4} borderRadius="md">
      <HStack
        p={4}
        borderWidth="1px"
        borderRadius="md"
        mb={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <ToggleAtom
          justifyContent="space-between"
          label="Show Menu Cards"
          value={showMenuCards}
          onChange={value => {
            setShowMenuCards(value); // Update local state
            onConfigChange('showMenuCards', value); // Notify parent of the change
          }}
        />
      </HStack>
      <CustomButton
        icon={<FaPlus />}
        // aria-label=""
        onClick={handleAddMenuCard}
        colorScheme="green"
      >
        Add Menu Card
      </CustomButton>
      <Stack spacing={4}>
        <Box mt={6}>
          {menuCards.map(card => (
            <HStack
              key={card.id}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              mb={4}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              {editingCardId === card.id ? (
                <Stack spacing={2} width="100%">
                  <HStack>
                    <IconPicker onSelect={iconName => handleIconChange(iconName)} />
                    {currentEdit?.icon && (
                      <Icon
                        as={getIconFromString(currentEdit.icon) || FaRegQuestionCircle}
                        boxSize={6}
                      />
                    )}
                    <CustomInput
                      value={currentEdit?.title || ''}
                      onChange={handleTitleChange}
                      placeholder="Menu Card Title"
                      overflow="auto"
                    />
                  </HStack>
                  <CustomInput
                    value={currentEdit?.menuCardLink || ''}
                    onChange={handleMenuCardLinkChange}
                    placeholder="Menu Card Link"
                    size="sm"
                    textOverflow="ellipsis"
                  />
                </Stack>
              ) : (
                <Stack spacing={1} width="50%">
                  <HStack alignItems="center">
                    <Icon
                      aria-label="Menu Icon"
                      as={getIconFromString(card.icon) || FaRegQuestionCircle}
                      mr={4}
                    />
                    <CustomText fontWeight="bold">{card.title}</CustomText>
                  </HStack>
                  <CustomText color="gray.500" overflow="ellipsis" whiteSpace="nowrap">
                    {card.menuCardLink}
                  </CustomText>
                </Stack>
              )}

              <HStack>
                {editingCardId === card.id ? (
                  <CustomButton
                    icon={<FaCheck />}
                    aria-label="Save Changes"
                    onClick={handleSaveEdit}
                    colorScheme="green"
                  />
                ) : (
                  <CustomButton
                    icon={<FaEdit />}
                    aria-label="Edit Menu Card"
                    onClick={() => handleEditMenuCard(card)}
                    colorScheme="yellow"
                  />
                )}
                <CustomButton
                  icon={<FaTrash />}
                  aria-label="Delete Menu Card"
                  onClick={() => handleDeleteMenuCard(card.id)}
                  colorScheme="red"
                />
              </HStack>
            </HStack>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};
