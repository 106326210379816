import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '0c741093-5444-4841-97c1-221d8b642f00', // Use the key from Step 1
    enableAutoRouteTracking: true, // Automatically track page views
  },
});

appInsights.loadAppInsights();

export { appInsights };
