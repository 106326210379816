import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TicketData, TicketView } from '../organisms/TicketView';
import { Settings } from '../../interfaces';
import { Header } from '../organisms';
import { Footer } from '../organisms/Footer';

export interface TicketPagePreviewProps {
  settings: Settings;
  supportedLanguages: { label: string; value: string }[];
  onClickMyTickets: () => void;
  onClickLandingPageButton: () => void;
}

export const TicketPagePreview: React.FC<TicketPagePreviewProps> = ({
  settings,
  supportedLanguages,
  onClickMyTickets,
  onClickLandingPageButton,
}) => {
  const [currentLanguage, setCurrentLanguage] = useState('English');
  const handleLanguageChange = () => {
    setCurrentLanguage('english');
  };
  const changeTheme = () => {};

  const { t }: { t: (key: string, options?: { returnObjects: boolean }) => any } = useTranslation();

  const helperTexts = t('data.helperTexts', { returnObjects: true });
  const ticketData: TicketData = {
    ticketId: '20345',
    status: 'Resolved',
    messages: [
      {
        sender: 'test.user@example.com',
        timestamp: '2024-08-13T16:02:00Z',
        message:
          'Hi,<br />As soon as I updated my game to the latest version, I lost my progress. Tried logging in multiple times, still the same. <br /> Please help.',
        origin: 'end-user',
      },
      {
        sender: 'helpshift',
        timestamp: '2024-08-13T16:03:00Z',
        message:
          'Hi there,<br />I have escalated this ticket to second line support, they will get back to you soon.<br />Best regards',
        origin: 'helpshift',
      },
    ],
  };
  // const { settings } = useSettings();
  return (
    <Box>
      {settings?.configs?.showHeader && (
        <Header
          showMainHeader={settings?.configs.showMainHeader}
          mainHeaderBgColor={settings?.configs.mainHeaderBgColor}
          mainHeaderTextColor={settings?.configs.mainHeaderTextColor}
          showSubHeader={settings?.configs.showSubHeader}
          subHeaderBgColor={settings?.configs.primaryBgColor}
          subHeaderTextColor={settings?.configs.primaryTextColor}
          showHeaderLogo={settings?.configs.showHeaderLogo}
          logoUrl={settings?.configs.headerlogoUrl}
          gameTitle={settings?.data.gameTitle}
          platforms={settings?.data.platforms}
          supportedLanguages={supportedLanguages}
          currentLanguage={currentLanguage}
          onLanguageChange={handleLanguageChange}
          horizontalPadding={{ base: '1', md: '8vw', lg: '12vw' }} // Responsive padding
          changeTheme={changeTheme}
          onClickMyTickets={onClickMyTickets}
          myTicketsButtonText={
            helperTexts.find((item: Record<string, string>) => 'myTicketsButtonText' in item)
              ?.myTicketsButtonText
          }
          loginButtonText={
            helperTexts.find((item: Record<string, string>) => 'loginButtonText' in item)
              ?.loginButtonText
          }
          logoWidth={settings?.configs?.headerLogoSize}
          onClickLandingPageButton={onClickLandingPageButton}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        minHeight="76vh"
        backgroundColor={settings?.configs.primaryBgColor}
      >
        <Box flex={1}>
          <TicketView ticketData={ticketData} settings={settings} showHelperButton={false} />
        </Box>
      </Box>
      <Footer
        showFooterLogo={settings?.configs?.showFooterLogo}
        showFooterLinks={settings?.configs?.showFooterLinks}
        links={settings?.data?.footerLinks}
        imageSrc={settings?.configs?.footerLogoUrl}
        logoWidth={settings?.configs?.footerLogoSize}
        footerNote={settings?.data?.footerNote}
        footerBgColor={settings?.configs?.footerBackgroundColor}
        footerTextColor={settings?.configs?.footerTextColor}
      />
    </Box>
  );
};
