import { useEffect, useRef, useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  CloseButton,
  HStack,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  useToast,
  VStack,
  Image,
} from '@chakra-ui/react';
import axios from 'axios';
import { CustomText } from '../../atoms';
import { ColorPickerAtom } from '../../atoms/ColorPickerAtom';
import { ToggleAtom } from '../../atoms/CustomToggle';

interface HeaderSettingsMoleculeProps {
  configs: {
    headerlogoUrl: string;
    showMainHeader: boolean;
    showSubHeader: boolean;
    mainHeaderBgColor: string;
    mainHeaderTextColor: string;
    showHeaderLogo: boolean;
    headerLogoSize?: { base: string; lg: string };
  };
  onConfigChange: (key: string, value: any) => void;
  convertImageToBase64: (file: File) => Promise<string | ArrayBuffer | null>;
}

const BLOB_CONTAINER_NAME = process.env.REACT_APP_BLOB_CONTAINER_NAME || '';

export const HeaderSettingsMolecule: React.FC<HeaderSettingsMoleculeProps> = ({
  configs,
  onConfigChange,
  convertImageToBase64,
}) => {
  const [showMainHeader, setShowMainHeader] = useState(configs.showMainHeader);
  const [showSubHeader, setShowSubHeader] = useState(configs.showSubHeader);
  const [showHeaderLogo, setShowHeaderLogo] = useState(configs.showSubHeader);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const toast = useToast();
  // Sync local state with incoming configs on mount and when configs change
  useEffect(() => {
    setShowMainHeader(configs.showMainHeader);
    setShowSubHeader(configs.showSubHeader);
  }, [configs.showMainHeader, configs.showSubHeader]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resetFileInput = () => {
      setSelectedFile(null);
      setPreviewUrl(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };

    const file = event.target.files?.[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        setAlertMessage('Only image files are allowed.');
        resetFileInput();
        return;
      }

      if (file.type !== 'image/svg+xml') {
        setAlertMessage('Only SVG files are allowed.');
        resetFileInput();
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setAlertMessage('File size must be less than 5MB.');
        resetFileInput();
        return;
      }

      const renamedFile = new File([file], 'headerLogo.svg', { type: file.type });
      setSelectedFile(renamedFile);

      const reader = new FileReader();
      reader.onloadend = () => setPreviewUrl(reader.result as string);
      reader.readAsDataURL(renamedFile);
    }
  };

  // Toggle collapse and expand
  const handleUpload = async () => {
    if (!selectedFile) return;
    setIsUploading(true);
    try {
      const base64String = await convertImageToBase64(selectedFile);

      try {
        const data = JSON.stringify([
          {
            name: `headerLogo.svg`,
            type: 'image',
            path: `${BLOB_CONTAINER_NAME}/images`,
            data: base64String,
          },
        ]);

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://catalysthc.ms.helpshift.live/api/fe-config',
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        };

        const response = await axios.request(config);

        if (response.status >= 200 && response.status < 300) {
          toast({
            title: 'Upload successful!',
            description: 'Your header logo has been uploaded.',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error uploading file:', error);
        toast({
          title: 'Error',
          description: 'An error occurred while uploading.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (error) {
      // console.error('Error uploading file:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while uploading.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box height="80vh" overflowY="auto">
      {/* Section Header */}
      <Stack spacing={4}>
        <VStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          align="start"
        >
          <CustomText>Upload Header Logo</CustomText>
          <HStack spacing={4}>
            <Input
              type="file"
              // accept="image/*"
              accept="image/svg+xml"
              onChange={handleFileChange}
              ref={fileInputRef}
              bg="white"
            />
            <Button
              colorScheme="blue"
              onClick={handleUpload}
              isDisabled={!selectedFile}
              isLoading={isUploading}
            >
              Upload
            </Button>
          </HStack>
          <Text fontSize="sm" color="gray.500" mt={2}>
            Supported file type: <b>SVG</b>
          </Text>
          <Text fontSize="sm" color="gray.500">
            Note: This action will immediately upload the selected image without any further
            confirmation.
          </Text>
        </VStack>
        {previewUrl && (
          <Box mt={4} display="flex" justifyContent="center">
            <Image
              src={previewUrl}
              alt="Preview"
              boxSize="200px"
              borderRadius="md"
              objectFit="cover"
            />
          </Box>
        )}

        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ToggleAtom
            justifyContent="space-between"
            label="Show Main Header"
            value={showMainHeader}
            onChange={value => {
              setShowMainHeader(value); // Update local state
              onConfigChange('showMainHeader', value); // Notify parent of the change
            }}
          />
        </HStack>
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ToggleAtom
            justifyContent="space-between"
            label="Show Sub Header"
            value={showSubHeader}
            onChange={value => {
              setShowSubHeader(value); // Update local state
              onConfigChange('showSubHeader', value); // Notify parent of the change
            }}
          />
        </HStack>
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ToggleAtom
            justifyContent="space-between"
            label="Show Header Logo"
            value={showHeaderLogo}
            onChange={value => {
              setShowHeaderLogo(value); // Update local state
              onConfigChange('showHeaderLogo', value); // Notify parent of the change
            }}
          />
        </HStack>
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ColorPickerAtom
            label="Main Header Background Color"
            value={configs.mainHeaderBgColor}
            onChange={color => onConfigChange('mainHeaderBgColor', color)}
          />
        </HStack>
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ColorPickerAtom
            label="Main Header Text Color"
            value={configs.mainHeaderTextColor}
            onChange={color => onConfigChange('mainHeaderTextColor', color)}
          />
        </HStack>
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <CustomText>Header Logo Size</CustomText>
          <Slider
            value={parseInt(configs?.headerLogoSize?.base || '30', 10)}
            min={10}
            max={100}
            onChange={val =>
              onConfigChange('headerLogoSize', { base: `${val}%`, lg: `${val / 3}%` })
            }
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={6}>
              <Text fontSize="xs">{configs?.headerLogoSize?.base || '30%'}</Text>
            </SliderThumb>
          </Slider>
        </HStack>
      </Stack>
      {alertMessage && (
        <Center
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="1000"
        >
          <Alert status="error" variant="solid" borderRadius="md" boxShadow="lg">
            <AlertIcon />
            <Box>
              <AlertTitle>Error</AlertTitle>
              {alertMessage}
            </Box>
            <CloseButton
              onClick={() => setAlertMessage(null)}
              position="absolute"
              right="8px"
              top="8px"
            />
          </Alert>
        </Center>
      )}
    </Box>
  );
};
