// src/components/atoms/BannerImage.tsx
import { Image, ImageProps, ResponsiveValue } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface CustomImageProps extends ImageProps {
  src?: string;
  alt?: string;
  objectFit?: ResponsiveValue<'fill' | 'contain' | 'cover' | 'none' | 'scale-down'>;
  borderRadius?: string;
  height?: ResponsiveValue<string>;
  width?: ResponsiveValue<string>;
  margin?: ResponsiveValue<string>;
  padding?: ResponsiveValue<string>;
  link?: string;
  border?: ResponsiveValue<string>;
  onClick?: () => void;
}

export const CustomImage: React.FC<CustomImageProps> = ({
  src = '', // Default to an empty string if src is undefined
  alt = 'Image', // Provide a default alt text
  objectFit = 'cover',
  borderRadius = 'md',
  height = '100%',
  width = '100%',
  margin,
  padding,
  link = '',
  border,
  onClick,
}) => {
  const navigate = useNavigate();

  const handleImageClick = (url: string) => {
    if (onClick) {
      onClick();
    }
    if (url) {
      navigate(url);
    }
  };

  return (
    <Image
      src={src}
      alt={alt}
      objectFit={objectFit}
      borderRadius={borderRadius}
      height={height}
      width={width}
      margin={margin}
      padding={padding}
      onClick={() => handleImageClick(link)}
      cursor={link ? 'pointer' : 'default'}
      border={border}
    />
  );
};
