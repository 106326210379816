import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { MenuCard } from '../molecules/MenuCard';

// Define the type for each item in the items array
export interface MenuCardItem {
  menuCardLink: string;
  icon: string;
  title: string;
  id: string;
}

// Define props interface
interface MenuCardListProps {
  items: MenuCardItem[];
  menuCardsBgColor: string;
  menuCradsTextColor: string;
  onClickMenuCard: (menuCardLink: string) => void;
  itemsTranslations: MenuCardItem[];
}

export const MenuCardList: React.FC<MenuCardListProps> = ({
  items,
  menuCardsBgColor,
  menuCradsTextColor,
  onClickMenuCard,
  itemsTranslations,
}) => {
  // const translatedItems = items?.map(item => {
  //   const itemTranslation = itemsTranslations?.find(t => t.id === item.id);
  //   return {
  //     ...item,
  //     text: itemTranslation ? itemTranslation.title : item.title, // Use translated text if available
  //   };
  // });
  const translatedItems = items.map(item => {
    const itemTranslation = itemsTranslations.find(t => t.id === item.id);
    return {
      ...item, // Keep all properties from `items`
      title: itemTranslation?.title ?? item.title, // Override `title` if translation exists
    };
  });
  return (
    <Box display="flex" justifyContent="center" marginTop="2vh" width="100%">
      <SimpleGrid
        columns={{ base: items?.length, sm: Math.min(items?.length ?? 1, 5) }}
        spacing={4}
        maxWidth="1200px"
        width="100%"
      >
        {translatedItems?.map(item => (
          <MenuCard
            key={item.id}
            icon={item.icon}
            title={item.title}
            bg={menuCardsBgColor}
            textColor={menuCradsTextColor}
            onClickMenuCard={() => onClickMenuCard(item.menuCardLink)}
            id={item.id}
            menuCardLink={item.menuCardLink}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
