import { Button, HStack, VStack } from '@chakra-ui/react';
import { CustomText } from '../atoms';

export const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  nextText,
  previousText,
  pageText,
  ofText,
}: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  nextText: string;
  previousText: string;
  pageText: string;
  ofText: string;
}) => (
  <VStack mt={4}>
    <HStack spacing={4}>
      <Button onClick={() => onPageChange(currentPage - 1)} isDisabled={currentPage === 1}>
        {previousText}
      </Button>
      <CustomText>
        {pageText} {currentPage} {ofText} {totalPages}
      </CustomText>
      <Button onClick={() => onPageChange(currentPage + 1)} isDisabled={currentPage === totalPages}>
        {nextText}
      </Button>
    </HStack>
  </VStack>
);
