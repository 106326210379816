import React from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { Layout } from '../templates';
import { TicketView } from '../organisms/TicketView';
import { useSettings } from '../../stateManagement/SettingsContext';

export const TicketPage: React.FC = () => {
  const { settings } = useSettings();
  const location = useLocation();
  const ticketData = location.state?.ticketData;
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="76vh"
        backgroundColor={settings?.configs.primaryBgColor}
      >
        <Box flex={1}>
          <TicketView ticketData={ticketData} settings={settings} />
        </Box>
      </Box>
    </Layout>
  );
};
