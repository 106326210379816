import { Box, Flex, ResponsiveValue } from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';
import { CustomImage } from '../atoms/CustomImage';
import { CustomText } from '../atoms/CustomText';
import { CustomButton } from '../atoms/CustomButton';
import { appInsights } from '../../appInsights';
import { useAuth } from '../../stateManagement/auth/useAuth';

interface MainHeaderProps {
  logoUrl?: string; // Type for logo URL
  logoSize?: ResponsiveValue<string>;
  gameTitle: string; // Type for game title
  horizontalPadding?: ResponsiveValue<string>; // Optional horizontal padding type
  darkMode?: boolean;
  marginStart?: ResponsiveValue<string>;
  showHeaderLogo?: boolean;
  mainHeaderBgColor: string;
  mainHeaderTextColor: string;
  changeTheme?: any;
  loginButtonText: string;
  onClickLandingPageButton?: () => void;
  targetUrl?: string;
  onClickMainHeading?: () => void;
}

export const MainHeader: React.FC<MainHeaderProps> = ({
  logoUrl,
  gameTitle,
  logoSize,
  marginStart,
  showHeaderLogo,
  mainHeaderBgColor = '#fefefe',
  mainHeaderTextColor,
  loginButtonText,
  onClickLandingPageButton,
  targetUrl,
  onClickMainHeading,
  // changeTheme,
}) => {
  const { isAuthenticated, handleLogin, handleLogout } = useAuth();

  const onClickImage = () => {
    appInsights.trackEvent({
      name: 'header',
      properties: { buttonName: 'headerLogoButton', userName: 'anonymous' },
    });
    // eslint-disable-next-line
    if (onClickLandingPageButton) {
      onClickLandingPageButton();
    }
  };
  return (
    <Box p={2} backgroundColor={mainHeaderBgColor}>
      <Flex
        alignItems="center"
        flexDirection="row" // Ensure everything stays in the same row
        justifyContent="space-between"
      >
        {/* Logo and Text Section */}
        <Flex alignItems="center">
          {showHeaderLogo &&
            (targetUrl ? (
              <CustomImage
                src={logoUrl}
                alt="Logo"
                width={logoSize}
                color={mainHeaderTextColor}
                link={targetUrl}
                onClick={onClickImage}
              />
            ) : (
              <CustomImage
                src={logoUrl}
                alt="Logo"
                width={logoSize}
                color={mainHeaderTextColor}
                onClick={onClickImage}
              />
            ))}
          <CustomText
            fontSize="lg"
            fontWeight="bold"
            marginStart={marginStart}
            color={mainHeaderTextColor}
            onClick={onClickMainHeading}
          >
            {gameTitle}
          </CustomText>
        </Flex>

        {/* Login Button */}
        {!isAuthenticated && (
          <CustomButton
            onClick={handleLogin}
            leftIcon={<FaUser />}
            backgroundColor={mainHeaderBgColor}
            color={mainHeaderTextColor}
            _hover={{
              boxShadow: 'lg',
              transform: 'scale(1.05)',
            }}
          >
            {loginButtonText}
          </CustomButton>
        )}

        {/* Loout Button */}
        {isAuthenticated && (
          <CustomButton
            onClick={handleLogout}
            leftIcon={<FaUser />}
            backgroundColor={mainHeaderBgColor}
            color={mainHeaderTextColor}
            _hover={{
              boxShadow: 'lg',
              transform: 'scale(1.05)',
            }}
          >
            Logout
          </CustomButton>
        )}
      </Flex>
    </Box>
  );
};
