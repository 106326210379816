import React from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ContactUs } from '../organisms/ContactUs';
import { Layout } from '../templates';
import { FormField, useSettings } from '../../stateManagement/SettingsContext';

export const ContactUsPage: React.FC = () => {
  const { settings, contactUsFormData } = useSettings(); // Destructure from context
  const { t }: { t: (key: string, options?: { returnObjects: boolean }) => any } = useTranslation();
  const translatedContactUsFormData = t('data.contactUsFormData', { returnObjects: true });
  const helperTexts = t('data.helperTexts', { returnObjects: true });
  const translatedData: FormField[] = (contactUsFormData ?? []).map(link => {
    const translation = translatedContactUsFormData.find(
      (i: {
        id: string;
        question?: string;
        description?: string;
        placeholder?: string;
        options?: string;
      }) => i.id === link.id,
    );
    return {
      ...link,
      question: translation?.question ?? link.question,
      description: translation?.description ?? link.description,
      placeholder: translation?.placeholder ?? link.placeholder,
      options: translation?.options ?? link.linked_cif_options,
    };
  });
  return (
    <Layout>
      <Box backgroundColor={settings?.configs.secondaryBgColor}>
        <ContactUs
          FormFields={translatedData}
          createTicketButtonText={
            helperTexts.find((item: Record<string, string>) => 'createTicketButtonText' in item)
              ?.createTicketButtonText
          }
          liveChatButtonText={
            helperTexts.find((item: Record<string, string>) => 'liveChatButtonText' in item)
              ?.liveChatButtonText
          }
        />
      </Box>
    </Layout>
  );
};
