import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { LandingPage } from './components/pages/landingPage';
import { SettingsProvider } from './stateManagement/SettingsContext';
import { Faq } from './components/pages/Faq';
import { AdminPage } from './components/pages/AdminPage';
import { ContactUsPage } from './components/pages/ContactUsPage';
import { PlayerHubPage } from './components/pages/PlayerHubPage';
import { TicketPage } from './components/pages/TicketPage';
import { Section } from './components/pages/Section';
import { ProtectedRoute } from './routes/ProtectedRoute';
import './i18n';
import { msalConfig } from './stateManagement/auth/authConfig';
import { ForgotPassword } from './components/pages/forgotPasswordPage';
import { ResetPassword } from './components/pages/resetPasswordPage';
import { LoginPage } from './components/pages/Login';
import { AdminLoginPage } from './components/pages/AdminLoginPage';
import { PrivacyPolicyHS } from './components/pages/privacyPolicy';
import { NotFound } from './components/pages/NotFound';

const msalInstance = new PublicClientApplication(msalConfig);

export const App: React.FC = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <SettingsProvider>
          <Routes>
            <Route path="/helpshift/privacy-policy" element={<PrivacyPolicyHS />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/admin/login" element={<AdminLoginPage />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<LandingPage />} />
              <Route path="/article/*" element={<Faq />} />
              <Route path="/sections/*" element={<Section />} />
              <Route path="/playerhub" element={<PlayerHubPage />} />
              <Route path="/tickethistory/*" element={<TicketPage />} />
              <Route path="/contactUs" element={<ContactUsPage />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </SettingsProvider>
      </Router>
    </MsalProvider>
  );
};
