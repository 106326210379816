import React from 'react';
import { Box, FormControl, FormLabel, Textarea, Text, ResponsiveValue } from '@chakra-ui/react';

interface CustomTextAreaProps {
  question: string; // Label
  placeholder?: string;
  required?: boolean;
  description?: string;
  marginTop?: ResponsiveValue<string>;
  fontSize?: ResponsiveValue<string>;
  descriptionFontSize?: ResponsiveValue<string>;
  height?: ResponsiveValue<string>;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  isDisabled?: boolean;
  id?: string;
}

export const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  question,
  placeholder,
  required = false,
  description,
  marginTop = '2rem',
  fontSize = 'md',
  descriptionFontSize = 'sm',
  height = '200px',
  onChange,
  value,
  isDisabled = false,
  id,
}) => {
  const inputId = 'Textarea-field';
  return (
    <Box marginTop={marginTop} width="100%">
      <FormControl isRequired={required}>
        <FormLabel htmlFor={inputId} fontSize={fontSize} fontWeight="bold">
          {question}
        </FormLabel>
        {description && (
          <Text fontSize={descriptionFontSize} color="gray.500" mb="6px">
            {description}
          </Text>
        )}
        <Textarea
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
          fontSize={fontSize}
          height={height} // Adjust height dynamically
          width="100%"
          backgroundColor="white"
          padding="12px"
          borderRadius="8px"
          border="1px solid #ccc"
          resize="vertical"
          _placeholder={{ color: 'gray.400' }}
          _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.5)' }}
        />
      </FormControl>
    </Box>
  );
};
