// LandingPage.tsx
import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuCardItem, MenuCardList } from '../organisms/MenuCardList';
import { NewsArticle, NewsSection } from '../organisms/NewsSection';
import { FaqArticle, PopularArticles } from '../organisms/PopularArticles';
import { BannerWithSearchBar } from '../organisms/BannerWithSearchBar';
import { ContactBtnSection } from '../molecules/ContactUsBtnSection';
import { SectionIcons, useSettings } from '../../stateManagement/SettingsContext';
import { Layout } from '../templates';
import { SectionGrid } from '../organisms/SectionGrid';
import { hmac, initializeHelpshiftChat } from '../../utils';
import { appInsights } from '../../appInsights';
import { useAuth } from '../../stateManagement/auth/useAuth';

const PLATFORM_ID = process.env.REACT_APP_WEB_PLATFORM_ID || '';
const DOMAIN = process.env.REACT_APP_DOMAIN || '';
const LANGUAGE = process.env.REACT_APP_LANGUAGE || '';
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || '';

export const LandingPage: React.FC = () => {
  const { settings, sectionsData, faqs, language } = useSettings(); // Destructure from context
  const [showSectionGrid, setShowSectionGrid] = useState(
    settings?.configs.showSectionGrid || false,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!sectionsData || sectionsData.length === 0) {
      setShowSectionGrid(false);
    }
    if (sectionsData?.length !== 0) {
      setShowSectionGrid(true);
    }
  }, [sectionsData]);

  const { user } = useAuth();
  const userId = JSON.stringify(user?.idTokenClaims?.epic_id);
  const userEmail = user?.idTokenClaims?.emails?.[0] || '';
  const userName = user?.idTokenClaims?.name || '';
  initializeHelpshiftChat(
    PLATFORM_ID,
    DOMAIN,
    LANGUAGE,
    userName,
    userEmail,
    userId,
    navigate,
    'hideWidget',
    hmac(userId, userEmail, SECRET_KEY),
  );

  const onClickContactUs = () => {
    // eslint-disable-next-line
    console.log('Contact Us button clicked');
    appInsights.trackEvent({
      name: 'landingPage',
      properties: { buttonName: 'contactUsButton', userName: 'anonymous' },
    });
    // eslint-disable-next-line
    console.log('done');
    navigate('/contactUs');
  };

  const onClickMenuCard = (menuCardLink: string) => {
    window.open(menuCardLink, '_self');
  };

  const onClickPopularArticle = (publish_id: string | number) => {
    const faq = faqs?.find(item => item.publish_id === publish_id);
    if (faq) {
      const translation = faq.translations[language];

      if (translation) {
        const formattedTitle = translation.title.toLowerCase().replace(/\s+/g, '-');
        navigate(`/article/${language}/faq/${publish_id}-${formattedTitle}`);
        appInsights.trackEvent({
          name: 'landingPage',
          properties: {
            buttonName: 'popularArticlesTile',
            userName: 'anonymous',
            publishId: publish_id,
            articleTitle: formattedTitle,
          },
        });
        // eslint-disable-next-line
        console.log('done');
      } else {
        const englishTranslation = faq.translations.en;
        const formattedTitle = englishTranslation.title.toLowerCase().replace(/\s+/g, '-');
        navigate(`/article/en/faq/${publish_id}-${formattedTitle}`);
        appInsights.trackEvent({
          name: 'landingPage',
          properties: {
            buttonName: 'popularArticlesTile',
            userName: 'anonymous',
            publishId: publish_id,
            articleTitle: formattedTitle,
          },
        });
      }
    }
  };
  const { t }: { t: (key: string, options?: { returnObjects: boolean }) => any } = useTranslation();
  const menuList = t('data.menuCards', { returnObjects: true }) as MenuCardItem[];
  const popularArticleList = t('data.popularArticles', { returnObjects: true }) as FaqArticle[];
  const newsArticleList = t('data.newsArticles', { returnObjects: true }) as NewsArticle[];
  const sectionIconsList = t('data.sectionIcons', { returnObjects: true }) as SectionIcons[];
  const categoryHeadings = t('data.categoryHeadings', { returnObjects: true });
  const helperTexts = t('data.helperTexts', { returnObjects: true });

  return (
    <Layout>
      <Box>
        {/* Banner with Search Bar */}
        {settings?.configs.showBanner && (
          <BannerWithSearchBar
            isFullScreen={!settings?.configs.isBannerFullScreen}
            px={{
              base: '2vw',
              md: '1vw',
              lg: !settings?.configs.isBannerFullScreen ? '0vw' : '15vw',
            }} // set 15vw to column size and 0vw for full screen banner
            imageSrc={settings?.configs.bannerImageUrl}
            altText="Banner image"
            bannerText={
              categoryHeadings.find((item: Record<string, string>) => 'bannerText' in item)
                ?.bannerText
            }
            placeholderText={
              categoryHeadings.find(
                (item: Record<string, string>) => 'searchBarPlaceholderText' in item,
              )?.searchBarPlaceholderText || 'Search for Articles'
            }
            placeholderFontSize={{ base: '18px', md: '20px', lg: '22px' }} // Responsive font size
            placeholderColor={settings?.configs.placeholderColor}
            searchBarTextColor={settings?.configs.searchBarTextColor}
            showOverlay={settings?.configs.showOverlay}
            showSearchBar={settings?.configs.showSearchBar}
            bannerTextColor={settings?.configs.bannerTextColor}
          />
        )}

        {/* Main content with responsive padding */}
        <Box px={{ base: '2vw', md: '1vw', lg: '15vw' }}>
          {/* Menu Cards */}
          {settings?.configs.showMenuCards && (
            <MenuCardList
              items={settings?.data.menuCards}
              itemsTranslations={menuList}
              menuCardsBgColor={settings?.configs.secondaryBgColor ?? ''}
              menuCradsTextColor={settings?.configs.secondaryTextColor ?? ''}
              onClickMenuCard={(id: string) => onClickMenuCard(id)}
            />
          )}
          {/* News Section */}
          {settings?.configs.showNewsSection && (
            <NewsSection
              newsArticles={settings?.data.newsArticles}
              newsArticlesTranslations={newsArticleList}
              newsArticleBgColor={settings?.configs.primaryBgColor}
              newsArticleTextColor={settings?.configs.primaryTextColor}
              newsSectionBgColor={settings?.configs.secondaryBgColor}
              newsSectionHeadingTextColor={settings?.configs.secondaryTextColor}
              paginationSelectedPageBgColor={settings?.configs.paginationSelectedPageBgColor}
              paginationSelectedPageTextColor={settings?.configs.paginationSelectedPageTextColor}
              newsSectionHeadingText={
                categoryHeadings.find(
                  (item: Record<string, string>) => 'newsArticlesHeading' in item,
                )?.newsArticlesHeading
              }
              newsSectionHelperTextView={
                helperTexts.find((item: Record<string, string>) => 'viewAll' in item)?.viewAll
              }
              newsSectionHelperTextCollapse={
                helperTexts.find((item: Record<string, string>) => 'collapse' in item)?.collapse
              }
            />
          )}

          {/* Popular Articles */}
          {settings?.configs.showPopularArticles && (
            <PopularArticles
              faqArticles={settings?.data.popularArticles}
              faqArticleTranslations={popularArticleList}
              bgColor={settings?.configs.primaryBgColor}
              faqArticlesTextColor={settings?.configs.primaryTextColor ?? ''}
              faqArticlesBgColor={settings?.configs.secondaryBgColor ?? ''}
              secondaryTextColor={settings?.configs.secondaryTextColor ?? ''}
              onClickPopularArticle={onClickPopularArticle}
              faqArticlesHeading={
                categoryHeadings.find(
                  (item: Record<string, string>) => 'popularArticlesHeading' in item,
                )?.popularArticlesHeading
              }
            />
          )}

          {/* section wise faq display */}
          {showSectionGrid && (
            <SectionGrid
              textColor={settings?.configs.secondaryTextColor}
              sectionBgColor={settings?.configs.secondaryBgColor}
              sections={sectionsData}
              sectionIconsTranslations={sectionIconsList}
              sectionIcons={settings?.data.sectionIcons}
              sectionsHeading={
                categoryHeadings.find((item: Record<string, string>) => 'categoriesHeading' in item)
                  ?.categoriesHeading
              }
              sectionHelperText={
                helperTexts.find((item: Record<string, string>) => 'more' in item)?.more
              }
              sectionCardTextColor={settings?.configs.primaryTextColor || 'black'}
            />
          )}
        </Box>

        {/* Contact Button Section */}
        {settings?.configs.showContactUsButton && (
          <ContactBtnSection
            onButtonClick={onClickContactUs}
            variant="h1"
            color={settings?.configs.contactUsButtonTextColor}
            buttonBgColor={settings?.configs.contactUsButtonBgColor}
            needHelpText={t('configs.needHelpText')}
            needHelpTextColor={settings?.configs.needHelpTextColor}
            mt="4vh"
            contactUsButtonText={
              helperTexts.find((item: Record<string, string>) => 'contactUsButtonText' in item)
                ?.contactUsButtonText
            }
          />
        )}
      </Box>
    </Layout>
  );
};
