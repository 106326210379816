import React from 'react'; // Import useState
import { Box, Stack, Center, ResponsiveValue } from '@chakra-ui/react';
import { CustomImage } from '../atoms/CustomImage'; // Reusing the existing BannerImage atom
import { CustomText } from '../atoms/CustomText'; // Reusing the existing Typography atom
import { SearchBar } from './SearchBar';

// Define props interface
interface BannerWithSearchBarProps {
  imageSrc: string | undefined; // Source URL for the background image
  altText: string; // Alt text for the image
  bannerText: string; // Text to display on the banner
  placeholderFontSize?: ResponsiveValue<string>; // Font size for the placeholder text
  placeholderFontFamily?: string; // Font family for the placeholder text
  placeholderText: string; // Placeholder text for the search input
  placeholderColor?: string;
  searchBarTextColor?: string;
  showOverlay: boolean | undefined;
  showSearchBar: boolean | undefined;
  px?: ResponsiveValue<string>;
  isFullScreen?: boolean;
  searchBarPx?: ResponsiveValue<string>;
  bannerTextColor?: string;
}

export const BannerWithSearchBar: React.FC<BannerWithSearchBarProps> = ({
  isFullScreen = true,
  imageSrc,
  altText,
  bannerText,
  placeholderFontSize,
  placeholderFontFamily,
  placeholderText,
  placeholderColor,
  searchBarTextColor,
  showOverlay,
  showSearchBar = true,
  px = { base: '2vw', md: '1vw', lg: '15vw' },
  searchBarPx = { base: '2vw', md: '1vw', lg: '15vw' },
  bannerTextColor = 'white',
}) => {
  return (
    <Box
      position="relative"
      height={{ base: '40vh', md: '50vh' }} // Responsive height
      overflow="visible"
      px={px}
    >
      {/* Background Image (Atom) */}
      <CustomImage src={imageSrc} alt={altText} objectFit="fill" />

      {/* Overlay for text and input */}
      <Box
        px={px}
        position="absolute"
        bottom="0"
        right={{
          base: (px as any)?.base,
          md: (px as any)?.md,
          sm: (px as any)?.sm,
          lg: (px as any)?.lg,
        }}
        left={{
          base: (px as any)?.base,
          md: (px as any)?.md,
          sm: (px as any)?.sm,
          lg: (px as any)?.lg,
        }}
        // width="100%"
        height="50%"
        bg={showOverlay ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)'}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      />
      <Box
        position="absolute"
        bottom="0"
        right="0"
        width="100%"
        height="50%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {showSearchBar && (
          <Center>
            <Stack spacing={6} width="100%">
              {/* Heading Text (Atom) */}
              <CustomText
                variant="h1"
                color={bannerTextColor}
                textAlign="center"
                fontSize={{ base: '2xl', md: '4xl', lg: '5xl' }} // Responsive font size
                fontWeight="700"
              >
                {bannerText}
              </CustomText>

              <SearchBar
                isFullScreen={isFullScreen}
                searchBarPx={searchBarPx}
                searchBarTextColor={searchBarTextColor}
                placeholderText={placeholderText}
                placeholderColor={placeholderColor}
                placeholderFontSize={placeholderFontSize}
                placeholderFontFamily={placeholderFontFamily}
              />
            </Stack>
          </Center>
        )}
      </Box>
    </Box>
  );
};

// export const BannerWithSearchBar: React.FC<BannerWithSearchBarProps> = ({
//   isFullScreen = true,
//   imageSrc,
//   altText,
//   bannerText,
//   placeholderFontSize,
//   placeholderFontFamily,
//   placeholderText,
//   placeholderColor,
//   searchBarTextColor,
//   showOverlay,
//   showSearchBar = true,
//   px = { base: '2vw', md: '1vw', lg: '15vw' },
//   searchBarPx = { base: '2vw', md: '1vw', lg: '15vw' },
// }) => {

//   return (
//     <Box
//       position="relative"
//       height={{ base: '40vh', md: '50vh' }} // Responsive height
//       px={px}
//     >
//       {/* Background Image (Atom) */}
//       <CustomImage src={imageSrc} alt={altText} objectFit="cover" width="100%" height="100%" />

//       {/* Overlay for text and input (50% from bottom) */}
//       <Box
//         border="1px solid red"
//         position="absolute"
//         bottom="0"
//         right="7vw"
//         left="7vw"
//         height="50%" // Ensuring it covers only the bottom half
//         px={px}
//         display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         bg={showOverlay ? 'rgba(0, 0, 0, 0.5)' : 'transparent'}
//       >
//         {showSearchBar && (
//           <Stack spacing={6} width="100%">
//             {/* Heading Text (Atom) */}
//             <CustomText
//               variant="h1"
//               color="white"
//               textAlign="center"
//               fontSize={{ base: '2xl', md: '4xl', lg: '5xl' }} // Responsive font size
//               fontWeight="700"
//             >
//               {bannerText}
//             </CustomText>

//             <SearchBar
//               isFullScreen={isFullScreen}
//               searchBarPx={searchBarPx}
//               searchBarTextColor={searchBarTextColor}
//               placeholderText={placeholderText}
//               placeholderColor={placeholderColor}
//               placeholderFontSize={placeholderFontSize}
//               placeholderFontFamily={placeholderFontFamily}
//             />
//           </Stack>
//         )}
//       </Box>
//     </Box>
//   );
// };
