import React, { useState } from 'react';
import { Box, Checkbox, Stack, VStack, Heading, Text, Button, HStack } from '@chakra-ui/react';
import { ToggleAtom } from '../../atoms/CustomToggle';
import { FaqArticle } from '../../organisms/PopularArticles';

// import { FaPlus } from 'react-icons/fa';

// Interfaces for FAQ and Section
interface FAQ {
  publish_id: string;
  faq_title: string;
}

interface Section {
  section_id: string;
  section_name: string;
  faq_data: FAQ[];
}

interface FAQSelectionOrganismProps {
  sectionsData: Section[] | null;
  onSelectionChange: (selectedFaqs: { id: string; title: string; category: string }[]) => void;
  configs: {
    showPopularArticles: boolean;
  };
  data: {
    popularArticles: FaqArticle[];
  };
  onConfigChange: (key: string, value: any) => void;
}

export const PopularArticlesSettingsMolecule: React.FC<FAQSelectionOrganismProps> = ({
  sectionsData,
  onSelectionChange,
  configs,
  data,
  onConfigChange,
}) => {
  const [selectedFaqIds, setSelectedFaqIds] = useState<string[]>(
    data?.popularArticles?.map(article => String(article.id)) || [],
  );
  const [selectedFaqs, setSelectedFaqs] = useState<
    { id: string; title: string; category: string }[]
  >(
    data?.popularArticles?.map(article => ({
      id: String(article.id),
      title: article.title,
      category: article.category,
    })) || [],
  );
  const [showPopularArticles, setShowPopularArticles] = useState(configs.showPopularArticles);
  const getFaqDetails = (faqId: string) => {
    if (!sectionsData) return null; // Ensures a consistent return type

    const foundFaq = sectionsData
      .flatMap(section =>
        section.faq_data.map(faq => ({
          id: faq.publish_id,
          title: faq.faq_title,
          category: section.section_name,
        })),
      )
      .find(faq => faq.id === faqId);

    return foundFaq || null; // Always returns an object or null
  };
  const handleCheckboxChange = (faqId: string, isChecked: boolean) => {
    // Find the FAQ corresponding to the id
    const foundFaq = getFaqDetails(faqId);

    if (!foundFaq) return; // Exit if FAQ not found
    if (isChecked) {
      if (selectedFaqIds.length < 6) {
        const updatedFaqIds = [...selectedFaqIds, faqId];
        setSelectedFaqIds(updatedFaqIds);
        const updatedFaqs = [...selectedFaqs, foundFaq];
        setSelectedFaqs(updatedFaqs);
        onSelectionChange(updatedFaqs);
      }
    } else {
      // Remove from selectedFaqIds
      const updatedFaqIds = selectedFaqIds.filter(id => id !== faqId);
      setSelectedFaqIds(updatedFaqIds);
      const updatedFaqs = selectedFaqs.filter(faq => faq.id !== faqId);
      setSelectedFaqs(updatedFaqs);
      onSelectionChange(updatedFaqs);
    }
  };

  return (
    <Box height="80vh" overflowY="auto" border="1px solid #e2e8f0" p={4} borderRadius="md">
      <HStack
        p={4}
        borderWidth="1px"
        borderRadius="md"
        mb={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <ToggleAtom
          justifyContent="space-between"
          label="Show Popular Articles"
          value={showPopularArticles}
          onChange={value => {
            setShowPopularArticles(value); // Update local state
            onConfigChange('showPopularArticles', value); // Notify parent of the change
          }}
        />
      </HStack>
      <Box maxWidth="100%" overflow="hidden">
        <Stack spacing={6}>
          {sectionsData?.map(section => (
            <Box key={section.section_id} borderBottom="1px solid #e2e8f0" pb={4}>
              <Heading size="sm" mb={2}>
                {section.section_name}
              </Heading>
              {/* <CheckboxGroup> */}
              <VStack align="start" spacing={2}>
                {section.faq_data.map(faq => (
                  <Checkbox
                    key={faq.publish_id}
                    value={faq.publish_id}
                    isChecked={selectedFaqIds.includes(faq.publish_id)}
                    isDisabled={
                      !selectedFaqIds.includes(faq.publish_id) && selectedFaqIds.length >= 6
                    }
                    onChange={e => handleCheckboxChange(faq.publish_id, e.target.checked)}
                  >
                    {faq.faq_title}
                  </Checkbox>
                ))}
              </VStack>
              {/* </CheckboxGroup> */}
            </Box>
          ))}
        </Stack>

        {/* Footer */}
        <Stack
          direction="row"
          justifyContent="space-between"
          mt={6}
          bg="white"
          p={2}
          position="sticky"
          bottom="0"
        >
          <Text fontWeight="medium">Selected FAQs: {selectedFaqIds.length} / 6</Text>
          <Button
            colorScheme="blue"
            onClick={() => {
              // console.log('Selected FAQs:', selectedFaqIds);
            }}
            isDisabled={selectedFaqIds.length === 0}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
