/* eslint-disable */
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

export function hmac(uid: string | null, email: string | null, secret: string): string {
  let message = '';
  if (uid) message += uid;
  if (email) message += email;

  return Base64.stringify(HmacSHA256(message, secret));
}

export function initializeHelpshiftChat(
  platformId: string,
  domain: string,
  language: string,
  username: string,
  userEmail: string,
  userId: string,
  navigate: (path: string) => void,
  property?: string,
  userAuthToken?: string,
  userFirstMessage?: string,
  customIssueFields?: Record<string, { type: string; value: string }>,
  marginTop?: string,
) {
  (window as any).helpshiftConfig = {
    platformId,
    domain,
    language,
    userName: username,
    userEmail,
    userId,
    userAuthToken,
  };

  if (typeof (window as any).Helpshift !== 'function') {
    console.log('inside if');
    const Helpshift = function (p0?: string) {
      (Helpshift as any).q.push(arguments);
    };
    (Helpshift as any).q = [];
    (window as any).Helpshift = Helpshift;

    const scriptId = 'hs-chat';
    if (!document.getElementById(scriptId)) {
      const scriptElement = document.createElement('script');
      scriptElement.async = true;
      scriptElement.id = scriptId;
      scriptElement.src = 'https://webchat.helpshift.com/latest/webChat.js';

      const waitForIframeLauncher = (retries: number, interval: number) => {
        const checkElement = () => {
          const el = document.getElementById('hs-web-sdk-iframe-launcher');
          // console.log('found it?', el);
          if (el) {
            el.style.top = '80%';
          } else if (retries > 0) {
            setTimeout(() => waitForIframeLauncher(retries - 1, interval), interval);
          } else {
            console.error('hs-web-sdk-iframe-launcher not found after retries');
          }
        };
        checkElement();
      };

      scriptElement.addEventListener('load', () => {
        (window as any).Helpshift('init');
        if (customIssueFields) {
          (window as any).Helpshift('setCustomIssueFields', customIssueFields);
        }

        // Execute the property logic
        switch (property) {
          case 'showWidget':
            (window as any).Helpshift('show');
            break;
          case 'hideWidget':
            (window as any).Helpshift('hide');
            break;
          case 'openWidget':
            (window as any).Helpshift('open');
            break;
          case 'closeWidget':
            (window as any).Helpshift('close');
            break;
          case 'showWidget,openWidget':
          case 'openWidget,showWidget':
            (window as any).Helpshift('show');
            (window as any).Helpshift('open');
            break;
          default:
            console.log('Invalid property value:', property);
            break;
        }

        // Check for the iframe launcher after Helpshift initializes
        waitForIframeLauncher(10, 500); // Retry 10 times with 500ms interval
      });

      const firstScript = document.getElementsByTagName('script')[0];
      if (firstScript.parentNode) {
        firstScript.parentNode.insertBefore(scriptElement, firstScript);
      }
      if (userFirstMessage && window.helpshiftConfig) {
        console.log('in user first message');
        window.helpshiftConfig.initialUserMessage = userFirstMessage;
        window.helpshiftConfig.initiateChatOnLoad = true;
        Helpshift('updateHelpshiftConfig');
      }
    }
  } else {
    // Handle cases where Helpshift is already defined
    if (customIssueFields) {
      (window as any).Helpshift('setCustomIssueFields', customIssueFields);
    }
    console.log('inside else');
    switch (property) {
      case 'showWidget':
        console.log('show');
        (window as any).Helpshift('show');
        break;
      case 'hideWidget':
        (window as any).Helpshift('hide');
        break;
      case 'openWidget':
        console.log('open');
        (window as any).Helpshift('open');
        break;
      case 'closeWidget':
        (window as any).Helpshift('close');
        break;
      case 'showWidget,openWidget':
      case 'openWidget,showWidget':
        console.log('show and open');
        (window as any).Helpshift('show');
        (window as any).Helpshift('open');
        break;
      default:
        console.log('Invalid property value:', property);
        break;
    }

    const waitForIframeLauncher = (retries: number, interval: number) => {
      const checkElement = () => {
        const el = document.getElementById('hs-web-sdk-iframe-launcher');
        console.log('found it?', el);
        if (el) {
          if (marginTop) {
            el.style.top = marginTop;
          } else {
            el.style.top = '90%';
          }
        } else if (retries > 0) {
          setTimeout(() => waitForIframeLauncher(retries - 1, interval), interval);
        } else {
          console.error('hs-web-sdk-iframe-launcher not found after retries');
        }
      };
      checkElement();
    };

    waitForIframeLauncher(10, 500); // Retry 10 times with 500ms interval
    if (userFirstMessage && window.helpshiftConfig) {
      console.log('in user first message');
      window.helpshiftConfig.initialUserMessage = userFirstMessage;
      window.helpshiftConfig.initiateChatOnLoad = true;
      (window as any).Helpshift('updateHelpshiftConfig');
    }
  }
  // var conversationEnd = function () {
  //   console.log('Conversation has ended.');
  //   // (window as any).Helpshift('close');
  //   // (window as any).Helpshift('hide');
  //   const el = document.querySelector('.hs-chat-footer__new-conversation-button');
  //   if (el) {
  //     console.log('found el');
  //     el.remove();
  //   }
  //   // navigate('/test');
  // };
  var conversationEnd = function () {
    console.log('Conversation has ended.');
    // (window as any).Helpshift('close');
    // (window as any).Helpshift('hide');

    const interval = setInterval(() => {
      const el = document.querySelector('.hs-chat-footer__new-conversation-button');
      // console.log('found el?', el); // Log every time it tries

      if (el) {
        console.log('found el!!!', el); // Log the element when found
        el.remove();
        clearInterval(interval); // Stop checking after removing
      } else {
        // console.log('no');
      }
    }, 500); // Check every 500ms

    // Stop checking after 10 seconds
    setTimeout(() => {
      clearInterval(interval);
      console.log('Stopped searching for the element.');
    }, 10000);
  };
  // (window as any).Helpshift('addEventListener', 'conversationEnd', conversationEnd);
  // (window as any).Helpshift('addEventListener', 'csatSubmit', conversationEnd);
  (window as any).Helpshift('addEventListener', 'conversationEnd', conversationEnd);
  // (window as any).Helpshift('addEventListener', 'conversationEnd', conversationEnd);
  // (window as any).Helpshift('removeEventListener', 'conversationEnd', conversationEndEventHandler);
}
