import { Box, SimpleGrid, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import tinycolor from 'tinycolor2';
import { NewsCard } from '../molecules/NewsCard';
import { CustomText } from '../atoms/CustomText';
import { CustomButton } from '../atoms';
import { appInsights } from '../../appInsights';

export interface NewsArticle {
  imageUrl?: string;
  title: string;
  date: string;
  id: string;
  destinationLink: string;
}

export interface NewsSectionProps {
  newsArticles: NewsArticle[];
  newsArticleBgColor?: string;
  newsArticleTextColor?: string;
  newsSectionBgColor?: string;
  newsSectionHeadingTextColor?: string;
  paginationSelectedPageBgColor?: string;
  paginationSelectedPageTextColor?: string;
  newsArticlesTranslations: NewsArticle[];
  newsSectionHeadingText: string;
  newsSectionHelperTextView: string;
  newsSectionHelperTextCollapse: string;
}

export const NewsSection: React.FC<NewsSectionProps> = ({
  newsArticles,
  newsArticleBgColor,
  newsArticleTextColor,
  newsSectionBgColor,
  newsSectionHeadingTextColor,
  paginationSelectedPageBgColor = newsSectionHeadingTextColor,
  paginationSelectedPageTextColor = 'white',
  newsArticlesTranslations,
  newsSectionHeadingText,
  newsSectionHelperTextView,
  newsSectionHelperTextCollapse,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showAll, setShowAll] = useState<boolean>(false);
  const articlesPerPage = 3;
  const totalPages = Math.ceil((newsArticles?.length ?? 0) / articlesPerPage);
  const translatedNewsArticle = newsArticles.map(item => {
    const itemTranslation = newsArticlesTranslations.find(t => t.id === item.id);
    return {
      ...item, // Keep all properties from `items`
      title: itemTranslation?.title ?? item.title, // Override `title` if translation exists
    };
  });

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = showAll
    ? translatedNewsArticle
    : translatedNewsArticle?.slice(indexOfFirstArticle, indexOfLastArticle);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    setShowAll(false);
  };

  const handleShowAll = () => {
    setShowAll(true);
  };

  const handleCollapse = () => {
    setShowAll(false);
  };

  const onClickNewsItem = (
    id: string | number,
    title: string,
    date: string,
    destinationLink: string,
  ) => {
    window.open(destinationLink, '_blank');
    appInsights.trackEvent({
      name: 'landingPage',
      properties: {
        buttonName: 'newsArticleTile',
        userName: 'anonymous',
        publishId: id,
        newsArticleTitle: title,
        newsArticleDate: date,
      },
    });
    // eslint-disable-next-line
    console.log('done');
  };

  return (
    <Box p="10" backgroundColor={newsSectionBgColor} marginTop="2vh">
      <CustomText
        variant="h1"
        fontSize="3xl"
        fontWeight="bold"
        mb="6"
        color={newsSectionHeadingTextColor}
      >
        {newsSectionHeadingText}
      </CustomText>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing="6" mt="4vh">
        {currentArticles?.map(article => (
          <NewsCard
            key={article.id}
            imageUrl={article.imageUrl}
            title={article.title}
            date={article.date}
            bgColor={newsArticleBgColor}
            textColor={newsArticleTextColor}
            id={article.id} // Pass the id here, if required
            onClickNewsItem={() =>
              onClickNewsItem(article.id, article.title, article.date, article.destinationLink)
            } // Pass the function reference
          />
        ))}
      </SimpleGrid>

      <Flex justifyContent="space-between" alignItems="center" mt="6">
        {!showAll && totalPages > 1 && (
          <Flex>
            {[...Array(totalPages)].map((_, pageIndex) => {
              const pageNumber = pageIndex + 1;
              return (
                <CustomButton
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  backgroundColor={
                    currentPage === pageNumber
                      ? paginationSelectedPageBgColor
                      : tinycolor(paginationSelectedPageBgColor).lighten(30).toString()
                  }
                  _hover={{
                    color: paginationSelectedPageBgColor,
                    bg: tinycolor(paginationSelectedPageBgColor).lighten(60).toString(),
                  }}
                  color={paginationSelectedPageTextColor}
                  mx="1"
                  size="sm"
                  borderRadius="full"
                  padding="0"
                >
                  {pageNumber}
                </CustomButton>
              );
            })}
          </Flex>
        )}

        <CustomButton
          onClick={showAll ? handleCollapse : handleShowAll}
          variant="link"
          color={newsSectionHeadingTextColor}
        >
          {showAll ? newsSectionHelperTextCollapse : newsSectionHelperTextView}
        </CustomButton>
      </Flex>
    </Box>
  );
};
