// LandingPagePreview.tsx
import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MenuCardList } from '../organisms/MenuCardList';
import { NewsSection } from '../organisms/NewsSection';
import { FaqArticle, PopularArticles } from '../organisms/PopularArticles';
import { BannerWithSearchBar } from '../organisms/BannerWithSearchBar';
import { ContactBtnSection } from '../molecules/ContactUsBtnSection';
import { Header } from '../organisms/Header';
import { Footer } from '../organisms/Footer';
import { SectionGrid } from '../organisms/SectionGrid';
import { SectionData } from '../../interfaces';

interface TranslationData {
  configs: {
    needHelpText: string;
  };
  data: {
    gameTitle: string;
    platforms: string[];
    newsArticles: { id: string; title: string }[];
    popularArticles: { id: string; title: string; category: string }[];
    menuCards: { id: string; title: string }[];
    footerLinks: { id: number; text: string }[];
    sectionIcons: { id: string; name: string }[];
    categoryHeadings: { [key: string]: string }[];
    helperTexts: { [key: string]: string }[];
    contactUsFormData: {
      id: string;
      question?: string;
      description?: string;
      placeholder?: string;
      options?: string;
    }[];
    footerNote: string;
  };
}
interface LandingPagePreviewProps {
  settings: any;
  translations: TranslationData;
  sectionsData: SectionData[] | null;
  supportedLanguages: { label: string; value: string }[];
  onClickMyTickets: () => void;
  onClickLandingPageButton: () => void;
  onContactUsClick: () => void;
}

export const LandingPagePreview: React.FC<LandingPagePreviewProps> = ({
  settings,
  sectionsData,
  supportedLanguages,
  onClickMyTickets,
  onClickLandingPageButton,
  onContactUsClick,
  translations,
}) => {
  const [currentLanguage, setCurrentLanguage] = useState('English');
  const onclickMenuCardPreview = () => {};
  const onClickPopularArticlePreview = () => {};
  const handleLanguageChange = () => {
    setCurrentLanguage('english');
  };
  const changeTheme = () => {};
  const { t }: { t: (key: string, options?: { returnObjects: boolean }) => any } = useTranslation();
  const menuListConfig = settings.data.menuCards;
  const menuListTranslations = translations.data.menuCards;
  const menuList = menuListConfig.map((menuCard: { id: string }) => ({
    ...menuCard,
    ...menuListTranslations.find(trans => trans.id === menuCard.id),
  }));

  const popularArticleList = t('data.popularArticles', { returnObjects: true }) as FaqArticle[];

  const newsArticlesTranslations = translations.data.newsArticles;
  const newsArticleListConfig = settings.data.newsArticles;
  const newsArticleList = newsArticleListConfig.map((article: { id: string }) => ({
    ...article, // Spread existing properties from config
    ...newsArticlesTranslations.find(trans => trans.id === article.id), // Override with translation if available
  }));

  const categoryHeadings = t('data.categoryHeadings', { returnObjects: true });
  const helperTexts = t('data.helperTexts', { returnObjects: true });
  return (
    <Box
      border="1px solid #ccc"
      borderRadius="8px"
      overflow="hidden"
      margin="auto"
      backgroundColor={settings.configs.primaryBgColor}
    >
      {/* Header */}
      {settings?.configs?.showHeader && (
        <Header
          showMainHeader={settings?.configs.showMainHeader}
          mainHeaderBgColor={settings?.configs.mainHeaderBgColor}
          mainHeaderTextColor={settings?.configs.mainHeaderTextColor}
          showSubHeader={settings?.configs.showSubHeader}
          subHeaderBgColor={settings?.configs.primaryBgColor}
          subHeaderTextColor={settings?.configs.primaryTextColor}
          showHeaderLogo={settings?.configs.showHeaderLogo}
          logoUrl={settings?.configs.headerlogoUrl}
          gameTitle={settings?.data.gameTitle}
          platforms={settings?.data.platforms}
          supportedLanguages={supportedLanguages}
          currentLanguage={currentLanguage}
          onLanguageChange={handleLanguageChange}
          horizontalPadding={{ base: '1', md: '8vw', lg: '12vw' }} // Responsive padding
          changeTheme={changeTheme}
          onClickMyTickets={onClickMyTickets}
          myTicketsButtonText={
            helperTexts.find((item: Record<string, string>) => 'myTicketsButtonText' in item)
              ?.myTicketsButtonText
          }
          loginButtonText={
            helperTexts.find((item: Record<string, string>) => 'loginButtonText' in item)
              ?.loginButtonText
          }
          logoWidth={settings?.configs?.headerLogoSize}
          onClickLandingPageButton={onClickLandingPageButton}
        />
      )}
      {settings?.configs.showBanner && (
        <BannerWithSearchBar
          searchBarPx={{
            base: '2vw',
            md: '1vw',
            lg: '7vw',
          }}
          isFullScreen={settings?.configs.isBannerFullScreen}
          px={{
            base: '2vw',
            md: '1vw',
            lg: settings?.configs.isBannerFullScreen ? '0vw' : '7vw',
          }} // set 15vw to column size and 0vw for full screen banner
          imageSrc={settings.configs.bannerImageUrl}
          altText="Banner image"
          bannerText="How can we help you?"
          placeholderText="Search for articles"
          placeholderFontSize={{ base: '18px', md: '20px', lg: '22px' }}
          placeholderColor={settings.configs.placeholderColor}
          searchBarTextColor={settings.configs.searchBarTextColor}
          showOverlay={settings?.configs.showOverlay}
          showSearchBar={settings?.configs.showSearchBar}
          bannerTextColor={settings?.configs.bannerTextColor}
        />
      )}
      <Box px={{ base: '2vw', md: '1vw', lg: '7vw' }}>
        {settings?.configs.showMenuCards && (
          <MenuCardList
            items={settings?.data.menuCards}
            itemsTranslations={menuList}
            menuCardsBgColor={settings.configs.secondaryBgColor}
            menuCradsTextColor={settings.configs.secondaryTextColor}
            onClickMenuCard={onclickMenuCardPreview}
          />
        )}
        {settings?.configs.showNewsSection && (
          <NewsSection
            newsArticles={settings?.data.newsArticles}
            newsArticlesTranslations={newsArticleList}
            newsArticleBgColor={settings.configs.primaryBgColor}
            newsArticleTextColor={settings.configs.primaryTextColor}
            newsSectionBgColor={settings.configs.secondaryBgColor}
            newsSectionHeadingTextColor={settings.configs.secondaryTextColor}
            paginationSelectedPageBgColor={settings.configs.paginationSelectedPageBgColor}
            paginationSelectedPageTextColor={settings.configs.paginationSelectedPageTextColor}
            newsSectionHeadingText={
              categoryHeadings.find((item: Record<string, string>) => 'newsArticlesHeading' in item)
                ?.newsArticlesHeading
            }
            newsSectionHelperTextView={
              helperTexts.find((item: Record<string, string>) => 'viewAll' in item)?.viewAll
            }
            newsSectionHelperTextCollapse={
              helperTexts.find((item: Record<string, string>) => 'collapse' in item)?.collapse
            }
          />
        )}
        {settings?.configs.showPopularArticles && (
          <PopularArticles
            faqArticles={settings?.data.popularArticles}
            faqArticleTranslations={popularArticleList}
            bgColor={settings.configs.primaryBgColor}
            faqArticlesTextColor={settings.configs.primaryTextColor}
            faqArticlesBgColor={settings.configs.secondaryBgColor}
            secondaryTextColor={settings?.configs.secondaryTextColor ?? ''}
            onClickPopularArticle={onClickPopularArticlePreview}
            faqArticlesHeading={
              categoryHeadings.find(
                (item: Record<string, string>) => 'popularArticlesHeading' in item,
              )?.popularArticlesHeading
            }
          />
        )}

        {/* section wise faq display */}
        {settings?.configs.showSectionGrid && (
          <SectionGrid
            textColor={settings?.configs.secondaryTextColor}
            sectionBgColor={settings?.configs.secondaryBgColor}
            sections={sectionsData}
            sectionIcons={settings?.data.sectionIcons}
            sectionsHeading={
              categoryHeadings.find((item: Record<string, string>) => 'categoriesHeading' in item)
                ?.categoriesHeading
            }
            sectionHelperText={
              helperTexts.find((item: Record<string, string>) => 'more' in item)?.more
            }
            sectionCardTextColor={settings?.configs.primaryTextColor}
          />
        )}
      </Box>
      {settings?.configs.showContactUsButton && (
        <ContactBtnSection
          variant="h1"
          color={settings.configs.contactUsButtonTextColor}
          buttonBgColor={settings.configs.contactUsButtonBgColor}
          needHelpText={settings.configs.needHelptext}
          needHelpTextColor={settings.configs.needHelpTextColor}
          onButtonClick={onContactUsClick}
          contactUsButtonText={
            helperTexts.find((item: Record<string, string>) => 'contactUsButtonText' in item)
              ?.contactUsButtonText
          }
        />
      )}
      {/* Footer */}
      {settings?.configs?.showFooter && (
        <Footer
          showFooterLogo={settings?.configs?.showFooterLogo}
          showFooterLinks={settings?.configs?.showFooterLinks}
          links={settings?.data?.footerLinks}
          imageSrc={settings?.configs?.footerLogoUrl}
          logoWidth={settings?.configs?.footerLogoSize}
          footerNote={settings?.data?.footerNote}
          footerBgColor={settings?.configs?.footerBackgroundColor}
          footerTextColor={settings?.configs?.footerTextColor}
        />
      )}
    </Box>
  );
};
