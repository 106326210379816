import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ArticleContent } from '../organisms/FaqPage/ArticleContent';
import { useSettings } from '../../stateManagement/SettingsContext';
import { Layout } from '../templates';
import { ContactBtnSection } from '../molecules/ContactUsBtnSection';
import { SearchBar } from '../organisms/SearchBar';
import { RelatedArticles } from '../organisms/FaqPage/RelatedArticles';
import { CustomText } from '../atoms';
import { appInsights } from '../../appInsights';

export const Faq: React.FC = () => {
  const { settings, faqs } = useSettings();
  const navigate = useNavigate();
  // Function to extract FAQ ID from the URL
  function extractFaqId(url: string): string | undefined {
    const match = url.match(/\/faq\/(\d+)-/); // Match digits before the dash
    return match ? match[1] : undefined; // Return the captured number
  }

  const url = window.location.pathname;
  const faqId = extractFaqId(url);

  // Extract language from the URL
  const langMatch = url.match(/\/article\/([^/]+)/);
  const language = langMatch ? langMatch[1] : '';

  // Use useMemo to fetch the FAQ data based on faqId and language
  const faqToDisplay = useMemo(() => {
    return faqId ? faqs?.find(faq => faq.publish_id === faqId) : null;
  }, [faqId, faqs]);

  // Extract article content and title, with fallback values
  const articleContent = faqToDisplay?.translations?.[language]?.body || 'No content available.';
  const articleTitle = faqToDisplay?.translations?.[language]?.title || 'No Title Available';
  const articleId = faqToDisplay?.id || 'No Id Available';
  const publishId = faqToDisplay?.publish_id || 'No Id Available';
  const sectionName = faqToDisplay?.section_translations?.[language]?.title || 'No Title Available';
  const lastUpdatedAt = faqToDisplay?.updated_at || '';
  const date = new Date(lastUpdatedAt);
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // Check if FAQ was found
  const notFound = !faqToDisplay || !articleContent;
  const { t }: { t: (key: string, options?: { returnObjects: boolean }) => any } = useTranslation();
  const helperTexts = t('data.helperTexts', { returnObjects: true });
  const categoryHeadings = t('data.categoryHeadings', { returnObjects: true });

  // Handle related article selection
  function onClickRelatedArticle(publish_id: string) {
    const faq = faqs?.find(item => item.publish_id === publish_id);
    if (faq) {
      const translation = faq.translations[language];
      if (translation) {
        const formattedTitle = translation.title.toLowerCase().replace(/\s+/g, '-');
        navigate(`/article/${language}/faq/${publish_id}-${formattedTitle}`);
        appInsights.trackEvent({
          name: 'landingPage',
          properties: {
            buttonName: 'relatedArticleTile',
            userName: 'anonymous',
            publishId: publish_id,
            articleTitle: formattedTitle,
            category: faq?.section_translations?.[language]?.title,
          },
        });
        // eslint-disable-next-line
        console.log('done');
      }
    }
  }

  // category: sectionToDisplay?.section_name, sectionName

  // Scroll to top when FAQ is found
  useEffect(() => {
    if (!notFound) {
      window.scrollTo(0, 0);
    }
  }, [notFound]);

  // Render the error page if FAQ is not found
  if (notFound) {
    return (
      <Box
        px={{ base: '2vw', md: '1vw', lg: '15vw' }}
        backgroundColor={settings?.configs.primaryBgColor}
        textAlign="center"
        py={8}
      >
        <Text fontSize="2xl" color={settings?.configs.secondaryTextColor}>
          404 - Article Not Found
        </Text>
        <Text fontSize="lg" color={settings?.configs.secondaryTextColor}>
          The requested article could not be found. Please check the URL or try again later.
        </Text>
      </Box>
    );
  }

  return (
    <Layout>
      <Box
        px={{ base: '2vw', md: '1vw', lg: '14vw' }}
        backgroundColor={settings?.configs.primaryBgColor}
        height="100%"
        py={{ base: '2vh', lg: '2vh' }}
        overflow="visible"
        borderTop="1px solid #ffffff"
      >
        <Flex width="100%" flexDirection={{ base: 'column', lg: 'row' }}>
          <Box flex="2">
            <SearchBar
              searchBarTextColor={settings?.configs.secondaryTextColor}
              searchBarPx={{
                base: '2vw',
                md: '1vw',
                lg: '0vw',
              }}
              placeholderText={
                categoryHeadings.find(
                  (item: Record<string, string>) => 'searchBarPlaceholderText' in item,
                )?.searchBarPlaceholderText || 'Search for Articles'
              }
            />
          </Box>
        </Flex>
        <Flex mt={8} gap={8} flexDirection={{ base: 'column', lg: 'row' }}>
          <Box flex="2" shadow="md">
            <ArticleContent
              title={articleTitle}
              lastUpdated={formattedDate}
              content={articleContent}
              textColor={settings?.configs.secondaryTextColor}
              bgColor={settings?.configs.secondaryBgColor}
              sectionName={sectionName}
              id={articleId}
              publishId={publishId}
              feedbackText={
                helperTexts.find((item: Record<string, string>) => 'feedbackText' in item)
                  ?.feedbackText
              }
            />
          </Box>
          <Box flex="1" backgroundColor={settings?.configs.primaryBgColor}>
            <Box mb={2}>
              <CustomText fontWeight="bold" variant="h1">
                {
                  categoryHeadings.find(
                    (item: Record<string, string>) => 'relatedArticlesHeading' in item,
                  )?.relatedArticlesHeading
                }
              </CustomText>
            </Box>
            <RelatedArticles
              bgColor={settings?.configs.secondaryBgColor}
              textColor={settings?.configs.secondaryTextColor}
              onClickRelatedArticle={publish_id => {
                onClickRelatedArticle(publish_id);
              }}
              faqId={faqId}
              faqTitle={articleTitle}
            />
          </Box>
        </Flex>
        <Box marginTop="5em">
          <ContactBtnSection
            color="#ffffff"
            onButtonClick={() => {
              navigate(`/contactUs`);
            }}
            variant={undefined}
            contactUsButtonText={
              helperTexts.find((item: Record<string, string>) => 'contactUsButtonText' in item)
                ?.contactUsButtonText
            }
            needHelpText={t('configs.needHelpText')}
          />
        </Box>
      </Box>
    </Layout>
  );
};
