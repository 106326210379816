import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout } from '../templates';
import { PlayerHub, PlayerHubTableField } from '../organisms/PlayerHub';
import { useSettings } from '../../stateManagement/SettingsContext';
import { TicketData } from '../organisms/TicketView';
import { useAuth } from '../../stateManagement/auth/useAuth';

export const PlayerHubPage: React.FC = () => {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const [ticketResponse, setTicketResponse] = useState<any>(null); // Initialize as null to check properly
  const [playerHubFields, setPlayerHubFields] = useState<PlayerHubTableField[]>([]);
  const { user } = useAuth();
  const username = user?.idTokenClaims?.name;
  const userId = user?.idTokenClaims?.epic_id;
  const fetchData = async () => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://catalysthc.ms.helpshift.live/api/issues?end-user-ids=${userId}&state=resolved;rejected`,
        headers: {},
      };

      const response = await axios.request(config);
      setTicketResponse(response.data); // Store only response.data, no need to store the whole response
    } catch (error) {
      // eslint-disable-next-line
      console.log('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Fetch data only once when the component mounts

  const transformData = (res: any): PlayerHubTableField[] => {
    return res.data.map((item: any) => ({
      ticketNumber: item.id,
      ticketSummary: item.title,
      status: item.state_data?.state || 'Unknown',
      date: item.updated_at
        ? new Date(item.updated_at).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: '2-digit',
          })
        : 'Unknown Date',
    }));
  };

  useEffect(() => {
    if (ticketResponse) {
      setPlayerHubFields(transformData(ticketResponse));
    }
  }, [ticketResponse]); // Transform data whenever ticketResponse changes

  useEffect(() => {}, [playerHubFields]);

  const transformTicketData = (data: any, issueId: number): TicketData | null => {
    if (!data) return null;

    const item = data.find((ticket: any) => ticket.id === issueId);
    if (!item) return null;

    return {
      ticketId: String(item.id),
      status: item.state_data?.state || 'Unknown',
      messages: item.messages
        ? item.messages
            .filter(
              (msg: any) =>
                !(
                  msg.origin === 'helpshift' &&
                  (msg.body === 'Bot Started' || msg.body === 'Bot Ended')
                ),
            )
            .map((msg: any) => ({
              sender: msg.origin === 'end-user' ? username || 'You' : msg.author.name,
              timestamp: msg.created_at ? new Date(msg.created_at).toISOString() : 'Unknown Time',
              message: msg.body ? msg.body.replace(/\n/g, '<br />') : 'No Message',
              origin: msg.origin,
            }))
        : [],
    };
  };

  const handleTicketClick = (issueId: number) => {
    const ticketData = transformTicketData(ticketResponse.data, issueId);
    if (!ticketData) {
      // eslint-disable-next-line
      console.log(`Ticket data not found for issue ID: ${issueId}`);
      return;
    }
    navigate(`/tickethistory/${issueId}`, { state: { ticketData } });
  };

  const { t }: { t: (key: string, options?: { returnObjects: boolean }) => any } = useTranslation();
  const helperTexts = t('data.helperTexts', { returnObjects: true });

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="76vh"
        backgroundColor={settings?.configs.primaryBgColor}
      >
        <Box flex={1}>
          <PlayerHub
            PlayerHubTableFields={playerHubFields}
            settings={settings ?? undefined}
            onTicketClick={handleTicketClick}
            emptyDataHelperText={
              helperTexts.find((item: Record<string, string>) => 'emptyDataHelperText' in item)
                ?.emptyDataHelperText
            }
          />
        </Box>
      </Box>
    </Layout>
  );
};
