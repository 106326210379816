// AdminPage.tsx
import React, { useEffect, useState } from 'react';
import { Box, Button, Spinner, Stack, useToast, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import crypto from 'crypto-js';
import { useLocation, useNavigate } from 'react-router-dom';
/* eslint-disable import/no-cycle */
import { FormField, useSettings } from '../../stateManagement/SettingsContext';
import { ColorSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/ColorSettingsMolecule';
import { HeaderSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/HeaderSettingsMolecule';
import {
  MenuCard,
  MenuCardSettingsOrganism,
} from '../molecules/AdminPageSettingsMolecules/MenuCardSettingsMolecule';
import { NewsSectionSettings } from '../molecules/AdminPageSettingsMolecules/NewsSectionSettingsMolecule';
import { FooterSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/FooterSettingsMolecule';
import { PopularArticlesSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/PopularArticlesSettingsMolecule';
import { LandingPagePreview } from './LandingPagePreview';
import { BannerSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/BannerSettingsMolecule';
import { SectionsSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/SectionsSettingsMolecule';
import { CustomButton } from '../atoms';
import { PlayerHubPagePreview } from './PlayerHubPagePreview';
import { TicketPagePreview } from './TicketPagePreview';
import { NewsArticle } from '../organisms/NewsSection';
import { FooterLink } from '../organisms/Footer';
import { TranslationData } from '../../interfaces';
import { TranslationsSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/TranslationsSettingsMolecule';
import { ContactUsPagePreview } from './ContactUsPagePreview';
import { ContactUsSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/ContactUsSettingsMolecule';

import { Loader } from '../organisms/Loader';

export const AdminPage: React.FC = () => {
  const {
    settings,
    setSettings,
    saveSettings,
    sectionsData,
    supportedLanguages,
    setLanguage,
    cifsData,
    contactUsFormData,
  } = useSettings();
  const toast = useToast();
  const { t } = useTranslation();
  const [localSettings, setLocalSettings] = useState(settings);
  const [contactUsData, setContactUsData] = useState<FormField[]>(contactUsFormData ?? []);
  const [cifData] = useState(cifsData);
  const [disableSaveSettings, setDisableSaveSettings] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [activeTab, setActiveTab] = useState<'branding' | 'data' | 'translations' | 'contactUs'>(
    'branding',
  );
  const [activeBrandingTab, setActiveBrandingTab] = useState<
    'color' | 'header' | 'banner' | 'footer'
  >('color');
  const [activeDataTab, setActiveDataTab] = useState<'menu' | 'news' | 'popular' | 'sections'>(
    'menu',
  );
  const [currentPage, setCurrentPage] = useState<'playerHub' | 'landing' | 'ticket' | 'contactUs'>(
    'landing',
  );
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const location = useLocation();
  const [adminUsername, setAdminUsername] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loaderError, setLoaderError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (activeTab === 'translations') {
      setDisableSaveSettings(true);
    } else {
      setDisableSaveSettings(false);
    }
  }, [activeTab]);

  function decrypt(encryptedText: any, key: string, iv: any) {
    const keyHex = crypto.enc.Hex.parse(key);
    const ivHex = iv ? crypto.enc.Hex.parse(iv) : undefined;
    const encryptedHex = crypto.enc.Hex.parse(encryptedText);

    const cipherParams = crypto.lib.CipherParams.create({
      ciphertext: encryptedHex,
      iv: ivHex,
      key: keyHex,
      algorithm: crypto.algo.AES,
    });

    const decrypted = crypto.AES.decrypt(cipherParams, keyHex, {
      iv: ivHex,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7,
    });
    return decrypted.toString(crypto.enc.Utf8);
  }

  useEffect(() => {
    const { data } = location.state || {}; // Destructure the state object
    if (data && data.adminIv && data.adminToken && data.adminTimestamp) {
      const { adminIv, adminTimestamp, adminToken } = data;
      setAdminUsername(data.adminUsername);
      const keyAauth = process.env.REACT_APP_ENCRYPTION_KEY || '';
      const decryptedToken = decrypt(adminToken, keyAauth, adminIv);
      if (decryptedToken === adminTimestamp.toString()) {
        setIsLoading(false);
      }
    } else {
      setLoaderError('This page is unreachable. Kindly login.');
      setIsLoading(false);
      navigate('/admin/login');
    }
  }, [location.state, navigate, adminUsername]);

  const initialTranslationData: TranslationData = {
    configs: {
      needHelpText: t('configs.needHelpText'),
    },
    data: {
      gameTitle: t('data.gameTitle'),
      platforms: t('data.platforms', { returnObjects: true }) as string[], // Ensure array
      newsArticles: t('data.newsArticles', { returnObjects: true }) as {
        id: string;
        title: string;
      }[],
      popularArticles: t('data.popularArticles', { returnObjects: true }) as {
        id: string;
        title: string;
        category: string;
      }[],
      menuCards: t('data.menuCards', { returnObjects: true }) as {
        id: string;
        title: string;
      }[],
      footerLinks: t('data.footerLinks', { returnObjects: true }) as {
        id: number;
        text: string;
      }[],
      sectionIcons: t('data.sectionIcons', { returnObjects: true }) as {
        id: string;
        name: string;
      }[],
      categoryHeadings: t('data.categoryHeadings', { returnObjects: true }) as {
        [key: string]: string;
      }[],
      helperTexts: t('data.helperTexts', { returnObjects: true }) as {
        [key: string]: string;
      }[],
      contactUsFormData: t('data.contactUsFormData', { returnObjects: true }) as {
        id: string;
        question: string;
        description?: string;
        placeholder?: string;
        options?: string;
      }[],
      footerNote: t('data.footerNote'),
    },
  };

  const [translationData, setTranslationData] = useState<TranslationData>(initialTranslationData);

  useEffect(() => {
    if (selectedLanguage !== 'en') {
      i18n
        .changeLanguage('en')
        .then(() => {
          setSelectedLanguage('en');
          setLanguage('en');
          setTranslationData({
            configs: {
              needHelpText: t('configs.needHelpText'),
            },
            data: {
              gameTitle: t('data.gameTitle'),
              platforms: t('data.platforms', { returnObjects: true }) as string[],
              newsArticles: t('data.newsArticles', { returnObjects: true }) as {
                id: string;
                title: string;
              }[],
              popularArticles: t('data.popularArticles', { returnObjects: true }) as {
                id: string;
                title: string;
                category: string;
              }[],
              menuCards: t('data.menuCards', { returnObjects: true }) as {
                id: string;
                title: string;
              }[],
              footerLinks: t('data.footerLinks', { returnObjects: true }) as {
                id: number;
                text: string;
              }[],
              sectionIcons: t('data.sectionIcons', { returnObjects: true }) as {
                id: string;
                name: string;
              }[],
              categoryHeadings: t('data.categoryHeadings', { returnObjects: true }) as {
                [key: string]: string;
              }[],
              helperTexts: t('data.helperTexts', { returnObjects: true }) as {
                [key: string]: string;
              }[],
              contactUsFormData: t('data.contactUsFormData', { returnObjects: true }) as {
                id: string;
                question: string;
                description?: string;
                placeholder?: string;
                options?: string;
              }[],
              footerNote: t('data.footerNote'),
            },
          });
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log('Error changing language:', err);
        });
    }
  }, []);

  const handleTranslationDataChange: (
    section: keyof TranslationData,
    key: string,
    value: any,
  ) => void = (section, key, value) => {
    setTranslationData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [key]: value,
      },
    }));
  };

  const newsCards: NewsArticle[] = translationData.data.newsArticles.map(translatedArticle => {
    const matchingLocalArticle = localSettings?.data.newsArticles.find(
      localArticle => localArticle.id === translatedArticle.id,
    );

    return {
      ...(matchingLocalArticle ?? ''), // Fallback values
      ...(translatedArticle ?? ''), // Overwrite with translations
      date: matchingLocalArticle?.date ?? '', // Ensure date is always a string
      imageUrl: matchingLocalArticle?.imageUrl ?? '', // Ensure imageUrl is always a string
      destinationLink: matchingLocalArticle?.destinationLink ?? '', // Ensure destinationLink is always a string
    };
  });

  const translatedFooterLinks: FooterLink[] =
    settings?.data?.footerLinks.map(link => {
      const translation = translationData.data.footerLinks.find(i => i.id === link.id);
      return {
        ...link,
        text: translation ? translation.text : link.text, // Use translated text if available
      };
    }) || [];

  const menuCards: MenuCard[] = translationData.data.menuCards.map(translatedCard => {
    const matchingLocalCard = localSettings?.data.menuCards.find(
      localCard => localCard.id === translatedCard.id,
    );

    return {
      ...matchingLocalCard, // Spread properties from settings (local data)
      ...translatedCard, // Override with translated title
      menuCardLink: matchingLocalCard?.menuCardLink ?? '', // Ensure menuCardLink is always a string
      icon: matchingLocalCard?.icon ?? '',
    };
  });

  const handleNewsArticleChange = (updatedNewsArticles: NewsArticle[]) => {
    setLocalSettings(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        data: {
          ...prev.data,
          newsArticles: updatedNewsArticles.map(article => {
            // Update title separately in translations
            handleTranslationDataChange('data', 'newsArticles', updatedNewsArticles);
            return article; // Update everything else in localSettings
          }),
        },
      };
    });
  };

  const handleFooterLinksSettingsChange = (updatedFooterLinks: FooterLink[]) => {
    setLocalSettings(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        data: {
          ...prev.data,
          footerLinks: updatedFooterLinks.map(footerLinks => {
            // Update title separately in translations
            handleTranslationDataChange('data', 'footerLinks', updatedFooterLinks);
            return footerLinks; // Update everything else in localSettings
          }),
        },
      };
    });
  };
  const handleFooterTextSettingsChange = (
    category: keyof TranslationData,
    updatedTextKey: string,
    updatedTextValue: string,
  ) => {
    setLocalSettings(prev => {
      if (!prev) return prev;
      const updatedSettings = {
        ...prev,
        [category]: {
          ...prev.data,
          [updatedTextKey]: updatedTextValue,
        },
      };
      // Send updated settings to translation handler
      handleTranslationDataChange(category, updatedTextKey, updatedTextValue);
      return updatedSettings;
    });
  };

  const handleSave = async () => {
    if (!localSettings) return;
    setIsSaving(true);

    try {
      setSettings(localSettings);
      setTranslationData(translationData);
      await saveSettings(localSettings, translationData, contactUsData);
      toast({
        title: 'Settings saved successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch {
      toast({
        title: 'Error saving settings',
        description: 'Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleConfigChange = (key: string, value: any) => {
    setLocalSettings(prev => ({
      ...prev!,
      configs: { ...prev!.configs, [key]: value },
    }));
  };

  const onClickMyTickets = (): void => {
    setCurrentPage('playerHub');
  };
  const onClickLandingPageButton = (): void => {
    setCurrentPage('landing');
  };
  const onTicketClick = (): void => {
    setCurrentPage('ticket');
  };
  const onContactUsClick = (): void => {
    setCurrentPage('contactUs');
  };

  const handleMenuCardChange = (updatedMenuCards: any) => {
    setLocalSettings(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        data: {
          ...prev.data,
          menuCards: updatedMenuCards.map((menuCard: any) => {
            handleTranslationDataChange('data', 'menuCards', updatedMenuCards);
            return menuCard;
          }),
        },
      };
    });
  };

  const handleContactUsFormDataChange = (updatedContactUsFormData: FormField[]) => {
    setContactUsData(updatedContactUsFormData); // Directly update state with new datas
    handleTranslationDataChange('data', 'contactUsFormData', updatedContactUsFormData);
  };

  const handlePopularFaqArticleChange = (updatedPopularFaqArticles: any) => {
    setLocalSettings(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        data: {
          ...prev.data,
          popularArticles: updatedPopularFaqArticles.map((article: any) => {
            handleTranslationDataChange('data', 'popularArticles', updatedPopularFaqArticles);
            return article;
          }),
        },
      };
    });
  };

  const handleSectionIconsChange = (updatedSectionIcons: any) => {
    setLocalSettings(prev => ({
      ...prev!,
      data: { ...prev!.data, sectionIcons: updatedSectionIcons },
    }));
  };

  const convertImageToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // Base64 encoded string or ArrayBuffer
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  if (!localSettings) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p={4}>
      {loaderError ? (
        <div className="error-message-rp">{loaderError}</div>
      ) : (
        <>
          {isLoading && <Loader />}
          <div id="outer" className={isLoading ? 'blurred' : ''}>
            <Flex direction={['column', 'row']} gap={6}>
              <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm" width={['100%', '33%']}>
                <Flex mb={4} gap={4}>
                  <Box>
                    <CustomButton
                      onClick={() => setActiveTab('branding')}
                      bg="transparent"
                      color={activeTab === 'branding' ? 'blue.500' : 'gray.500'}
                      _hover={{ bg: 'transparent', color: 'blue.600' }}
                      fontWeight="bold"
                    >
                      Branding
                    </CustomButton>
                    {activeTab === 'branding' && <Box height="2px" bg="blue.500" />}
                  </Box>

                  <Box>
                    <CustomButton
                      onClick={() => setActiveTab('data')}
                      bg="transparent"
                      color={activeTab === 'data' ? 'blue.500' : 'gray.500'}
                      _hover={{ bg: 'transparent', color: 'blue.600' }}
                      fontWeight="bold"
                    >
                      Data
                    </CustomButton>
                    {activeTab === 'data' && <Box height="2px" bg="blue.500" />}
                  </Box>
                  <Box>
                    <CustomButton
                      onClick={() => setActiveTab('translations')}
                      bg="transparent"
                      color={activeTab === 'translations' ? 'blue.500' : 'gray.500'}
                      _hover={{ bg: 'transparent', color: 'blue.600' }}
                      fontWeight="bold"
                    >
                      Translations
                    </CustomButton>
                    {activeTab === 'translations' && <Box height="2px" bg="blue.500" />}
                  </Box>
                  <Box>
                    <CustomButton
                      onClick={() => {
                        setActiveTab('contactUs');
                        setCurrentPage('contactUs');
                      }}
                      bg="transparent"
                      color={activeTab === 'contactUs' ? 'blue.500' : 'gray.500'}
                      _hover={{ bg: 'transparent', color: 'blue.600' }}
                      fontWeight="bold"
                    >
                      Contact Us Form
                    </CustomButton>
                    {activeTab === 'contactUs' && <Box height="2px" bg="blue.500" />}
                  </Box>
                </Flex>

                {/* Sub Tabs */}
                {activeTab === 'branding' && (
                  <>
                    <Flex mb={4} justify="space-between">
                      <CustomButton
                        onClick={() => setActiveBrandingTab('color')}
                        color={activeBrandingTab === 'color' ? 'blue.500' : 'gray.500'}
                        bg="transparent"
                        fontSize="sm"
                      >
                        Color
                      </CustomButton>
                      <CustomButton
                        onClick={() => setActiveBrandingTab('header')}
                        color={activeBrandingTab === 'header' ? 'blue.500' : 'gray.500'}
                        bg="transparent"
                        fontSize="sm"
                      >
                        Header
                      </CustomButton>
                      <CustomButton
                        onClick={() => setActiveBrandingTab('banner')}
                        color={activeBrandingTab === 'banner' ? 'blue.500' : 'gray.500'}
                        bg="transparent"
                        fontSize="sm"
                      >
                        Banner
                      </CustomButton>
                      <CustomButton
                        onClick={() => setActiveBrandingTab('footer')}
                        color={activeBrandingTab === 'footer' ? 'blue.500' : 'gray.500'}
                        bg="transparent"
                        fontSize="sm"
                      >
                        Footer
                      </CustomButton>
                    </Flex>

                    <Stack spacing={6}>
                      {activeBrandingTab === 'color' && (
                        <ColorSettingsMolecule
                          configs={localSettings.configs}
                          onConfigChange={handleConfigChange}
                        />
                      )}
                      {activeBrandingTab === 'header' && (
                        <HeaderSettingsMolecule
                          configs={localSettings.configs}
                          onConfigChange={handleConfigChange}
                          convertImageToBase64={convertImageToBase64}
                        />
                      )}
                      {activeBrandingTab === 'banner' && (
                        <BannerSettingsMolecule
                          configs={localSettings.configs}
                          onConfigChange={handleConfigChange}
                          convertImageToBase64={convertImageToBase64}
                        />
                      )}
                      {activeBrandingTab === 'footer' && (
                        <FooterSettingsMolecule
                          configs={localSettings.configs}
                          onConfigChange={handleConfigChange}
                          convertImageToBase64={convertImageToBase64}
                          translatedFooterLinks={translatedFooterLinks}
                          handleFooterLinksSettingsChange={handleFooterLinksSettingsChange}
                          handleFooterTextSettingsChange={handleFooterTextSettingsChange}
                          translation={translationData}
                        />
                      )}
                    </Stack>
                  </>
                )}

                {activeTab === 'data' && (
                  <>
                    <Flex mb={4} justify="space-between">
                      <CustomButton
                        onClick={() => setActiveDataTab('menu')}
                        color={activeDataTab === 'menu' ? 'blue.500' : 'gray.500'}
                        bg="transparent"
                        fontSize="sm"
                      >
                        Menu Cards
                      </CustomButton>
                      <CustomButton
                        onClick={() => setActiveDataTab('news')}
                        color={activeDataTab === 'news' ? 'blue.500' : 'gray.500'}
                        bg="transparent"
                        fontSize="sm"
                      >
                        News
                      </CustomButton>
                      <CustomButton
                        onClick={() => setActiveDataTab('popular')}
                        color={activeDataTab === 'popular' ? 'blue.500' : 'gray.500'}
                        bg="transparent"
                        fontSize="sm"
                      >
                        Popular
                      </CustomButton>
                      <CustomButton
                        onClick={() => setActiveDataTab('sections')}
                        color={activeDataTab === 'sections' ? 'blue.500' : 'gray.500'}
                        bg="transparent"
                        fontSize="sm"
                      >
                        Sections
                      </CustomButton>
                    </Flex>

                    <Stack spacing={6}>
                      {activeDataTab === 'menu' && (
                        <MenuCardSettingsOrganism
                          configs={localSettings.configs}
                          onConfigChange={handleConfigChange}
                          menuCards={menuCards}
                          onMenuCardChange={handleMenuCardChange}
                        />
                      )}
                      {activeDataTab === 'news' && (
                        <NewsSectionSettings
                          configs={localSettings.configs}
                          onConfigChange={handleConfigChange}
                          newsCards={newsCards}
                          onNewsArticleChange={handleNewsArticleChange}
                        />
                      )}
                      {activeDataTab === 'popular' && (
                        <PopularArticlesSettingsMolecule
                          configs={localSettings.configs}
                          data={localSettings.data}
                          onConfigChange={handleConfigChange}
                          sectionsData={sectionsData}
                          onSelectionChange={handlePopularFaqArticleChange}
                        />
                      )}
                      {activeDataTab === 'sections' && (
                        <SectionsSettingsMolecule
                          configs={localSettings.configs}
                          onConfigChange={handleConfigChange}
                          sectionIcons={localSettings.data.sectionIcons}
                          onSectionIconChange={handleSectionIconsChange}
                        />
                      )}
                    </Stack>
                  </>
                )}

                {activeTab === 'translations' && (
                  <TranslationsSettingsMolecule
                    supportedLanguages={supportedLanguages}
                    translationData={initialTranslationData}
                  />
                )}
                {activeTab === 'contactUs' && (
                  <ContactUsSettingsMolecule
                    cifs={cifData}
                    contactUsFormData={contactUsData}
                    onFormCardChange={handleContactUsFormDataChange}
                  />
                )}
              </Box>

              <Box width={['100%', '65%']}>
                {!disableSaveSettings && (
                  <Button onClick={handleSave} isLoading={isSaving} colorScheme="blue" size="sm">
                    Save Settings
                  </Button>
                )}

                <Box
                  bg="gray.100"
                  p={4}
                  borderRadius="md"
                  boxShadow="sm"
                  width="100%"
                  overflowY="scroll"
                  height="100vh"
                >
                  {currentPage === 'playerHub' && (
                    <PlayerHubPagePreview
                      settings={localSettings}
                      onTicketClick={onTicketClick}
                      supportedLanguages={supportedLanguages}
                      onClickMyTickets={onClickMyTickets}
                      onClickLandingPageButton={onClickLandingPageButton}
                    />
                  )}
                  {currentPage === 'landing' && (
                    <LandingPagePreview
                      settings={localSettings}
                      translations={translationData}
                      sectionsData={sectionsData}
                      supportedLanguages={supportedLanguages}
                      onClickMyTickets={onClickMyTickets}
                      onClickLandingPageButton={onClickLandingPageButton}
                      onContactUsClick={onContactUsClick}
                    />
                  )}
                  {currentPage === 'ticket' && (
                    <TicketPagePreview
                      settings={localSettings}
                      supportedLanguages={supportedLanguages}
                      onClickMyTickets={onClickMyTickets}
                      onClickLandingPageButton={onClickLandingPageButton}
                    />
                  )}

                  {currentPage === 'contactUs' && (
                    <ContactUsPagePreview
                      settings={localSettings}
                      contactUsFormData={contactUsData}
                      supportedLanguages={supportedLanguages}
                      onClickMyTickets={onClickMyTickets}
                      onClickLandingPageButton={onClickLandingPageButton}
                    />
                  )}
                </Box>
              </Box>
            </Flex>
          </div>
        </>
      )}
    </Box>
  );
};
