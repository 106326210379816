import {
  Menu,
  useDisclosure,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Input,
  Box,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';
import { useState, useRef, useEffect } from 'react';
import tinycolor from 'tinycolor2';

interface DropdownProps {
  label: string;
  items?: { label: string; value: string }[];
  leftIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  onItemClick: (value: string) => void;
  textColor?: string;
  dropdownBgColor?: string;
  value: string;
  variant?: string;
}

export const CustomDropdownWithSearchBar: React.FC<DropdownProps> = ({
  value,
  label,
  items = [],
  leftIcon,
  onItemClick,
  textColor = 'black',
  dropdownBgColor = 'white',
  variant = 'outline',
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus(); // Auto-focus input when dropdown opens
    }
  }, [isOpen]);

  const filteredItems = items.filter(item =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton
        as={Button}
        color={textColor}
        leftIcon={leftIcon}
        variant={variant}
        onClick={isOpen ? onClose : onOpen} // Toggle open/close
        rightIcon={isOpen ? <FaChevronUp /> : <FaChevronDown />} // Show up icon if open, down if closed
        ref={buttonRef}
      >
        {value ? items.find(item => item.value === value)?.label || label : label}
      </MenuButton>

      <MenuList
        backgroundColor={dropdownBgColor}
        width={buttonRef.current ? `${buttonRef.current.offsetWidth}px` : '200px'}
        maxH="300px"
        overflowY="auto"
        p={0}
        borderRadius="md"
        boxShadow="lg"
      >
        {/* Search Input inside MenuList */}
        <Box p={2} borderBottom="1px solid #E2E8F0">
          <Flex align="center" gap={2} bg="gray.100" borderRadius="md" p={2}>
            <Icon as={FaSearch} color="gray.500" />
            <Input
              ref={inputRef}
              placeholder="Search..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              size="sm"
              border="none"
              bg="transparent"
              _focus={{ outline: 'none' }}
            />
          </Flex>
        </Box>

        {/* Dropdown Items */}
        <Box maxH="250px" overflowY="auto">
          {filteredItems.length > 0 ? (
            filteredItems.map(item => (
              <MenuItem
                key={item.value}
                onClick={() => {
                  onItemClick(item.value);
                  setSearchTerm('');
                  onClose();
                }}
                backgroundColor={dropdownBgColor}
                _hover={{
                  backgroundColor: tinycolor(dropdownBgColor).lighten(10).toString(),
                }}
                textColor={textColor}
              >
                {item.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem isDisabled textColor={textColor} opacity={0.6}>
              No results found
            </MenuItem>
          )}
        </Box>
      </MenuList>
    </Menu>
  );
};
