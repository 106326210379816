import { useState } from 'react';
import {
  Box,
  HStack,
  Stack,
  Text,
  Center,
  Alert,
  AlertIcon,
  CloseButton,
  AlertTitle,
  Flex,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { CustomButton } from '../../atoms';
import { CustomDropdownWithSearchBar } from './CustomDropdownWithSearchBar';
import { TranslationData } from '../../../interfaces';

const allLanguages: Record<string, string> = {
  af: 'Afrikaans',
  sq: 'Albanian',
  am: 'Amharic',
  ar: 'Arabic',
  hy: 'Armenian',
  as: 'Assamese',
  az: 'Azerbaijani (Latin)',
  bn: 'Bangla',
  ba: 'Bashkir',
  eu: 'Basque',
  bho: 'Bhojpuri',
  brx: 'Bodo',
  bs: 'Bosnian (Latin)',
  bg: 'Bulgarian',
  yue: 'Cantonese (Traditional)',
  ca: 'Catalan',
  lzh: 'Chinese (Literary)',
  'zh-Hans': 'Chinese Simplified',
  'zh-Hant': 'Chinese Traditional',
  sn: 'chiShona',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  prs: 'Dari',
  dv: 'Divehi',
  doi: 'Dogri',
  nl: 'Dutch',
  en: 'English',
  et: 'Estonian',
  fo: 'Faroese',
  fj: 'Fijian',
  fil: 'Filipino',
  fi: 'Finnish',
  fr: 'French',
  'fr-ca': 'French (Canada)',
  gl: 'Galician',
  ka: 'Georgian',
  de: 'German',
  el: 'Greek',
  gu: 'Gujarati',
  ht: 'Haitian Creole',
  ha: 'Hausa',
  he: 'Hebrew',
  hi: 'Hindi',
  mww: 'Hmong Daw (Latin)',
  hu: 'Hungarian',
  is: 'Icelandic',
  ig: 'Igbo',
  id: 'Indonesian',
  ikt: 'Inuinnaqtun',
  iu: 'Inuktitut',
  'iu-Latn': 'Inuktitut (Latin)',
  ga: 'Irish',
  it: 'Italian',
  ja: 'Japanese',
  kn: 'Kannada',
  ks: 'Kashmiri',
  kk: 'Kazakh',
  km: 'Khmer',
  rw: 'Kinyarwanda',
  'tlh-Latn': 'Klingon',
  'tlh-Piqd': 'Klingon (plqaD)',
  gom: 'Konkani',
  ko: 'Korean',
  ku: 'Kurdish (Central)',
  kmr: 'Kurdish (Northern)',
  ky: 'Kyrgyz (Cyrillic)',
  lo: 'Lao',
  lv: 'Latvian',
  lt: 'Lithuanian',
  ln: 'Lingala',
  dsb: 'Lower Sorbian',
  lug: 'Luganda',
  mk: 'Macedonian',
  mai: 'Maithili',
  mg: 'Malagasy',
  ms: 'Malay (Latin)',
  ml: 'Malayalam',
  mt: 'Maltese',
  mi: 'Maori',
  mr: 'Marathi',
  'mn-Cyrl': 'Mongolian (Cyrillic)',
  'mn-Mong': 'Mongolian (Traditional)',
  my: 'Myanmar',
  ne: 'Nepali',
  nb: 'Norwegian Bokmål',
  nya: 'Nyanja',
  or: 'Odia',
  ps: 'Pashto',
  fa: 'Persian',
  pl: 'Polish',
  pt: 'Portuguese (Brazil)',
  'pt-pt': 'Portuguese (Portugal)',
  pa: 'Punjabi',
  otq: 'Queretaro Otomi',
  ro: 'Romanian',
  run: 'Rundi',
  ru: 'Russian',
  sm: 'Samoan (Latin)',
  'sr-Cyrl': 'Serbian (Cyrillic)',
  'sr-Latn': 'Serbian (Latin)',
  st: 'Sesotho',
  nso: 'Sesotho sa Leboa',
  tn: 'Setswana',
  sd: 'Sindhi',
  si: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  so: 'Somali (Arabic)',
  es: 'Spanish',
  sw: 'Swahili (Latin)',
  sv: 'Swedish',
  ty: 'Tahitian',
  ta: 'Tamil',
  tt: 'Tatar (Latin)',
  te: 'Telugu',
  th: 'Thai',
  bo: 'Tibetan',
  ti: 'Tigrinya',
  to: 'Tongan',
  tr: 'Turkish',
  tk: 'Turkmen (Latin)',
  uk: 'Ukrainian',
  hsb: 'Upper Sorbian',
  ur: 'Urdu',
  ug: 'Uyghur (Arabic)',
  uz: 'Uzbek (Latin)',
  vi: 'Vietnamese',
  cy: 'Welsh',
  xh: 'Xhosa',
  yo: 'Yoruba',
  yua: 'Yucatec Maya',
  zu: 'Zulu',
};

interface TranslationsSettingsMoleculeProps {
  urls?: { [key: string]: string }[];
  supportedLanguages: { label: string; value: string }[];
  translationData: TranslationData;
}

const BLOB_CONTAINER_NAME = process.env.REACT_APP_BLOB_CONTAINER_NAME || '';

export const TranslationsSettingsMolecule: React.FC<TranslationsSettingsMoleculeProps> = ({
  supportedLanguages,
  translationData,
}) => {
  const addLanguageDropdownItems = Object.entries(allLanguages)
    .map(([code, language]) => ({
      label: language,
      value: code,
    }))
    .filter(item => !supportedLanguages.some(lang => lang.value === item.value));
  const deleteLanguageDropdownItems = supportedLanguages.map(({ value, label }) => ({
    label,
    value,
  }));

  const toast = useToast();
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);
  const [isAddLanguageVisible, setIsAddLanguageVisible] = useState(false);
  const [isDeleteLanguageVisible, setIsDeleteLanguageVisible] = useState(false);
  const [addSelectedLanguage, setAddSelectedLanguage] = useState<string>('');
  const [addSelectedLanguageLabel, setAddSelectedLanguageLabel] = useState<string>('');
  const [deleteSelectedLanguage, setDeleteSelectedLanguage] = useState<string>('');

  const toggleAddLanguageSection = () => {
    setIsAddLanguageVisible(true);
    setIsDeleteLanguageVisible(false);
  };
  const toggleDeleteLanguageSection = () => {
    setIsDeleteLanguageVisible(true);
    setIsAddLanguageVisible(false);
  };
  const handleAddLanguageDropdownItemClick = (value: string) => {
    setAddSelectedLanguage(value);
    const label = allLanguages[value] || '';
    setAddSelectedLanguageLabel(label);
  };
  const handleDeleteLanguageDropdownItemClick = (value: string) => {
    setDeleteSelectedLanguage(value);
  };
  const handleAddLanguage = async () => {
    // CALL TO ADD LANGUAGE FN
    setDisableAddButton(true);
    const languageCode = addSelectedLanguage;
    const languageLabel = addSelectedLanguageLabel;
    const translationUrl = `https://catalysthc.ms.helpshift.live/api/lang-translation?lang=${languageCode}&path=${BLOB_CONTAINER_NAME}/translations`;
    const translationConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: translationUrl,
      headers: {},
      data: translationData,
    };
    try {
      const response = await axios.request(translationConfig);
      if (response.status === 200) {
        supportedLanguages.push({ label: languageLabel, value: languageCode });
        const supportedLanguagesData = supportedLanguages.reduce<Record<string, string>>(
          (acc, lang) => {
            return { ...acc, [lang.value]: lang.label };
          },
          {},
        );
        try {
          const data = JSON.stringify([
            {
              name: 'supportedLanguages.json',
              type: 'json',
              path: BLOB_CONTAINER_NAME,
              data: JSON.stringify(supportedLanguagesData, null, 2),
            },
          ]);
          const configData = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://catalysthc.ms.helpshift.live/api/fe-config',
            // url: '',
            headers: {
              'Content-Type': 'application/json',
            },
            data,
          };
          const configResponse = await axios.request(configData);
          if (configResponse.status === 200) {
            toast({
              title: 'New language saved successfully!',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: 'Error saving new language',
              description: 'Please try again.',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: 'Error saving new language',
            description: 'Please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: 'Error saving new language',
          description: 'Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error saving new language',
        description: 'Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setDisableAddButton(false); // Re-enable the button in both success and error cases
    }
  };
  const handleDeleteLanguage = async () => {
    setDisableDeleteButton(true);
    const newSupportedLanguages = supportedLanguages.filter(
      lang => lang.value !== deleteSelectedLanguage,
    );
    const supportedLanguagesData = newSupportedLanguages.reduce<Record<string, string>>(
      (acc, lang) => {
        return { ...acc, [lang.value]: lang.label };
      },
      {},
    );
    try {
      const data = JSON.stringify([
        {
          name: 'supportedLanguages.json',
          type: 'json',
          path: BLOB_CONTAINER_NAME,
          data: JSON.stringify(supportedLanguagesData, null, 2),
        },
      ]);
      const configData = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://catalysthc.ms.helpshift.live/api/fe-config',
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      };
      const configResponse = await axios.request(configData);
      if (configResponse.status === 200) {
        toast({
          title: `Language ${deleteSelectedLanguage} deleted successfully!`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: `Errror deleting ${deleteSelectedLanguage} language`,
          description: 'Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: `Errror deleting ${deleteSelectedLanguage} language`,
        description: 'Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setDisableDeleteButton(false);
    }
  };
  return (
    <Box height="80vh" overflowY="auto">
      <Stack spacing={4}>
        <HStack spacing={4} mb={4}>
          <CustomButton colorScheme="green" onClick={toggleAddLanguageSection}>
            Add Language
          </CustomButton>
          <CustomButton colorScheme="red" onClick={toggleDeleteLanguageSection}>
            Delete Existing Language
          </CustomButton>
        </HStack>

        {/* Add Game Setting Section */}
        {isAddLanguageVisible && (
          <Box p={4} borderWidth={1} borderRadius="md" display="flex" flexDirection="column">
            <Box mb={2}>
              <Text fontSize="sm" fontWeight="bold" color="gray.700">
                Languages already supported:
              </Text>
              <Flex wrap="wrap" mt={1} gap={2}>
                {supportedLanguages.length > 0 ? (
                  supportedLanguages.map(lang => (
                    <Box
                      key={lang.value}
                      px={3}
                      py={1}
                      borderRadius="md"
                      backgroundColor="gray.100"
                      fontSize="sm"
                    >
                      {lang.label}
                    </Box>
                  ))
                ) : (
                  <Text fontSize="sm" color="gray.500">
                    No languages added yet.
                  </Text>
                )}
              </Flex>
            </Box>
            <CustomDropdownWithSearchBar
              value={addSelectedLanguage}
              label={
                addSelectedLanguage ? allLanguages[addSelectedLanguage] : 'Select Language To Add'
              }
              items={addLanguageDropdownItems}
              onItemClick={handleAddLanguageDropdownItemClick}
            />
            {addSelectedLanguage && (
              <>
                <CustomButton
                  colorScheme="blue"
                  onClick={handleAddLanguage}
                  marginTop="1rem"
                  isLoading={disableAddButton}
                >
                  Add {allLanguages[addSelectedLanguage]} to Game Selection Page
                </CustomButton>
                <Text fontSize="sm" color="gray.500" mt={2}>
                  Note: This action will immediately add {' "'}
                  {allLanguages[addSelectedLanguage]}
                  {'" '} to helpcenter without any further confirmation.
                </Text>
              </>
            )}
          </Box>
        )}
        {isDeleteLanguageVisible && (
          <Box p={4} borderWidth={1} borderRadius="md" display="flex" flexDirection="column">
            <Box mb={2}>
              <Text fontSize="sm" fontWeight="bold" color="gray.700">
                Languages already supported:
              </Text>
              <Flex wrap="wrap" mt={1} gap={2}>
                {supportedLanguages.length > 0 ? (
                  supportedLanguages.map(lang => (
                    <Box
                      key={lang.value}
                      px={3}
                      py={1}
                      borderRadius="md"
                      backgroundColor="gray.100"
                      fontSize="sm"
                    >
                      {lang.label}
                    </Box>
                  ))
                ) : (
                  <Text fontSize="sm" color="gray.500">
                    No languages deleted yet.
                  </Text>
                )}
              </Flex>
            </Box>
            <CustomDropdownWithSearchBar
              value={deleteSelectedLanguage}
              label={
                deleteSelectedLanguage
                  ? supportedLanguages.find(lang => lang.label === deleteSelectedLanguage)?.label ||
                    'Select Language To Delete'
                  : 'Select Language To Delete'
              }
              items={deleteLanguageDropdownItems}
              onItemClick={handleDeleteLanguageDropdownItemClick}
            />
            {deleteSelectedLanguage && (
              <>
                <CustomButton
                  colorScheme="blue"
                  onClick={handleDeleteLanguage}
                  marginTop="1rem"
                  isLoading={disableDeleteButton}
                >
                  Delete{' '}
                  {supportedLanguages.find(lang => lang.value === deleteSelectedLanguage)?.label}{' '}
                  from Game Selection Page
                </CustomButton>
                <Text fontSize="sm" color="gray.500" mt={2}>
                  Note: This action will immediately delete{' "'}
                  {supportedLanguages.find(lang => lang.value === deleteSelectedLanguage)?.label}
                  {'" '}
                  without any further confirmation.
                </Text>
              </>
            )}
          </Box>
        )}
      </Stack>
      {alertMessage && (
        <Center
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="1000"
        >
          <Alert status="error" variant="solid" borderRadius="md" boxShadow="lg">
            <AlertIcon />
            <Box>
              <AlertTitle>Error</AlertTitle>
              {alertMessage}
            </Box>
            <CloseButton
              onClick={() => setAlertMessage(null)}
              position="absolute"
              right="8px"
              top="8px"
            />
          </Alert>
        </Center>
      )}
    </Box>
  );
};
