import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // Make sure this is used
  .init({
    debug: true,
    lng: 'en', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_CDN_URL}/translations/{{lng}}.json?v=${Date.now()}`,
    },
  });

export const updateI18nLanguage = (language: string) => {
  i18n.changeLanguage(language); // Dynamically update the language
};

// eslint-disable-next-line import/no-default-export
export default i18n;
