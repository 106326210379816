import React, { ReactNode, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Footer, FooterLink } from '../organisms/Footer';
import { Header } from '../organisms';
import { useSettings } from '../../stateManagement/SettingsContext';
import { appInsights } from '../../appInsights';
import i18n from '../../i18n';
import { Loader } from '../organisms/Loader';

interface LayoutProps {
  children: ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const navigate = useNavigate();

  const { settings, setSettings, language, supportedLanguages, setLanguage } = useSettings();
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [settingsCopy, setSettingsCopy] = useState<typeof settings | null>(null); // Store the original settings
  const { t }: { t: (key: string, options?: { returnObjects: boolean }) => any } = useTranslation();
  const translations = t('data.footerLinks', { returnObjects: true }) as FooterLink[];
  const helperTexts = t('data.helperTexts', { returnObjects: true });
  const handleLanguageChange = (newLanguage: string) => {
    const languageCode = supportedLanguages.find(lang => lang.label === newLanguage)?.value || 'en';
    i18n
      .changeLanguage(languageCode) // Change language in i18n instance
      .then(() => {
        const selectedLang =
          supportedLanguages.find(lang => lang.label === newLanguage)?.label || newLanguage;
        setCurrentLanguage(selectedLang);
        setLanguage(languageCode);
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log('Error changing language:', err);
      });

    appInsights.trackEvent({
      name: 'header',
      properties: {
        buttonName: 'languageDropdown',
        userName: 'anonymous',
        language: newLanguage,
      },
    });
  };

  const onClickMyTickets = () => {
    // eslint-disable-next-line
    console.log('Clicked My Tickets');
    navigate('/playerhub');
  };

  useEffect(() => {
    // Ensure settings and supportedLanguages are available before setting the language
    if (settings && supportedLanguages.length > 0) {
      const selectedLang =
        supportedLanguages.find(lang => lang.value === language)?.label || 'English'; // Default to "English" if not found
      setCurrentLanguage(selectedLang);
      setLanguage(supportedLanguages.find(lang => lang.label === selectedLang)?.value || 'en');
      // Synchronize i18n with the selected language
      i18n
        .changeLanguage(language)
        // eslint-disable-next-line
        .catch(err => console.log('Error setting initial language:', err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, setSettings]);

  const changeTheme = () => {
    setDarkMode(prevDarkMode => !prevDarkMode);

    if (!darkMode) {
      // Enabling dark mode
      if (!settings) return; // Prevent changes before settings are loaded

      if (!settingsCopy) {
        setSettingsCopy({ ...settings }); // Copy the original settings before modifying
      }

      const tempSettings = {
        ...settings,
        configs: {
          ...settings?.configs,
          needHelpTextColor: '#fff',
          mainHeaderBgColor: '#000',
          mainHeaderTextColor: '#fff',
          primaryBgColor: '#000',
          secondaryBgColor: '#777',
          primaryTextColor: '#fff',
          secondaryTextColor: '#fff',
          contactUsButtonBgColor: '#faba32',
          contactUsButtonTextColor: '#000',
          searchBarBgColor: '#777',
        },
      };
      setSettings(tempSettings); // Use temporary settings to simulate dark mode
    } else if (settingsCopy) {
      setSettings(settingsCopy); // Revert to the original settings
      setSettingsCopy(null); // Clear the copied settings once reverted
    }
  };

  useEffect(() => {
    if (!settings) {
      // Fetch settings if not already loaded
      fetch('/api/settings')
        .then(response => response.json())
        .then(data => {
          setSettings(data); // Update the context state with fetched settings
        })
        .catch(error =>
          // eslint-disable-next-line
          console.error('Error fetching settings:', error),
        );
    }
  }, [settings, setSettings]);

  if (!settings) {
    return <Loader />; // Optional loading state while fetching settings
  }

  const onClickLandingPageButton = () => {
    navigate('/');
  };

  return (
    <Box backgroundColor={settings?.configs.primaryBgColor}>
      {/* Header */}
      {settings?.configs?.showHeader && (
        <Header
          showMainHeader={settings?.configs.showMainHeader}
          mainHeaderBgColor={settings?.configs.mainHeaderBgColor}
          mainHeaderTextColor={settings?.configs.mainHeaderTextColor}
          showSubHeader={settings?.configs.showSubHeader}
          subHeaderBgColor={settings?.configs.primaryBgColor}
          subHeaderTextColor={settings?.configs.primaryTextColor}
          showHeaderLogo={settings?.configs.showHeaderLogo}
          logoUrl={settings?.configs.headerlogoUrl}
          gameTitle={t(`data.gameTitle`)}
          platforms={settings?.data.platforms}
          supportedLanguages={supportedLanguages}
          currentLanguage={currentLanguage}
          onLanguageChange={handleLanguageChange}
          horizontalPadding={{ base: '1', md: '8vw', lg: '15vw' }} // Responsive padding
          changeTheme={changeTheme}
          onClickMyTickets={onClickMyTickets}
          myTicketsButtonText={
            helperTexts.find((item: Record<string, string>) => 'myTicketsButtonText' in item)
              ?.myTicketsButtonText
          }
          loginButtonText={
            helperTexts.find((item: Record<string, string>) => 'loginButtonText' in item)
              ?.loginButtonText
          }
          logoWidth={settings?.configs?.headerLogoSize}
          targetUrl="/"
          onClickLandingPageButton={onClickLandingPageButton}
        />
      )}

      {/* Render children */}
      {children}

      {/* Footer */}
      {settings?.configs?.showFooter && (
        <Footer
          showFooterLogo={settings?.configs?.showFooterLogo}
          gameTitle={t(`data.gameTitle`)}
          showFooterLinks={settings?.configs?.showFooterLinks}
          links={settings?.data?.footerLinks}
          translations={translations}
          imageSrc={settings?.configs?.footerLogoUrl}
          logoWidth={settings?.configs?.footerLogoSize}
          footerNote={t(`data.footerNote`)}
          footerBgColor={settings?.configs?.footerBackgroundColor}
          footerTextColor={settings?.configs?.footerTextColor}
          fontSize="xs"
        />
      )}
    </Box>
  );
};
