// SettingsContext.tsx
import React, { createContext, useContext, ReactNode, useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { NewsArticle } from '../components/organisms/NewsSection';
import { FaqArticle } from '../components/organisms/PopularArticles';
import { MenuCardItem } from '../components/organisms/MenuCardList';
import { FooterLink } from '../components/organisms/Footer';
import i18n from '../i18n';
import { Loader } from '../components/organisms/Loader';
/* eslint-disable import/no-cycle */
import { SectionData, Settings, TranslationData } from '../interfaces';

const BLOB_CONTAINER_NAME = process.env.REACT_APP_BLOB_CONTAINER_NAME || '';
export interface Configs {
  primaryBgColor: string;
  secondaryBgColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  font: string;

  showHeader: boolean;
  mainHeaderBgColor: string;
  mainHeaderTextColor: string;
  headerlogoUrl: string; // Allow null for optional image
  showMainHeader: boolean;
  showSubHeader: boolean;
  showHeaderLogo: boolean;
  headerLogoSize?: { base: string; lg: string };

  showBanner: boolean;
  isBannerFullScreen: boolean;
  showSearchBar: boolean;
  searchBarTextColor: string;
  searchBarBgColor?: string;
  placeholderColor?: string;
  showOverlay: boolean;
  bannerImageUrl: string;
  bannerBgColor: string;
  bannerTextColor: string;
  bannerTextSize?: string;

  showNewsSection: boolean;
  paginationSelectedPageBgColor?: string;
  paginationSelectedPageTextColor?: string;

  showMenuCards: boolean;
  showPopularArticles: boolean;
  showSectionGrid: boolean;

  showFooter: boolean;
  footerLogoUrl: string;
  footerBackgroundColor: string;
  footerTextColor: string;
  showFooterLogo: boolean;
  showFooterLinks: boolean;
  footerLogoSize?: { base: string; lg: string };

  showContactUsButton: boolean;
  contactUsButtonBgColor: string;
  contactUsButtonTextColor: string;
  needHelpTextColor?: string;
  needHelptext?: string;

  userChatTextColor: string;
  helpshiftChatTextColor: string;
  userChatBgColor: string;
  helpshiftChatBgColor: string;
}

export interface Section {
  name: string;
  articles: string[];
}

export interface SectionIcons {
  id: string;
  name: string;
  icon: string;
}

export interface Data {
  sectionIcons: SectionIcons[];
  gameTitle: string;
  platforms: string[]; // Array of string for platforms
  languages: string[]; // Array of string for languages
  newsArticles: NewsArticle[]; // Assume NewsArticle type exists elsewhere
  popularArticles: FaqArticle[]; // Assume FaqArticle type exists elsewhere
  menuCards: MenuCardItem[]; // Assume MenuCard type exists elsewhere
  footerLinks: FooterLink[]; // Assume FooterLink type exists elsewhere
  footerNote: string;
  mainHeading: string;
  sections_data: SectionData[];
}

export interface FAQ {
  section_id: string;
  'published?': boolean;
  publish_id: string;
  platform_types: string[];
  slug: string;
  issue_tags?: string[];
  app_id: string;
  updated_at?: number;
  id: string;
  last_updated_by?: string;
  created_at?: number;
  translations: Record<string, any>;
  section_translations: Record<string, { title: string }>;
  linked_faq_ids?: string[];
}

export interface FormField {
  question: string;
  description?: string;
  placeholder?: string;
  options?: string;
  required: boolean;
  type:
    | 'singleline'
    | 'multiline'
    | 'email'
    | 'tel'
    | 'date'
    | 'password'
    | 'checkbox'
    | 'dropdown'
    | 'file'
    | 'radio'
    | 'number'
    | 'url'
    | 'heading';
  multiple?: boolean;
  height?: string;
  id: string;
  linked_cif_key?: string;
  linked_cif_type?: string;
  linked_cif_options?: string;
}

export interface CifFeilds {
  key: string;
  type:
    | 'email'
    | 'tel'
    | 'date'
    | 'password'
    | 'checkbox'
    | 'dropdown'
    | 'file'
    | 'radio'
    | 'number'
    | 'url'
    | 'heading'
    | 'singleline'
    | 'multiline';
  label: string;
  id: string;
  is_editable: boolean;
  value?: string;
  metadata_type?: string;
  metadata_key?: string;
  data?: {
    placeholder?: string;
    options?: { label: string; option_id: string }[];
  };
}

export interface CifData {
  data: CifFeilds[];
  is_agent: boolean;
}

interface SettingsContextType {
  settings: Settings | null;
  setSettings: React.Dispatch<React.SetStateAction<Settings | null>>;
  saveSettings: (
    newSettings: Settings,
    translationData: TranslationData,
    contactUsData: FormField[],
  ) => Promise<void>;
  faqs: FAQ[] | null;
  appId: string;
  language: string;
  setLanguage: (newLanguage: string) => void;
  supportedLanguages: { label: string; value: string }[];
  setFaqs: React.Dispatch<React.SetStateAction<FAQ[] | null>>;
  sectionsData: SectionData[] | null;
  setSectionsData: React.Dispatch<React.SetStateAction<SectionData[] | null>>;
  contactUsFormData: FormField[] | null;
  setContactUsFormData: React.Dispatch<React.SetStateAction<FormField[] | null>>;
  cifsData: CifData;
}

// Create a context for the settings
export const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

// Create a custom hook to access settings
export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

function getSectionWiseFAQs(data: FAQ[], language: string, appId: string): SectionData[] {
  const sectionsData: Record<string, SectionData> = {};
  data.forEach(faq => {
    // Filter by app_id and ensure section translations exist for the specified language
    if (faq.app_id !== appId || !faq.section_translations?.[language]) return;
    const sectionId = faq.section_id;
    const sectionTitle = faq.section_translations[language].title;

    if (!sectionsData[sectionId]) {
      // Initialize new section object if it doesn't exist
      sectionsData[sectionId] = {
        section_id: sectionId,
        section_name: sectionTitle,
        faq_data: [],
      };
    }

    // Check if FAQ translations exist for the specified language; skip if not
    if (!faq.translations?.[language]) return;

    // Add FAQ id and title to the section's faq_data list
    sectionsData[sectionId].faq_data.push({
      publish_id: faq.publish_id,
      faq_title: faq.translations[language].title,
      faq_body: faq.translations[language].body,
    });
  });

  // Convert sectionsData object to array and return
  return Object.values(sectionsData);
}

// Create a provider to wrap the Layout component and provide settings
export const SettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [settings, setSettings] = useState<Settings | null>(null); // Start with null
  const [language, setLanguage] = useState<string>(i18n.language?.split('-')[0] || 'en');
  const [supportedLanguages, setSupportedLanguages] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [faqs, setFaqs] = useState<FAQ[] | null>(null); // State for faqs
  const [sectionsData, setSectionsData] = useState<SectionData[] | null>(null);
  const [contactUsFormData, setContactUsFormData] = useState<FormField[] | null>(null);
  const [cifsData, setCifsData] = useState<CifData>({
    data: [],
    is_agent: false,
  });
  const [appId] = useState(process.env.REACT_APP_APP_ID || '');

  // Function to save settings to the server
  const saveSettingsToServer = async (
    newSettings: Settings,
    translationData: TranslationData,
    contactUsData: FormField[],
  ) => {
    try {
      const data = JSON.stringify([
        {
          name: 'config.json',
          type: 'json',
          path: BLOB_CONTAINER_NAME,
          data: JSON.stringify(newSettings, null, 2),
        },
        {
          name: 'en.json',
          type: 'json',
          path: `${BLOB_CONTAINER_NAME}/translations`,
          data: JSON.stringify(translationData, null, 2),
        },
        {
          name: 'contact-us-formdata.json',
          type: 'json',
          path: BLOB_CONTAINER_NAME,
          data: JSON.stringify(contactUsData, null, 2),
        },
      ]);
      const configData = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://catalysthc.ms.helpshift.live/api/fe-config',
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      };

      const configResponse = await axios.request(configData);
      // eslint-disable-next-line
      console.log(JSON.stringify(configResponse.data));
      await Promise.all(
        supportedLanguages.map(async lang => {
          const translationUrl = `https://catalysthc.ms.helpshift.live/api/lang-translation?lang=${lang.value}&path=${BLOB_CONTAINER_NAME}/translations`;
          const translationConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: translationUrl,
            headers: {},
            data: translationData,
          };

          try {
            const translationResponse = await axios.request(translationConfig);
            // eslint-disable-next-line
            console.log(
              `Translation API Response for ${lang.label}:`,
              JSON.stringify(translationResponse.data),
            );
          } catch (translationError) {
            // eslint-disable-next-line
            console.error(`Error in Translation API call for ${lang.label}:`, translationError);
            throw translationError;
          }
        }),
      );
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error saving settings:', error);
      throw error;
    }
  };

  // New function to handle saving settings
  const saveSettings = async (
    newSettings: Settings,
    translationData: TranslationData,
    contactUsData: FormField[],
  ) => {
    await saveSettingsToServer(newSettings, translationData, contactUsData);
  };

  // Use useMemo to memoize the value prop
  const value = useMemo(
    () => ({
      settings,
      setSettings,
      saveSettings,
      faqs,
      appId,
      language,
      setLanguage,
      supportedLanguages,
      setFaqs,
      sectionsData,
      setSectionsData,
      contactUsFormData,
      setContactUsFormData,
      cifsData,
    }),
    [
      settings,
      setSettings,
      faqs,
      appId,
      language,
      sectionsData,
      contactUsFormData,
      supportedLanguages,
      cifsData,
    ],
  );

  useEffect(() => {
    // Simulate API call to fetch settings
    const fetchSettings = async () => {
      try {
        const cdnurl = process.env.REACT_APP_CDN_URL;
        const globalConfigRes = await fetch(`${cdnurl}/globalConfig.json?v=1.1`);
        const globalConfig = await globalConfigRes.json(); // Parse response as JSON

        // Now fetch all required data using the URLs from globalConfig
        const [settingsRes, faqsRes, contactUsFormRes, supportedLanguagesRes, cifsDataRes] =
          await Promise.all([
            fetch(globalConfig.config),
            fetch(globalConfig.faqs),
            fetch(globalConfig.contactUs),
            fetch(globalConfig.supportedLanguages),
            fetch(globalConfig.cifs),
          ]);
        // Check if both responses are successful
        if (!settingsRes.ok || !faqsRes.ok) {
          throw new Error('One or both network responses were not ok');
        }

        // Parse both JSON responses concurrently
        const [data, faqArticles, contactUsFormFields, supportedLanguagesData, cifs] =
          await Promise.all([
            settingsRes.json(),
            faqsRes.json(),
            contactUsFormRes.json(),
            supportedLanguagesRes.json(),
            cifsDataRes.json(),
          ]);

        const supportedLanguagesArray = Object.entries(supportedLanguagesData).map(
          ([val, label]) => ({
            label: label as string,
            value: val as string,
          }),
        );

        setSupportedLanguages(supportedLanguagesArray);

        // Process FAQs data
        const sections = getSectionWiseFAQs(faqArticles, language, appId);

        // eslint-disable-next-line
        // console.log('Sections ', sections);
        // set the sections data in context
        setSectionsData(sections);

        // set the fetched faqs
        setFaqs(faqArticles);

        setContactUsFormData(contactUsFormFields);
        setCifsData(cifs);

        // Set the fetched settings
        setSettings(data);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Failed to fetch settings', error);
      }
    };
    fetchSettings();
  }, [language]); // rerender everytime language changes

  // Render only if settings is not null
  if (!settings) {
    // eslint-disable-next-line
    // console.log('loading', loading);
    return <Loader />; // Optionally show a loading message
  }
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
