import {
  Box,
  HStack,
  Stack,
  Switch,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  useToast,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  VStack,
  Button,
  Image,
} from '@chakra-ui/react';

import { useRef, useState } from 'react';
import axios from 'axios';
import { ColorPickerAtom } from '../../atoms/ColorPickerAtom';
import { CustomText } from '../../atoms';
import { ToggleAtom } from '../../atoms/CustomToggle';
import { FooterLink } from '../../organisms/Footer';
import { TranslationData } from '../../../interfaces';

interface FooterSettingsMoleculeProps {
  configs: any;
  translation: TranslationData;
  onConfigChange: (key: string, value: any) => void;
  convertImageToBase64: (file: File) => Promise<string | ArrayBuffer | null>;
  translatedFooterLinks: FooterLink[];
  handleFooterLinksSettingsChange: (footerLinks: FooterLink[]) => void;
  handleFooterTextSettingsChange: (
    category: keyof TranslationData,
    updatedTextKey: string,
    updatedTextValue: string,
  ) => void;
}

const BLOB_CONTAINER_NAME = process.env.REACT_APP_BLOB_CONTAINER_NAME || '';

export const FooterSettingsMolecule: React.FC<FooterSettingsMoleculeProps> = ({
  configs,
  onConfigChange,
  convertImageToBase64,
  translatedFooterLinks,
  handleFooterLinksSettingsChange,
  handleFooterTextSettingsChange,
  translation,
}) => {
  const [showContactUsButton, setShowContactUsButton] = useState(configs.showContactUsButton);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const toast = useToast();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resetFileInput = () => {
      setSelectedFile(null);
      setPreviewUrl(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };

    const file = event.target.files?.[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        setAlertMessage('Only image files are allowed.');
        resetFileInput();
        return;
      }

      if (file.type !== 'image/svg+xml') {
        setAlertMessage('Only SVG files are allowed.');
        resetFileInput();
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setAlertMessage('File size must be less than 5MB.');
        resetFileInput();
        return;
      }

      const renamedFile = new File([file], 'footerLogoTest.svg', { type: file.type });
      setSelectedFile(renamedFile);

      const reader = new FileReader();
      reader.onloadend = () => setPreviewUrl(reader.result as string);
      reader.readAsDataURL(renamedFile);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
    setIsUploading(true);
    try {
      const base64String = await convertImageToBase64(selectedFile);

      try {
        const data = JSON.stringify([
          {
            name: 'footerLogo.svg',
            type: 'image',
            path: `${BLOB_CONTAINER_NAME}/images`,
            data: base64String,
          },
        ]);

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://catalysthc.ms.helpshift.live/api/fe-config',
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        };

        const response = await axios.request(config);

        if (response.status >= 200 && response.status < 300) {
          toast({
            title: 'Upload successful!',
            description: 'Your footer image has been uploaded.',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error uploading file:', error);
        toast({
          title: 'Error',
          description: 'An error occurred while uploading.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (error) {
      // console.error('Error uploading file:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while uploading.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };
  const [footerLinks, setFooterLinks] = useState<FooterLink[]>(translatedFooterLinks);
  const [footerNote, setFooterNote] = useState(translation.data.footerNote);
  return (
    <Box height="80vh" overflowY="auto">
      {/* Section Header */}

      <Stack spacing={4}>
        {/* Show Footer */}
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ToggleAtom
            justifyContent="space-between"
            label="Show Contact Us Button"
            value={showContactUsButton}
            onChange={value => {
              setShowContactUsButton(value); // Update local state
              onConfigChange('showContactUsButton', value); // Notify parent of the change
            }}
          />
        </HStack>
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <CustomText>Show Footer</CustomText>
          <Switch
            isChecked={configs.showFooter}
            onChange={e => onConfigChange('showFooter', e.target.checked)} // Use e.target.checked instead of e.target.value
          />
        </HStack>
        <VStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          align="start"
        >
          <CustomText>Upload Footer Image</CustomText>
          <HStack spacing={4}>
            <Input
              type="file"
              accept="image/svg+xml"
              onChange={handleFileChange}
              ref={fileInputRef}
              bg="white"
            />
            <Button
              colorScheme="blue"
              onClick={handleUpload}
              isDisabled={!selectedFile}
              isLoading={isUploading}
            >
              Upload
            </Button>
          </HStack>
          <Text fontSize="sm" color="gray.500" mt={2}>
            Supported file type: <b>SVG</b>
          </Text>
          <Text fontSize="sm" color="gray.500">
            Note: This action will immediately upload the selected image without any further
            confirmation.
          </Text>
        </VStack>

        {previewUrl && (
          <Box mt={4} display="flex" justifyContent="center">
            <Image
              src={previewUrl}
              alt="Preview"
              boxSize="200px"
              borderRadius="md"
              objectFit="cover"
            />
          </Box>
        )}

        {/* Footer Background Color */}
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ColorPickerAtom
            label="Footer Background Color"
            value={configs?.footerBackgroundColor}
            onChange={color => onConfigChange('footerBackgroundColor', color)}
          />
        </HStack>

        {/* Footer Text Color */}
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <ColorPickerAtom
            label="Footer Text Color"
            value={configs?.footerTextColor}
            onChange={color => onConfigChange('footerTextColor', color)}
          />
        </HStack>

        {/* Show Footer Logo */}
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <CustomText>Show Footer Logo</CustomText>
          <Switch
            isChecked={configs?.showFooterLogo}
            onChange={e => onConfigChange('showFooterLogo', e.target.checked)}
          />
        </HStack>

        {/* Show Footer Links */}
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <CustomText>Show Footer Links</CustomText>
          <Switch
            isChecked={configs?.showFooterLinks}
            onChange={e => onConfigChange('showFooterLinks', e.target.checked)}
          />
        </HStack>

        {/* Footer Logo Size */}
        <HStack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <CustomText>Footer Logo Size</CustomText>
          <Slider
            value={parseInt(configs?.footerLogoSize?.base || '30', 10)}
            min={10}
            max={100}
            onChange={val =>
              onConfigChange('footerLogoSize', { base: `${val}%`, lg: `${val / 3}%` })
            }
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={6}>
              <Text fontSize="xs">{configs?.footerLogoSize?.base || '30%'}</Text>
            </SliderThumb>
          </Slider>
        </HStack>
        <HStack p={4} justifyContent="space-between" alignItems="center">
          <CustomText>Footer Note</CustomText>
          <Input
            value={footerNote}
            onChange={e => {
              const newValue = e.target.value;
              setFooterNote(newValue);
              handleFooterTextSettingsChange('data', 'footerNote', newValue);
            }}
            placeholder="Enter footer note"
          />
        </HStack>
        <VStack p={4} borderWidth="1px" borderRadius="md" mb={4} align="start" w="full">
          <CustomText>Footer Links</CustomText>
          {footerLinks.map((link: any, index: number) => (
            <HStack key={link.id || index} spacing={4} w="full">
              <Input
                placeholder="Label"
                value={link.text}
                onChange={e => {
                  const updatedLinks = [...footerLinks];

                  updatedLinks[index].text = e.target.value;
                  setFooterLinks(updatedLinks);
                  handleFooterLinksSettingsChange(updatedLinks);
                }}
              />
              <Input
                type="url"
                placeholder="Target URL"
                value={link.href}
                onChange={e => {
                  const updatedLinks = [...footerLinks];
                  updatedLinks[index].href = e.target.value;
                  setFooterLinks(updatedLinks);
                  handleFooterLinksSettingsChange(updatedLinks);
                }}
              />
              <Switch
                isChecked={link.enabled}
                onChange={e => {
                  const updatedLinks = [...footerLinks];
                  updatedLinks[index].enabled = e.target.checked;
                  setFooterLinks(updatedLinks);
                  handleFooterLinksSettingsChange(updatedLinks);
                }}
              />
            </HStack>
          ))}
        </VStack>
      </Stack>
      {alertMessage && (
        <Center
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="1000"
        >
          <Alert status="error" variant="solid" borderRadius="md" boxShadow="lg">
            <AlertIcon />
            <Box>
              <AlertTitle>Error</AlertTitle>
              {alertMessage}
            </Box>
            <CloseButton
              onClick={() => setAlertMessage(null)}
              position="absolute"
              right="8px"
              top="8px"
            />
          </Alert>
        </Center>
      )}
    </Box>
  );
};
