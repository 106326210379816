import React, { ChangeEventHandler } from 'react';
import { Box, FormControl, ResponsiveValue } from '@chakra-ui/react';
import { Label } from '../../atoms/Label';
import { Description } from '../../atoms/Description';
import { CustomInput } from '../../atoms';

interface CustomInputTextboxProps {
  question: string;
  placeholder?: string;
  required: boolean;
  description?: string;
  marginStart?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  marginTop?: string;
  fontSize?: string;
  descriptionFontSize?: string;
  height?: ResponsiveValue<string>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  isDisabled?: boolean;
  id?: string;
}

export const CustomInputTextbox: React.FC<CustomInputTextboxProps> = ({
  question,
  placeholder,
  required,
  description,
  marginStart,
  paddingLeft,
  fontSize,
  descriptionFontSize,
  height,
  onChange,
  value,
  marginTop,
  isDisabled,
  id,
}) => {
  const inputId = 'Textbox-field'; // Unique id for the input field
  return (
    // <div>
    <Box
      marginStart={marginStart}
      paddingLeft={paddingLeft}
      marginTop={id !== '2' ? marginTop : undefined}
    >
      <FormControl marginStart={marginStart} paddingLeft={paddingLeft}>
        <Label label={question} htmlFor={inputId} required={required} fontSize={fontSize} />
        <Description description={description} fontSize={descriptionFontSize} />
        <CustomInput
          isRequired={required}
          placeholder={placeholder}
          onChange={onChange}
          size="md"
          value={value}
          type="text"
          variant="outline"
          bgColor="#FFFFFF"
          height={height || '50px'}
          marginTop={marginTop}
          isDisabled={isDisabled}
        />
      </FormControl>
    </Box>
    // </div>
  );
};
